define("lbp-cust-web-application/initializers/routes/ticust-web-module-projects/interlocutors/interlocutor/projects/project", ["exports", "ticust-web-module-projects/routes/interlocutors/interlocutor/projects/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-projects/routes/interlocutors/interlocutor/projects/project',
    // eslint-disable-line
    initialize: initialize
  };
  _project.default.reopen({
    // Services
    identityService: Ember.inject.service(),
    projectReaderService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // Manage read / unread on project level
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this._markAsRead(model.project, this.get('identityService.userId'));
    },
    _markAsRead: function _markAsRead(project, reader) {
      if (!project.get('clientReaders').some(function (r) {
        return r === reader;
      })) {
        this.get('projectReaderService').markProjectAsRead(project.get('id'), reader);
      }
    },
    model: function model(params, transition) {
      var _this = this;
      return this._super(params, transition).then(function (model) {
        model.documentNatures = _this.get('store').query('document-nature', {});
        model.documentModels = _this.get('store').query('document-model', {});
        model.documentRejectReasons = _this._getRejectReason();
        return Ember.RSVP.hash(model);
      });
    },
    _getRejectReason: function _getRejectReason() {
      return this.get('ajax').raw('/api/document-reject-reason', {
        method: 'GET',
        headers: {
          Authorization: this.get('identity.token')
        }
      }).then(function (response) {
        return response.response;
      });
    },
    afterModel: function afterModel() {
      // don't call the super afterModel function to don't mark documents as read
    }
  });
});