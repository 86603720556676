define("lbp-cust-web-application/instance-initializers/override-router-ticust-web-module-projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(instance) {
    instance.register('route-map:ticust-web-module-projects', function () {
      // This route redirects to the login module
      this.route('login');
      this.route('interlocutors', function () {
        this.route('interlocutor', {
          path: '/:interlocutor_id'
        }, function () {
          this.route('site-map');
          // START custom route
          this.route('help');
          // END of custom route
          this.route('projects', function () {
            this.route('search', function () {
              this.route('target', {
                path: '/:query_name'
              }, function () {});
            });
            this.route('project', {
              path: '/:project_id'
            }, function () {
              this.route('edit');
            });
            this.route('create', function () {
              this.route('free');
            });
          });
        });
      });
      this.route('signature');
    });
  }
  var _default = _exports.default = {
    name: 'override-router-ticust-web-module-projects',
    initialize: initialize
  };
});