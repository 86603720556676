define("lbp-cust-web-application/components/common/modal/self-service-modal", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Flag to show emphasis or not
    actionEmphasis: false,
    options: Ember.computed('closeButtonText', 'closeButtonLabel', 'validateButtonText', 'validateButtonLabel', function () {
      var options = [];
      options.push({
        'label': this.get('closeButtonLabel'),
        'text': this.get('closeButtonText'),
        'action': 'close',
        'class': 'text-sm-uppercase'
      });
      options.push({
        'label': this.get('validateButtonLabel'),
        'text': this.get('validateButtonText'),
        'action': 'validate'
      });
      return options;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalId', 'popup-self-service-' + _emberUuid.default.v4());
      this.set('modalTitleId', 'popup-self-service-' + _emberUuid.default.v4());
    },
    showEmphasis: function showEmphasis() {
      if (!this.get('actionEmphasis') && !this.isDestroyed) {
        this.set('actionEmphasis', true);
      }
    },
    didRender: function didRender(args) {
      this._super(args);
      if (this.get('isVisible')) {
        // 5s after render, show Emphasis
        Ember.run.later(this, function () {
          this.showEmphasis();
        }, 3000);
      }
    }
  });
});