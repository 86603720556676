define("lbp-cust-web-application/mixins/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Mixin.create({
    _previousFocus: undefined,
    blurOnAutofocusOut: true,
    focus: function focus(element) {
      var _this = this;
      if (element) {
        if (this.get('_currentFocus')) {
          return;
        }
        var currentFocus = Ember.run.later(this, function () {
          if (_this.isDestroyed) {
            return;
          }
          _this.set('_previousFocus', document.activeElement);
          var node = false;
          if (typeof element === 'string') {
            // element is a selector
            node = _this.element.querySelector(element);
          } else if (_typeof(element) === 'object') {
            // element is the node
            node = element;
          }
          if (node) {
            node.focus();
          }
          _this.set('_currentFocus', undefined);
        }, 50);
        this.set('_currentFocus', currentFocus);
      }
    },
    unfocus: function unfocus() {
      if (this.get('previousFocus')) {
        if (this.get('previousFocus').focus !== undefined) {
          this.get('previousFocus').focus();
        }
      } else if (this.get('_previousFocus') && this.get('_previousFocus').focus !== undefined) {
        this.get('_previousFocus').focus();
      }
    },
    genericFocusElement: function genericFocusElement(selector, isId, isNotFocusableElement) {
      var elementToFocus = undefined;
      if (isId) {
        elementToFocus = document.getElementById(selector);
      } else {
        elementToFocus = document.querySelector(selector);
      }
      if (elementToFocus) {
        if (isNotFocusableElement) {
          elementToFocus.setAttribute('tabindex', '-1');
        }
        elementToFocus.focus();
      }
    },
    genericFocusAfterToast: function genericFocusAfterToast(selector, isId, isNotFocusableElement) {
      var _this2 = this;
      window.toastr.options.onHidden = function () {
        _this2.genericFocusElement(selector, isId, isNotFocusableElement);
      };
    }
  });
});