define("lbp-cust-web-application/initializers/components/ti-web-shared/projects/project-card-component", ["exports", "ti-web-shared/components/ti-web-shared/projects/project-card-component", "moment"], function (_exports, _projectCardComponent, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/components/ti-web-shared/projects/project-card-component',
    // eslint-disable-line
    initialize: initialize
  };
  _projectCardComponent.default.reopen({
    creationDate: Ember.computed('project.creationDate', function () {
      var creationDate = this.get('project.creationDate');
      return creationDate ? (0, _moment.default)(creationDate).format('D MMMM YYYY') : '';
    }),
    closedDate: Ember.computed('project.closedDate', function () {
      var closedDate = this.get('project.closedDate');
      return closedDate ? (0, _moment.default)(closedDate).format('D MMMM YYYY') : '';
    }),
    finishDate: Ember.computed('project.finishDate', function () {
      var finishDate = this.get('project.finishDate');
      return finishDate ? (0, _moment.default)(finishDate).format('D MMMM YYYY') : '';
    }),
    showCreationDate: Ember.computed.equal('project.status', 'IN_PROGRESS'),
    showLinkToProject: Ember.computed.equal('project.status', 'IN_PROGRESS'),
    showClosedDate: Ember.computed.equal('project.status', 'CLOSED'),
    showFinishDate: Ember.computed.equal('project.status', 'CLOSED'),
    warnedAccessibleDate: Ember.computed('_lastNotifiedDate', function () {
      var date = (0, _moment.default)(this.get('_lastNotifiedDate')).subtract(this.get('_lastNotifiedDate').getTimezoneOffset(), 'm');
      var yearFormat = this.get('_lastNotifiedDate').getFullYear() !== new Date().getFullYear() ? ' Y' : '';
      return this.get('accessibleDate').format(date, {
        format: "DD D M".concat(yearFormat),
        language: 'fr',
        military: true
      });
    })
  });
});