define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/conversation-message-attachments", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-attachments"], function (_exports, _conversationMessageAttachments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-attachments',
    // eslint-disable-line
    initialize: initialize
  };
  _conversationMessageAttachments.default.reopen({
    displayAttachmentId: false,
    nbAttachments: Ember.computed.alias('attachments.length'),
    titleLevel: "4"
  });
});