define("lbp-cust-web-application/templates/ticust-web-module-authentication/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EXFM7D0z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\"],[11,\"class\",\"logout-card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"role\",\"heading\"],[11,\"aria-level\",\"1\"],[11,\"class\",\"panel-heading flex-basic flex-row-simple\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"panel-title\"],[9],[1,[27,\"t\",[\"auth.logout.title\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"auth.logout.text\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/ticust-web-module-authentication/logout.hbs"
    }
  });
});