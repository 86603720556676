define("lbp-cust-web-application/initializers/components/ti-web-shared/projects/project-group", ["exports", "ti-web-shared/components/ti-web-shared/projects/project-group", "lbp-cust-web-application/templates/components/ti-web-shared/projects/project-group"], function (_exports, _projectGroup, _projectGroup2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/components/ti-web-shared/projects/project-group',
    // eslint-disable-line
    initialize: initialize
  };
  _projectGroup.default.reopen({
    // Layout
    layout: _projectGroup2.default,
    // Services
    projectService: Ember.inject.service('project-service'),
    actions: {
      getProjects: function getProjects(criteria) {
        var _this = this;
        return this._super(criteria).then(function (projects) {
          return _this.get('projectService').enrichProjectList(projects, _this.get('currentInterlocutor'));
        });
      }
    }
  });
});