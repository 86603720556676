define("lbp-cust-web-application/initializers/services/ticust-web-module-interactions/conversation-query", ["exports", "ticust-web-module-interactions/services/conversation-query"], function (_exports, _conversationQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/services/conversation-query',
    // eslint-disable-line
    initialize: initialize
  };
  _conversationQuery.default.reopen({
    getSourceRequestData: function getSourceRequestData(source) {
      (true && !(source) && Ember.assert('Missing source', source));
      switch (source) {
        case 'conversationMine':
          return {
            status: 'IN_PROGRESS',
            messageStatus: 'RECEIVED'
          };
        case 'conversationSent':
          return {
            status: 'IN_PROGRESS',
            messageStatus: 'SENT'
          };
        case 'conversationDraft':
          return {
            status: 'INIT,IN_PROGRESS',
            messageStatus: 'DRAFT'
          };
        case 'search':
        case 'all':
          return {};
        case 'conversationTrashed':
          return {
            status: 'DELETED'
          };
        default:
          throw new Error('queryParam.source define as ' + source + ' is not defined.');
      }
    }
  });
});