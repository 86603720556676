define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/conversation", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/conversation", "libmime"], function (_exports, _conversation, _libmime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/conversation',
    // eslint-disable-line
    initialize: initialize
  };
  _conversation.default.reopen({
    // Services
    identity: Ember.inject.service('identity-service'),
    lbpService: Ember.inject.service('lbp-service'),
    logger: Ember.inject.service('logger-service'),
    hasDraft: undefined,
    setupController: function setupController(controller, model) {
      // Super will call _markAsRead
      this._super(controller, model);
      controller.set('_answerButtonClicked', false);
      controller.set('_showConfirmDelete', false);
      controller.set('showExitConfirmationModal', false);
      controller.set('showHistoryButton', false);
      controller.set('showHistory', true);
      controller.set('showErrors', false);
      // Find draft message
      var bodyText = '';
      if (model.messages) {
        var draftMessage = model.messages.find(function (m) {
          return "DRAFT" === m.get('status');
        });
        if (draftMessage) {
          bodyText = draftMessage.get('messageContent.bodyTextHtmlParts.firstObject.body');
        }
      }
      controller.set('_textHtml', bodyText);
      this.set('hasDraft', controller.get('hasDraft'));
    },
    // Parent markAsRead override.
    // eslint-disable-next-line no-unused-vars
    _markAsRead: function _markAsRead(conversation) {
      // removed.
    },
    _localMarkAsReadPromise: function _localMarkAsReadPromise(conversation) {
      var _this = this;
      if (!conversation.get('isRead')) {
        conversation.set('isRead', true);
        return conversation.save().catch(function (error) {
          _this.get('logger').error('An error occured while saving the isRead change', error);
        });
      }
    },
    // override of product model to manage marking as read, before getting messages.
    model: function model(params) {
      var _this2 = this;
      var conversationId = params.conversation_id;
      var promises = {
        conversation: this.get('store').findRecord('conversation', conversationId),
        settings: this.getSettingsPromise(),
        interlocutorSelectedInHeaderBar: this.modelFor('interlocutors.interlocutor'),
        pageSource: params.pageSource
      };
      return Ember.RSVP.hash(promises).then(function (model) {
        if (model.conversation.get('nbMessages') == 1) {
          // Add intermediary promis to mark as read and resolve it
          model.markRead = _this2._localMarkAsReadPromise(model.conversation);
        }
        return Ember.RSVP.hash(model);
      }).then(function (model) {
        model.messages = _this2.get('store').query('conversation-message', {
          conversation: conversationId,
          sort: 'receiveDate,desc',
          page: 0,
          size: model.settings.messagePageSize
        });
        return Ember.RSVP.hash(model);
      }).then(function (model) {
        model.theme = _this2._getConversationTheme(model.conversation, _this2._getMessageWithTheme(model.messages));
        return Ember.RSVP.hash(model);
      });
    },
    _getMessageWithTheme: function _getMessageWithTheme(messages) {
      if (messages) {
        return messages.find(function (message) {
          return message.get('status') != 'DRAFT' && message.get('messageContent.headers.X-BPCDSTH');
        });
      }
    },
    _getConversationTheme: function _getConversationTheme(conversation, message) {
      if (message) {
        var getHeader = function getHeader(headers, name) {
          return headers[name] != null && headers[name].length > 0 ? headers[name][0] : '';
        };
        var headers = message.get('messageContent.headers');
        return Ember.Object.create({
          id: getHeader(headers, 'X-BPAETHEMEUUID'),
          key: getHeader(headers, 'X-BPCDSTH'),
          label: _libmime.default.decodeWords(getHeader(headers, 'X-BPLBSTH'))
        });
      } else if (conversation && conversation.get('caracteristics') && conversation.get('caracteristics')['theme-key']) {
        return Ember.Object.create({
          key: conversation.get('caracteristics')['theme-key'],
          label: _libmime.default.decodeWords(conversation.get('caracteristics')['theme-label'])
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    afterModel: function afterModel(_ref, transition) {
      var conversation = _ref.conversation;
    } // Nothing to do for LBP
    ,
    // Override settings to differenciate CS / STD
    getSettingsPromise: function getSettingsPromise() {
      var _this3 = this;
      var messagePageSizeKey = 'cust.module.interactions.messagePageSize';
      var attachmentsMaxSizeKey = 'cust.module.interactions.newMessage.attachment.out.maxSize';
      var attachmentsMaxNumberKey = 'cust.module.interactions.newMessage.attachments.out.maxNumber';
      var attachmentsAllowedTypesKey = 'cust.module.interactions.newMessage.attachments.allowedTypes';
      var maxSizeOfMessageKey = 'cust.module.interactions.newMessage.attachments.out.maxSize';
      var convMonthDelayKey = 'cust.module.interactions.answer.newMessage.convMonthDelay';
      var convNewMessageDelayKey = 'cust.module.interactions.answer.newMessage.convNewMessageDelay';
      var inbentaBypassKey = 'cust.module.interactions.answer.newMessage.inbentaBypass';

      // LBP, use other settings if the user is LBPCS
      if (this.get('identity.isLbpCs')) {
        attachmentsMaxSizeKey = 'cust.module.interactions.newMessageCs.attachments.maxSize';
        attachmentsMaxNumberKey = 'cust.module.interactions.newMessageCs.attachments.maxNumber';
        attachmentsAllowedTypesKey = 'cust.module.interactions.newMessageCs.attachments.allowedTypes';
      }
      var interlocutorId = this.modelFor('interlocutors.interlocutor').get('id');
      var userId = this.modelFor('application').get('id');
      var getSetting = function getSetting(type, id, key) {
        return _this3.get('apiSettings').getSettingByKey(type, id, key);
      };
      return Ember.RSVP.hash({
        messagePageSize: getSetting('user', userId, messagePageSizeKey),
        attachmentsMaxSize: getSetting('interlocutor', interlocutorId, attachmentsMaxSizeKey),
        attachmentsMaxNumber: getSetting('interlocutor', interlocutorId, attachmentsMaxNumberKey),
        attachmentsAllowedTypes: getSetting('interlocutor', interlocutorId, attachmentsAllowedTypesKey),
        maxSizeOfMessage: getSetting('interlocutor', interlocutorId, maxSizeOfMessageKey),
        convMonthDelay: getSetting('interlocutor', interlocutorId, convMonthDelayKey),
        convNewMessageDelay: getSetting('interlocutor', interlocutorId, convNewMessageDelayKey),
        inbentaBypass: getSetting('interlocutor', interlocutorId, inbentaBypassKey)
      }).then(function (settings) {
        var messagePageSize = settings.messagePageSize.get('settingValue');
        var attachmentMaxSize = settings.attachmentsMaxSize.get('settingValue');
        var attachmentsMaxNumber = settings.attachmentsMaxNumber.get('settingValue');
        var attachmentsAllowedTypes = settings.attachmentsAllowedTypes.get('settingValue');
        var maxSizeOfMessage = settings.maxSizeOfMessage.get('settingValue');
        var convMonthDelay = settings.convMonthDelay.get('settingValue');
        var convNewMessageDelay = settings.convNewMessageDelay.get('settingValue');
        var inbentaBypass = settings.inbentaBypass.get('settingValue');
        return {
          messagePageSize: messagePageSize,
          attachmentMaxSize: attachmentMaxSize,
          attachmentsMaxNumber: attachmentsMaxNumber,
          attachmentsAllowedTypes: attachmentsAllowedTypes,
          maxSizeOfMessage: maxSizeOfMessage,
          convMonthDelay: convMonthDelay,
          convNewMessageDelay: convNewMessageDelay,
          inbentaBypass: inbentaBypass
        };
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.isDirty') && !this.get('controller.showExitConfirmationModal')) {
          this.set('controller.showExitConfirmationModal', true);
          this.set('controller._transition', transition);
          transition.abort();
          return;
        }
        this.set('controller._transition', undefined);
        this._super(transition);
      }
    }
  });
});