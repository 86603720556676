define("lbp-cust-web-application/templates/components/ti-web-shared/modals/document-viewer-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8hfOw+n1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ti-web-shared/modals/modal-component\",null,[[\"modalId\",\"modalTitle\",\"modalTitleId\",\"originalFocusId\",\"isOpen\",\"showConfirmButton\",\"showCancelButton\",\"onOpenModal\",\"triggerTitle\",\"triggerTooltip\",\"showTriggerButton\",\"showTriggerTitleTooltip\",\"customClasses\",\"cancel\"],[[23,[\"modalId\"]],[23,[\"modalTitle\"]],[23,[\"modalTitleId\"]],[23,[\"focusBackId\"]],[23,[\"isOpen\"]],false,false,[23,[\"triggerAction\"]],[23,[\"triggerTitle\"]],[23,[\"triggerTooltip\"]],[23,[\"showTriggerButton\"]],[23,[\"showTriggerTitleTooltip\"]],[23,[\"customClasses\"]],[27,\"action\",[[22,0,[]],\"closeAction\"],null]]],{\"statements\":[[0,\"\\n  \"],[1,[27,\"ti-web-shared/modals/document-viewer\",null,[[\"isVisible\",\"src\",\"filename\",\"author\",\"date\",\"isCampaign\",\"isProject\",\"isRequest\",\"hasError\",\"closeAction\"],[[23,[\"isVisible\"]],[23,[\"src\"]],[23,[\"filename\"]],[23,[\"author\"]],[23,[\"date\"]],[23,[\"isCampaign\"]],[23,[\"isProject\"]],[23,[\"isRequest\"]],[23,[\"hasErrorPreview\"]],[27,\"action\",[[22,0,[]],\"closeAction\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/modals/document-viewer-popup.hbs"
    }
  });
});