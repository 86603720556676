define("lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-project-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M4hbYH7q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ti-web-shared/projects/lbp-accordion-element\",null,[[\"onOpen\",\"title\",\"index\",\"isOpen\",\"hasIndicator\",\"indicator\"],[[27,\"action\",[[22,0,[]],\"onOpen\"],null],[23,[\"part\",\"title\"]],[23,[\"index\"]],[27,\"eq\",[[23,[\"part\",\"status\"]],\"OPENED\"],null],[23,[\"_isUnread\"]],[27,\"t\",[\"projects.project.documents.unread.text\"],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-project-part.hbs"
    }
  });
});