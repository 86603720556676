define("lbp-cust-web-application/instance-initializers/override-router-ticust-web-module-interactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(instance) {
    instance.register('route-map:ticust-web-module-interactions', function () {
      // This route redirects to the login module
      this.route('login');
      this.route('preview-document');
      this.route('interlocutors', function () {
        this.route('interlocutor', {
          path: '/:interlocutor_id'
        }, function () {
          this.route('site-map');
          // START custom route
          this.route('parameters', function () {
            this.route('notifications');
          });
          this.route('help');
          // END of custom route
          this.route('conversations', function () {
            // START custom route
            this.route('inbenta');
            this.route('claim');
            // END custom route
            this.route('create');
            this.route('drafts', function () {
              this.route('draft', {
                path: '/:conversation_id'
              });
            });
            this.route('conversation', {
              path: '/:conversation_id'
            });
            this.route('sources', function () {
              this.route('source', {
                path: '/:source'
              });
            });
            this.route('labels', function () {
              this.route('label', {
                path: '/:label'
              });
            });
          });
          this.route('labels', function () {
            this.route('settings');
          });
        });
      });
    });
  }
  var _default = _exports.default = {
    name: 'override-router-ticust-web-module-interactions',
    initialize: initialize
  };
});