define("lbp-cust-web-application/templates/components/a11y/a11y-action-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1QGaStjf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"class\",[27,\"if\",[[27,\"not\",[[23,[\"enabled\"]]],null],\"action-icon-disabled\"],null]],[12,\"id\",[28,[[21,\"id\"]]]],[11,\"role\",\"button\"],[11,\"tabindex\",\"0\"],[12,\"aria-label\",[28,[[21,\"label\"],\" \",[27,\"if\",[[27,\"not\",[[23,[\"enabled\"]]],null],[27,\"t\",[\"icon.disabled\"],null]],null]]]],[11,\"href\",\"#\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"aria-hidden\",\"true\"],[12,\"class\",[28,[[21,\"iconClass\"]]]],[12,\"title\",[28,[[21,\"srText\"]]]],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[27,\"if\",[[23,[\"enabled\"]],\"action\",\"nothing\"],null]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/a11y/a11y-action-icon.hbs"
    }
  });
});