define("lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eps48iei",
    "block": "{\"symbols\":[],\"statements\":[[4,\"a11y/a11y-action-div\",null,[[\"class\",\"role\",\"label\",\"action\",\"enableAction\"],[\"project-indicator\",[23,[\"role\"]],[23,[\"label\"]],[27,\"action\",[[22,0,[]],\"indicatorAction\"],null],[23,[\"hasAction\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"project-indicator-left project-indicator-icon\"],[12,\"aria-hidden\",[28,[[27,\"if\",[[23,[\"indicatorsAriaHidden\"]],\"true\",\"false\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"textClass\",\"fixClass\",\"iconClass\",\"srHidden\"],[\"\",\"fixSafari\",[27,\"concat\",[\"go-right project-icon project-icon-base project-indicator-icon \",[23,[\"icon\"]]],null],true]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"project-indicator-right\"],[12,\"aria-hidden\",[28,[[27,\"if\",[[23,[\"indicatorsAriaHidden\"]],\"true\",\"false\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"project-indicator-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"project-indicator-text\"],[9],[1,[21,\"text\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-indicator.hbs"
    }
  });
});