define("lbp-cust-web-application/initializers/components/ti-web-shared/modals/document-viewer-popup", ["exports", "ti-web-shared/components/ti-web-shared/modals/document-viewer-popup"], function (_exports, _documentViewerPopup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/components/ti-web-shared/modals/document-viewer-popup',
    // eslint-disable-line
    initialize: initialize
  };
  _documentViewerPopup.default.reopen({
    showTriggerButton: true,
    focusIdOnOpen: undefined,
    isNotFocusableOnOpen: undefined,
    focusIdOnClose: undefined,
    isNotFocusableOnClose: undefined,
    focusableElements: undefined,
    focusElementById: function focusElementById(elementId, isNotFocusableElement) {
      var element = document.getElementById(elementId);
      if (element) {
        if (isNotFocusableElement) {
          element.setAttribute('tabindex', '-1');
        }
        element.focus();
      }
    },
    setupFocusableElements: function setupFocusableElements() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var focusableElementsSelector = "\n        a[href], button, input, select, textarea, iframe,\n        [tabindex]:not([tabindex=\"-1\"]), [contenteditable]\n      ";
        var modalElement = document.getElementById('modal-container');
        var allFocusableElements = Array.from(modalElement.querySelectorAll(focusableElementsSelector));
        this.focusableElements = allFocusableElements.filter(function (el) {
          return window.getComputedStyle(el).display !== 'none';
        });
      });
    },
    addKeydownListener: function addKeydownListener() {
      this.keydownHandler = this.handleKeydown.bind(this);
      document.getElementById('modal-container').addEventListener('keydown', this.keydownHandler);
    },
    removeKeydownListener: function removeKeydownListener() {
      document.getElementById('modal-container').removeEventListener('keydown', this.keydownHandler);
    },
    handleKeydown: function handleKeydown(event) {
      if (event.key === 'Tab' && this.focusableElements.length > 0) {
        var firstElement = this.focusableElements[0];
        var lastElement = this.focusableElements[this.focusableElements.length - 1];
        if (event.shiftKey) {
          // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else {
          // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    },
    undisplayElement: function undisplayElement() {
      var title = document.querySelector('.center-title.panel-title-header-base');
      var closeButton = document.querySelector('.modal-close.modal-close-icon');
      if (title) {
        title.style.display = 'none';
      }
      if (closeButton) {
        closeButton.style.display = 'none';
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.later(function () {
        if (_this.get('focusIdOnOpen')) {
          _this.focusElementById(_this.get('focusIdOnOpen'), _this.get('isNotFocusableOnOpen'));
        }
      }, 200);
      this.undisplayElement();
      this.setupFocusableElements();
      this.addKeydownListener();
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      Ember.run.later(function () {
        if (_this2.get('focusIdOnClose')) {
          _this2.focusElementById(_this2.get('focusIdOnClose'), _this2.get('isNotFocusableOnClose'));
        }
      }, 100);
      this.removeKeydownListener();
    }
  });
});