define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/composer/conversation-composer-reply", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/composer/conversation-composer-reply", "moment", "lbp-cust-web-application/mixins/focus", "ember-uuid"], function (_exports, _conversationComposerReply, _moment, _focus, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/composer/conversation-composer-reply',
    // eslint-disable-line
    initialize: initialize
  };
  _conversationComposerReply.default.reopen(_focus.default, {
    _showConfirmDelete: false,
    isDraftConversation: false,
    // Does not show the modal when we open the component
    showModal: false,
    showError: true,
    sendButtonDisabled: false,
    sendErrorToParent: Ember.observer('_emptyMessage', function () {
      this.set('emptyMessage', this.get('_emptyMessage'));
    }),
    _emptyMessage: Ember.computed('_textHtml', function () {
      if (this.get('_textHtml')) {
        return this.get('_textHtml').split(' ').join('') === '<p></p>';
      }
      return true;
    }),
    // Override LBP to manage the TO header
    // Remove to send message to contact
    //_toHeader: computed('to.firstObject.email', function() {
    //  return [this.get('to.firstObject.email')];
    //}),

    now: Ember.computed('clock.minute', function () {
      return (0, _moment.default)().format('HH[h]mm');
    }),
    _attachmentCount: Ember.computed('_attachments.[]', function () {
      return this.get('_attachments').length;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalDeleteId', 'popup-delete-conv-' + _emberUuid.default.v4());
      this.set('modalDeleteTitleId', 'popup-delete-conv-' + _emberUuid.default.v4());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.handleKeyDownListener = this._keyDown.bind(this);
      document.addEventListener("keydown", this.handleKeyDownListener);
    },
    _keyDown: function _keyDown(event) {
      // Catch only enter or space key
      if (event.keyCode === 13 || event.keyCode === 32) {
        if (document.activeElement && document.activeElement.localName === 'a' && document.activeElement.role === 'button') {
          document.activeElement.click();
          return false;
        }
      }
    },
    // Actions
    cancelAnswer: function cancelAnswer() {
      throw new Error("Missing 'cancelAnswer' handler");
    },
    showHistoryButton: function showHistoryButton() {
      throw new Error("Missing 'showHistoryButton' handler");
    },
    showHistory: function showHistory() {
      throw new Error("Missing 'showHistory' handler");
    },
    actions: {
      closeModal: function closeModal() {
        this.set('showModal', false);
        // Set focus on editor as LBP requested
        this.focus(document.querySelector('.message-editor textarea'));
        this.set('transition', null);
      },
      deleteConversationClicked: function deleteConversationClicked() {
        var _this = this;
        this._autoSavingChain(function () {
          _this.deleteConversation();
        });
      },
      deleteConversationPopup: function deleteConversationPopup() {
        this.toggleProperty('_showConfirmDelete');
      },
      cancelClicked: function cancelClicked(param) {
        if (this.get('cancelButtonDisabled') || param) {
          // Disable the double cancel confirmation
          this.onComposerReset();
          // Close the modal
          this.set('showModal', false);
          // Remove the answer part
          this.get('cancelAnswer')();
          this.get('showHistoryButton')(false);
          this.get('showHistory')(true);
          this._super();
        } else {
          this.set('showModal', true);
          this.set('transition', null);
        }
      },
      saveClicked: function saveClicked() {
        // Disable the double cancel confirmation
        this.onComposerReset();
        // Close the modal
        this.set('showModal', false);
        this._super();
      },
      handleBtnKeyUp: function handleBtnKeyUp(event) {
        // Open pop-up on enter
        if (event.which == '13' || event.which == '32') {
          this.$('#conversation-composer-uploader').click();
        }
      },
      showHistoryBis: function showHistoryBis() {
        this.get('showHistory')(false);
        this.get('showHistoryButton')(true);
      }
    }
  });
});