define("lbp-cust-web-application/components/a11y/a11y-semantic-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    // Remove div tag
    iconClass: undefined,
    // Name of the icon css class
    label: '',
    // Alt text used
    srText: Ember.computed.oneWay('label'),
    // Specific text for screen readers
    smuTitle: Ember.computed.oneWay('label'),
    // Specific text for sighted mouse users
    textClass: 'conversation-header-component__btn-text',
    fixClass: '',
    displaySpanSrOnly: Ember.computed('srText', function () {
      return this.get('srText');
    })
  });
});