define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/label/popup-edit-component", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/label/popup-edit-component", "ember-cp-validations"], function (_exports, _popupEditComponent, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'interactions.label.popup-edit-component.label.error'
    })]
  });
  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/label/popup-edit-component',
    // eslint-disable-line
    initialize: initialize
  };
  _popupEditComponent.default.reopen(Validations, {
    originalFocusId: undefined,
    nameDescribedby: Ember.computed('showErrors', function () {
      return this.get("showErrors") ? "error-input-label-name-" + this.get('elementId') : "";
    }),
    labels: [['label-color-1', 'label-color-2', 'label-color-3', 'label-color-4'], ['label-color-5', 'label-color-6', 'label-color-7', 'label-color-8']],
    didRender: function didRender() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);
      if (this.get('colorId') === undefined) {
        this.set('colorId', this.get('labels').get(0).get(0));
      }
    },
    keyDown: function keyDown(event) {
      // Close modal on escape
      if (event.keyCode === 27) {
        this.send('closeAction');
      }
    },
    validate: function validate() {
      var _this = this;
      // Validate the form
      this.set('showErrors', true);
      if (!this.get('validations.isValid')) {
        window.toastr.options.onHidden = function () {
          var id = _this.get('model').id ? _this.get('model').id : '';
          var elementWithError = document.getElementById("fieldName-".concat(id));
          if (elementWithError) {
            elementWithError.focus();
          }
        };
        this.showError('interactions.label.popup-edit-component.message-info.error.invalid-form');
        return Ember.RSVP.reject();
      }
      return Ember.RSVP.resolve();
    },
    actions: {
      saveAction: function saveAction() {
        this._super();
        if (this.get('validations.attrs.name.isInvalid') && this.get('showErrors.name')) {
          document.getElementById("fieldName-".concat(this.get('uniqueIdentifier'))).focus();
        }
      },
      colorChangeAction: function colorChangeAction(selectedLabel) {
        this.set('colorId', selectedLabel);
      },
      onKeyDownLabel: function onKeyDownLabel(selectedLabel) {
        // Catch only enter or space key
        if (event.keyCode === 13 || event.keyCode === 32) {
          this.send('colorChangeAction', selectedLabel);
        }
      },
      onKeyUpName: function onKeyUpName() {
        if (this.get('name') && this.get('name').length > 20) this.set('name', this.get('name').substr(0, 20));
      }
    }
  });
});