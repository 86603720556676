define("lbp-cust-web-application/initializers/models/interlocutor", ["exports", "ember-data", "ticust-web-common/models/interlocutor"], function (_exports, _emberData, _interlocutor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-common/models/interlocutor',
    // eslint-disable-line
    initialize: initialize
  };
  _interlocutor.default.reopen({
    wallet: _emberData.default.attr('string'),
    displayAll: Ember.computed('fullName', 'isPhysicalPerson', 'businessName', 'civility', function () {
      if (this.get('isPhysicalPerson')) {
        var result = this.get('fullName');
        if (this.get('civility')) {
          result = this.get('civility') + ' ' + result;
        }
        return result;
      }
      return this.get('businessName');
    })
  });
});