define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/editor/message-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NmK4OVYD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"textarea\"],[12,\"id\",[21,\"idTextarea\"]],[12,\"aria-labelledby\",[28,[[21,\"labbelledBy\"]]]],[12,\"placeholder\",[28,[[27,\"if\",[[23,[\"displayPlaceholder\"]],[23,[\"placeholder\"]]],null]]]],[12,\"onfocus\",[27,\"action\",[[22,0,[]],\"displayHistory\"],null]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"onTextChanged\"],[[\"event\"],[\"target.value\"]]]],[12,\"onkeyup\",[27,\"action\",[[22,0,[]],\"onTextChanged\"],[[\"event\"],[\"target.value\"]]]],[12,\"disabled\",[28,[[21,\"disabled\"]]]],[12,\"class\",[28,[[21,\"class\"]]]],[12,\"maxlength\",[28,[[21,\"maxlength\"]]]],[12,\"aria-describedby\",[28,[[21,\"ariaDescribedBy\"]]]],[9],[0,\"\"],[10],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/editor/message-editor.hbs"
    }
  });
});