define("lbp-cust-web-application/services/project-reader-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var READER_TYPE = 'CLIENT';
  var _default = _exports.default = Ember.Service.extend({
    // Services
    ajax: Ember.inject.service(),
    identityService: Ember.inject.service(),
    config: Ember.inject.service(),
    ajaxNamespace: Ember.computed.alias('config.namespace'),
    markDocumentAsRead: function markDocumentAsRead(documentId, reader) {
      var _this = this;
      var url = this.get('ajaxNamespace') + '/project-part-documents/' + documentId + '/read';
      return this.get('ajax').raw(url, {
        method: 'PATCH',
        headers: this.get('identityService.ajaxHeaders'),
        data: JSON.stringify({
          reader: reader,
          type: READER_TYPE
        })
      }).catch(function (error) {
        _this.get('tiLogger').error('Cannot update the read status for project document', error);
      });
    },
    markProjectAsRead: function markProjectAsRead(projectId, reader) {
      var _this2 = this;
      var url = this.get('ajaxNamespace') + '/projects/' + projectId + '/read';
      return this.get('ajax').raw(url, {
        method: 'PATCH',
        headers: this.get('identityService.ajaxHeaders'),
        data: JSON.stringify({
          reader: reader,
          type: READER_TYPE
        })
      }).catch(function (error) {
        _this2.get('tiLogger').error('Cannot update the read status for project', error);
      });
    }
  });
});