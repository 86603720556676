define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-accordion-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['project-accordion'],
    classNameBindings: ['isOpen:opened:closed'],
    title: undefined,
    // Use either an icon, or a numbered index before the element name.
    icon: undefined,
    index: undefined,
    ariaLabel: undefined,
    isOpen: false,
    ariaExpanded: Ember.computed('isOpen', function () {
      return '' + this.get('isOpen');
    }),
    indicator: undefined,
    class: '',
    hasIndicator: Ember.computed.notEmpty('indicator'),
    hasIndex: Ember.computed.notEmpty('index'),
    bodyElementId: Ember.computed('elementId', function () {
      return 'project-accordion-detail-' + this.get('elementId');
    }),
    actions: {
      toggle: function toggle() {
        // Update isOpen boolean
        this.toggleProperty('isOpen');

        // Call existing callback action
        if (this.get('onOpen') && this.get('isOpen')) {
          this.set('hasIndicator', false);
          this.get('onOpen')();
        }
      }
    }
  });
});