define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-project-bloc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['panel-body container-fluid']
  });
});