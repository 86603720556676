define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation-footer-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'visible-xs-block conversation-footer-component',
    intlPrefix: 'interactions.conversation-line-component',
    // Data
    _columnWidth: Ember.computed('showLabelsButton', 'showDeleteButton', 'showRestoreButton', 'showRemoveFromTrashButton', function () {
      var number = 1;
      if (this.get('showLabelsButton')) {
        number++;
      }
      if (this.get('showDeleteButton')) {
        number++;
      }
      if (this.get('showDeleteDraftButton')) {
        number++;
      }
      if (this.get('showRestoreButton')) {
        number++;
      }
      if (this.get('showRemoveFromTrashButton')) {
        number++;
      }
      return 12 / number;
    }),
    _divClass: Ember.computed('_columnWidth', function () {
      var column = this.get('_columnWidth');
      return 'col-xs-' + column + ' col-sm-' + column + ' ellipsis conversation-header-component__item';
    }),
    // Actions
    unselectAll: function unselectAll() {
      throw new Error("Missing 'unselectAll' handler");
    },
    categorize: function categorize() {
      throw new Error("Missing 'categorize' handler");
    },
    delete: function _delete() {
      throw new Error("Missing 'delete' handler");
    },
    deleteDraft: function deleteDraft() {
      throw new Error("Missing 'deleteDraft' handler");
    },
    removeFromTrash: function removeFromTrash() {
      throw new Error("Missing 'removeFromTrash' handler");
    },
    restore: function restore() {
      throw new Error("Missing 'restore' handler");
    },
    actions: {
      cancel: function cancel() {
        this.get('unselectAll')();
      },
      categorize: function categorize() {
        this.get('categorize')();
      },
      delete: function _delete() {
        this.get('delete')();
      },
      deleteDraft: function deleteDraft() {
        this.get('deleteDraft')();
      },
      removeFromTrash: function removeFromTrash() {
        this.get('removeFromTrash')();
      },
      restore: function restore() {
        this.get('restore')();
      }
    }
  });
});