define("lbp-cust-web-application/routes/app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/claim", ["exports", "ti-web-shared/mixins/router-mixin"], function (_exports, _routerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_routerMixin.default, {
    templateName: 'app/ticust-web-module-interactions/interlocutors/interlocutor/conversations/claim',
    apiSettings: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    userOnDesktop: Ember.computed.alias('identity.userOnDesktop'),
    model: function model() {
      return Ember.RSVP.hash({
        interlocutor: this.modelFor('app.ticust-web-module-interactions.interlocutors.interlocutor'),
        claimUrlMobile: this.get('apiSettings').getSettingByKey('organization', null, 'adv.module.interactions.claim.default.url.mobile'),
        claimUrlDesktop: this.get('apiSettings').getSettingByKey('organization', null, 'adv.module.interactions.claim.default.url.desktop')
      });
    },
    afterModel: function afterModel(model) {
      if (this.get('userOnDesktop')) {
        model.claimUrl = model.claimUrlDesktop.get('settingValue');
      } else {
        model.claimUrl = model.claimUrlMobile.get('settingValue');
      }
    }
  });
});