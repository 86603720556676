define("lbp-cust-web-application/templates/components/common/dropdown/dropdown-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0TdVvZ96",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"a11y/a11y-action-div\",null,[[\"id\",\"class\",\"label\",\"action\",\"aria-expanded\"],[[23,[\"actionDivId\"]],[23,[\"_actionDivStyle\"]],[23,[\"label\"]],[27,\"action\",[[22,0,[]],\"toggleDropDown\"],null],[27,\"if\",[[23,[\"ariaExpanded\"]],\"true\",\"false\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"label\",\"iconClass\"],[[23,[\"label\"]],[23,[\"iconClass\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"id\",\"panel-drop-down-region\"],[12,\"class\",[28,[\"panel-drop-down \",[21,\"_panelDropDownStyle\"]]]],[12,\"aria-hidden\",[28,[[27,\"not\",[[23,[\"ariaExpanded\"]]],null]]]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/common/dropdown/dropdown-component.hbs"
    }
  });
});