define("lbp-cust-web-application/initializers/controllers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/create", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/create", "ember-cp-validations", "lbp-cust-web-application/mixins/focus"], function (_exports, _create, _emberCpValidations, _focus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  // eslint-disable-line

  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.conversation.theme.content': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'themes.error.theme'
      })]
    },
    'model.recipient': {
      validators: []
    },
    'model.conversation.subject': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        messageKey: 'themes.error.subject'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^\s*\S.*$/,
        messageKey: 'themes.error.subject'
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        max: 100
      })]
    }
  });
  function initialize() {}
  var _default = _exports.default = {
    name: 'controller/interlocutors/interlocutor/conversations/create',
    initialize: initialize
  };
  _create.default.reopen(Validations, _focus.default, {
    // Does not show the modal when we open the route
    showExitConfirmationModal: false,
    //don't show popin when we message is sent
    isMessageSent: false,
    // eslint-disable-next-line ember/new-module-imports
    interlocutorController: Ember.inject.controller('interlocutors.interlocutor'),
    identity: Ember.inject.service('identity-service'),
    lbpService: Ember.inject.service('lbp-service'),
    intl: Ember.inject.service(),
    userOnDesktop: Ember.computed.alias('identity.userOnDesktop'),
    urlClaim: '',
    displayClaimForm: false,
    isPads: Ember.computed.alias('lbpService.isPads'),
    transitionToClaim: Ember.observer('model.conversation.theme.claimCode', function () {
      /* If the selected theme has a claim code and the code is configured in the setting,
       * then display claim form */
      var codeUrlMap = this.get('settingMap');
      var themeClaimCode = this.get('model.conversation.theme.claimCode');
      if (themeClaimCode && codeUrlMap) {
        var codeUrlEntry = codeUrlMap.find(function (codeUrlEntry) {
          return codeUrlEntry[0] === themeClaimCode;
        });
        if (codeUrlEntry) {
          // MOBILE or TABLETTE
          if (!this.get('userOnDesktop')) {
            this.set('urlClaim', codeUrlEntry[2]);
          } else {
            this.set('urlClaim', codeUrlEntry[1]);
          }
          this.set('displayClaimForm', true);
        }
      }
    }),
    _focusTextEditor: function _focusTextEditor() {
      var textEditor = document.getElementById('conversation-composer-editor');
      if (textEditor) {
        textEditor.focus();
      }
    },
    fieldsForTheme: Ember.computed('model.conversation.theme.fields', function () {
      // Get additional fields related to the selected theme
      var fields = this.get('model.conversation.theme.fields');
      // Set selected options of additional fields
      if (fields) {
        fields.forEach(function (item) {
          // eslint-disable-next-line ember/new-module-imports,ember/no-side-effects
          Ember.set(item, 'selectedOption', '');
        });
        fields = fields.sort(function (f1, f2) {
          return f1.get('name').localeCompare(f2.get('name'));
        });
      }
      return fields;
    }),
    showBlacklistedModal: false,
    useSelfService: Ember.computed('iaIsEnabled', function () {
      return this.get('iaIsEnabled') === 'true';
    }),
    displayThemeRequiredError: Ember.computed('validations.attrs.model.conversation.theme.content.isInvalid', 'showErrors.theme', function () {
      return this.get('validations.attrs.model.conversation.theme.content.isInvalid') && this.get('showErrors.theme');
    }),
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);

      // Validation - add new attribute to showErrors
      this.set('showErrors.theme', false);
    },
    selfServiceValidateMisc: function selfServiceValidateMisc() {
      // Redirect to my conversations
      this.set('composerIsDirty', false);
      this.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', this.get('interlocutorController.model'), 'conversationMine');
    },
    pageTitle: Ember.on('init', Ember.observer('model', function () {
      if (this.get('title')) {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name', {
          pageName: this.get('intl').t('interactions.conversation.create.title.panel')
        });
      }
    })),
    actions: {
      closeModal: function closeModal() {
        this.set("isMessageSent", true);
      },
      backToList: function backToList() {
        this.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', this.get('interlocutorController.model'), 'conversationMine');
      },
      newMessage: function newMessage() {
        this.set('themeSelected', null);
        this.set('displayClaimForm', false);
      },
      onBlacklistedModalClose: function onBlacklistedModalClose() {
        this.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', this.get('interlocutorController.model'), 'conversationMine');
      },
      renewSession: function renewSession(_this, userIdle, tiLogger, lbpservice) {
        userIdle.resetTimeout();
        tiLogger.info('EES Session updated');
        lbpservice.keepAlive();
      },
      cancelMessage: function cancelMessage() {
        this.send('cancelCreateInRoute', this.get('_transition'));
      },
      sendMessage: function sendMessage(message) {
        var _this2 = this;
        var focusOnFirstError = function focusOnFirstError() {
          var fieldsForThemeErrorIndex = _this2.get('fieldsForThemeErrorIndex');
          if (_this2.get('displayThemeRequiredError') === true) {
            document.querySelector('#conversation-composer-create-select-theme').focus();
          } else if (fieldsForThemeErrorIndex !== -1) {
            var errorNodes = document.querySelectorAll('select.conversation-composer-create-fields-for-theme');
            errorNodes[fieldsForThemeErrorIndex].focus();
          } else if (_this2.get('displaySubjectError') === true) {
            document.querySelector('#inputConversationSender').focus();
          }
        };
        window.toastr.options.onHidden = function () {
          var elements = document.querySelectorAll('.panel-body.conversation-main.panel-body-border-exact ' + '.conversation-message-list-line .conversation-message-header__container.hidden-xs');
          if (elements) {
            if (elements.length > 0) {
              var element = elements.item(elements.length - 1);
              element.focus();
            }
          }
        };

        // Validate the form
        var hasFieldsError = this._validateFields();
        if (this.get('messageIsEmpty')) {
          this.set('showErrors.message', true);
          this.get('_focusTextEditor')();
          return;
        } else if (!this.get('validations.isValid') || this.get('isCharacterLimitExceeded') || this.get('displaySubjectError')) {
          this.set('showErrors.theme', true);
          this.set('showErrors.subject', true);
          this.set('showErrors.message', true);
          focusOnFirstError();
          return;
        } else if (hasFieldsError) {
          focusOnFirstError();
          return;
        }
        if (this.get('identity.legalGuardianIdPph')) {
          message = this.get('identity').checkAndSetLegalGuardianSentence(message);
        }

        // Save parent send message as callback.
        var sendMessageCallback = function sendMessageCallback() {
          _this2.send('createConversationInRoute', message, 'SENT');
        };
        if (this.get('useSelfService')) {
          // Call self-service interceptor
          this.selfServiceMessageInterception(message, sendMessageCallback);
        } else {
          // Do the normal sendMessageCall - default case
          sendMessageCallback(message);
        }
      },
      saveDraftMessage: function saveDraftMessage(draftMessage) {
        var _this3 = this;
        var autoSaving = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        if (autoSaving && !this.get('isDirty')) {
          return;
        }

        // Validate the form
        if (!this.get('validations.isValid') || this.get('displaySubjectError')) {
          if (!autoSaving) {
            this.set('showErrors.theme', true);
            // Close the confirmation modal
            this.set('showExitConfirmationModal', false);
            // Product code
            this.set('showErrors.subject', true);
            window.toastr.options.onHidden = function () {
              var fieldsForThemeErrorIndex = _this3.get('fieldsForThemeErrorIndex');
              if (_this3.get('displayThemeRequiredError') === true) {
                document.querySelector('#conversation-composer-create-select-theme').focus();
              } else if (fieldsForThemeErrorIndex !== -1) {
                var errorNodes = document.querySelectorAll('select.conversation-composer-create-fields-for-theme');
                errorNodes[fieldsForThemeErrorIndex].focus();
              } else if (_this3.get('displaySubjectError') === true) {
                document.querySelector('#inputConversationSender').focus();
              }
            };
            this.showError('interactions.conversation.create.message-info.error.invalid-form');
            return;
          }
        }
        this.genericFocusAfterToast("page-title", true, true);
        this.send('createConversationInRoute', draftMessage, 'DRAFT', this.get('_transition'), autoSaving, this.get('displaySubjectError'));
      }
    }
  });
});