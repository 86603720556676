define("lbp-cust-web-application/adapters/theme", ["exports", "lbp-cust-web-application/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    config: Ember.inject.service(),
    namespace: Ember.computed('config.namespace', function () {
      return this.get('config.namespace') + '/interco';
    })
  });
});