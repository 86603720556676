define("lbp-cust-web-application/templates/components/a11y/buttons/icon-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "paDSKgdW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"id\",[21,\"id\"]],[12,\"class\",[28,[\"show-icons \",[21,\"iconClass\"],\" \",[21,\"buttonClassInternal\"],\" \",[27,\"if\",[[23,[\"buttonText\"]],\"bt-create\"],null]]]],[12,\"disabled\",[21,\"disabled\"]],[12,\"type\",[21,\"type\"]],[9],[0,\"\\n  \"],[1,[27,\"if\",[[23,[\"buttonText\"]],[23,[\"buttonText\"]]],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"doAction\"],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"tooltipEnabled\"]]],null,{\"statements\":[[4,\"ember-tooltip\",null,[[\"event\",\"targetId\",\"side\"],[\"hover\",[23,[\"tooltipTarget\"]],[23,[\"tooltipSide\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[23,[\"paramButtonLabel\"]],[23,[\"paramButtonLabel\"]],[27,\"t\",[[23,[\"buttonLabel\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/a11y/buttons/icon-button.hbs"
    }
  });
});