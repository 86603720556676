define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/labels/settings", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/labels/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/labels/settings',
    // eslint-disable-line
    initialize: initialize
  };
  _settings.default.reopen({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.run.later(this, function () {
        controller.set('popupIsVisible', model.isCreateLabel || false);
        var createNewFolder = document.querySelector(".menu-icon-label-add");
        if (createNewFolder) {
          controller.set('originalFocusId', createNewFolder.id);
        }
      }, 10);
      if (model.isCreateLabel) {
        controller.set('newLabel', this.get('store').createRecord('label'));
        // Send Tag
        controller.get('tagService').sendPageTag({
          name: 'contacter::messagerie::popin_creation_dossier_personnel'
        });
      }
    },
    model: function model(params, transition) {
      return this._super(params, transition).then(function (hash) {
        hash.isCreateLabel = transition.queryParams.isCreateLabel === "true";
        return hash;
      });
    }
  });
});