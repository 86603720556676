define("lbp-cust-web-application/templates/app/ticust-web-module-projects/interlocutors/interlocutor/projects/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jrANuNt5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\"],[11,\"class\",\"page-content-main\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"tabindex\",\"0\"],[11,\"aria-level\",\"2\"],[9],[0,\"\\n\"],[4,\"ti-web-shared/a11y/focusable\",null,null,{\"statements\":[[4,\"if\",[[27,\"not\",[[27,\"eq\",[[23,[\"status\"]],\"open\"],null]],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"common-projects.side-menu.closed-projects\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"interlocutors.interlocutor.projects.search.open\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"project-page-wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"ticust-web-module-projects/projects/project-group\",null,[[\"title\",\"currentInterlocutor\",\"selectedTab\",\"sharedWithMe\",\"selectedStatusFilter\",\"openConversation\",\"openProject\"],[[23,[\"interlocutorProjectTitleText\"]],[23,[\"currentInterlocutor\"]],[23,[\"selectedTab\"]],false,[23,[\"status\"]],[27,\"action\",[[22,0,[]],\"openConversation\"],null],[27,\"action\",[[22,0,[]],\"openProject\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/app/ticust-web-module-projects/interlocutors/interlocutor/projects/index.hbs"
    }
  });
});