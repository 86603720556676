define("lbp-cust-web-application/initializers/controllers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/sources/source", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/sources/source", "lbp-cust-web-application/mixins/focus", "lbp-cust-web-application/mixins/keydown", "ti-web-shared/mixins/invalidate-session-mixin"], function (_exports, _source, _focus, _keydown, _invalidateSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  // eslint-disable-line
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/sources/source',
    // eslint-disable-line
    initialize: initialize
  };
  _source.default.reopen(_focus.default, _keydown.default, _invalidateSessionMixin.default, {
    lbpService: Ember.inject.service('lbp-service'),
    tiLogger: Ember.inject.service('logger-service'),
    session: Ember.inject.service(),
    originalFocusId: "page-title",
    pageSource: Ember.computed.alias('model.pageSource'),
    title: Ember.computed('pageSource', function () {
      if (this.get('pageSource')) {
        if ('search' === this.get('pageSource')) {
          this._setSearchFields();
        }
        return this.get('intl').t('interactions.conversations.panel-result.title.label.' + this.get('pageSource'));
      } else if (this.get('labelTitle')) {
        return this.get('labelTitle');
      }
    }),
    tagService: Ember.inject.service('tags'),
    store: Ember.inject.service(),
    paginationHelper: Ember.inject.service('pagination-helper'),
    indexFirstNewElement: undefined,
    labelTitle: Ember.computed.alias('model.labelSource.name'),
    _showConfirmDelete: false,
    _showCategorizePopup: false,
    showPaginationSeeMore: Ember.computed('conversations.meta.hasNext', function () {
      return this.get('conversations.meta.hasNext');
    }),
    showPaginationPrevious: Ember.computed('conversations.meta.hasPrevious', function () {
      return this.get('conversations.meta.hasPrevious');
    }),
    _displayNoResult: Ember.computed('pageSource', 'searchText', function () {
      return this.get('pageSource') !== 'search' || this.get('searchText');
    }),
    showDeleteDraftButton: Ember.computed.and('displayButtonConfig.deleteDraft', 'identity.hasRoleConversationModify'),
    isSearchDisabled: Ember.computed('isCurrentlyLoading', 'conversations.@each.isSaving', 'displayButtonConfig.search', function () {
      return !this.get('displayButtonConfig.search') || this.get('isCurrentlyLoading') || this.get('conversations').any(function (conversation) {
        return conversation.get('isSaving');
      });
    }),
    _noResultKey: Ember.computed('isSearchDisabled', 'searchPerimeter', function () {
      if (!this.get('isSearchDisabled') && this.get('searchPerimeter') && this.get('searchPerimeter') !== 'all') {
        return 'interactions.conversation-search-form.no-result';
      }
      return 'common.result-list.results.none';
    }),
    _getTitle: Ember.computed('pageSource', 'labelTitle', function () {
      if (this.get('pageSource')) {
        return this.get('intl').t('interactions.conversations.panel-result.title.label.' + this.get('pageSource'));
      }
      return this.get('labelTitle');
    }),
    isInbentaEnabled: Ember.computed('model.inbentaEnabled', function () {
      return this.get('model.inbentaEnabled') === 'true';
    }),
    selectedLabel: undefined,
    pageTitle: Ember.on('init', Ember.observer('title', function () {
      if (this.get('title')) {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name', {
          pageName: this.get('title')
        });
      }
    })),
    _saveChanges: function _saveChanges(action) {
      var _this = this;
      var refresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var data = arguments.length > 2 ? arguments[2] : undefined;
      (true && !(action) && Ember.assert('Missing action parametter', action));
      if (!data) {
        data = this.get('selectedConversations');
      }
      var promises = data.map(function (conversation) {
        return conversation.save().catch(function (error) {
          conversation.rollbackAttributes();
          _this.get('tiLogger').error("An error occured on '" + action + "' action.", error);
          return Ember.RSVP.reject(error);
        });
      });
      Ember.RSVP.all(promises).then(function () {
        _this.showSuccess('interactions.conversations.search.message-info.info.' + action);
        if (refresh) {
          _this.send('refreshModel');
        }
      }).catch(function () {
        _this.showError('interactions.conversations.search.message-info.error.' + action);
      });
    },
    _isNotSameLabel: function _isNotSameLabel(conversation) {
      var _this2 = this;
      // check if no label selected yet
      if (this._noLabelSelected(conversation)) {
        return true;
      }
      return conversation.get('labels').any(function (label) {
        return _this2.get('selectedLabel').any(function (selectedLabel) {
          return label.get('id') != selectedLabel.get('id');
        });
      });
    },
    _noLabelSelected: function _noLabelSelected(conversation) {
      // true if label is present in conversation
      var isNotNull = conversation.get('labels').any(function (label) {
        return label != undefined;
      });
      //  true if previous selected conversation have a label
      var selectedIsNotNull = this.get('selectedLabel').any(function (selectedLabel) {
        return selectedLabel != undefined;
      });
      if (!isNotNull && selectedIsNotNull) {
        return true;
      }
    },
    _setSearchFields: function _setSearchFields() {
      var searchValues = this.get('paginationHelper').getSearchValues();
      if (searchValues) {
        if (searchValues.currentPage) {
          this.set('currentPage', searchValues.currentPage);
        }
        if (searchValues.searchPerimeter) {
          this.set('searchPerimeter', searchValues.searchPerimeter);
        }
        if (searchValues.searchText) {
          this.set('searchText', searchValues.searchText);
        }
      }
    },
    _focusAfterDelete: function _focusAfterDelete() {
      var _this3 = this;
      window.toastr.options.onHidden = function () {
        var element = undefined;
        if (_this3.get('conversations').length === 0) {
          element = document.querySelector('p.empty-text');
          if (element) {
            element.setAttribute('tabindex', '-1');
          }
        } else {
          element = document.querySelector(".conversation-line-component__checkbox-wrapper" + ".conversation-line-component__checkbox-wrapper-margin");
        }
        if (element) {
          element.focus();
        }
      };
    },
    _focusElementAfterToast: function _focusElementAfterToast(elementSelector) {
      window.toastr.options.onHidden = function () {
        var element = undefined;
        if (elementSelector) {
          element = document.querySelector(elementSelector);
        } else {
          element = document.getElementById('page-title');
        }
        if (element) {
          element.setAttribute('tabindex', '-1');
          element.focus();
        }
      };
    },
    actions: {
      newMessage: function newMessage() {
        var _this4 = this;
        this.get('tagService').sendClickTag({
          name: 'contacter::messagerie::reception_bouton_nouveau_message',
          type: 'navigation'
        });
        var interlocutor = this.get('interlocutorController.model');
        if (this.lbpService.get('isPads')) {
          var uriNewMsg = this.lbpService.get('uriNewMessage');
          this.tiLogger.info("PADS - New message button clicked, redirecting to ".concat(uriNewMsg));
          this._invalidateSession({
            route: uriNewMsg,
            type: 'external'
          }).then(function () {
            _this4.get('session').invalidate('authenticator:lbp-token-auth');
          });
        } else if (this.get('isInbentaEnabled')) {
          this.transitionToRoute('interlocutors.interlocutor.conversations.inbenta', interlocutor);
          Ember.run.later(function () {
            _this4.genericFocusElement(".inbenta-title-backButton h2", false, true);
          }, 220);
        } else {
          this.transitionToRoute('interlocutors.interlocutor.conversations.create', interlocutor);
        }
      },
      removeFromTrash: function removeFromTrash() {
        var _this5 = this;
        this.get('selectedConversations').forEach(function (conversation) {
          conversation.set('status', 'TO_ARCHIVE');
        });
        this._saveChanges('removeFromTrash');
        this.set('_showConfirmDelete', false);
        Ember.run.later(function () {
          if (_this5.get('conversations').length - _this5.get('selectedConversations').length === 0) {
            _this5.genericFocusAfterToast("p.empty-text", false, true);
          } else {
            _this5.genericFocusAfterToast("check-all-box", true, false);
          }
        }, 50);
      },
      restore: function restore() {
        var _this6 = this;
        Ember.run.later(function () {
          if (_this6.get('conversations').length - _this6.get('selectedConversations').length === 0) {
            _this6.genericFocusAfterToast("p.empty-text", false, true);
          } else {
            _this6.genericFocusAfterToast("check-all-box", true, false);
          }
        }, 50);
        this._super();
      },
      removeFromTrashPopup: function removeFromTrashPopup() {
        this.set('_showConfirmDelete', true);
      },
      onLoadMore: function onLoadMore() {
        if (this.get('conversations').more) {
          this.get('paginationHelper').incrementPageNumber();
          this.get('conversations').more();
          this.set('indexFirstNewElement', this.get('conversations').length);
        }
      },
      onLoadPrevious: function onLoadPrevious() {
        if (this.get('conversations').previous) {
          this.get('conversations').previous();
        }
      },
      deleteDraft: function deleteDraft() {
        var _this7 = this;
        var data = this.get('selectedConversations').map(function (conversation) {
          // If the conversation has INIT status, delete it
          if (conversation.get('status') === 'INIT') {
            conversation.deleteRecord();
            return conversation;
          } else {
            // Just remove the draft message into the conversation
            return _this7.get('store').query('conversation-message', {
              conversation: conversation.id,
              status: 'DRAFT'
            }).then(function (messages) {
              if (messages.get('length') > 0) {
                var message = messages.get('firstObject');
                message.deleteRecord();
                return message;
              }
            });
          }
        });
        Ember.RSVP.Promise.all(data).then(function (result) {
          _this7._saveChanges('deleteDraft', true, result.filter(function (obj) {
            return obj;
          }));
        });
        this._focusAfterDelete();
      },
      showCategorizePopup: function showCategorizePopup() {
        var _this8 = this;
        // Send Tag
        this.get('tagService').sendPageTag({
          name: 'contacter::messagerie::popin_associer_dossier_personnel'
        });
        var BreakException = {};
        try {
          this.get('selectedConversations').forEach(function (conversation) {
            if (typeof _this8.get('selectedLabel') === 'undefined') {
              _this8.set('selectedLabel', conversation.get('labels'));
            } else if (_this8._isNotSameLabel(conversation)) {
              // reset selectedLabel and stop the forEach function
              _this8.set('selectedLabel', undefined);
              throw BreakException;
            }
          });
        } catch (e) {
          if (e !== BreakException) {
            throw e;
          }
        }
        // Open Popup
        this.set('_showCategorizePopup', true);
        Ember.run.later(function () {
          var closeButton = document.querySelector('.modal-close.modal-close-icon');
          if (closeButton) {
            closeButton.focus();
          }
        }, 200);
      },
      categorize: function categorize(selectedLabels) {
        // Save categorize
        var dirty = false;
        this.get('selectedConversations').forEach(function (conversation) {
          // tests for dirty check
          if (!dirty) {
            if (selectedLabels.length === 1 && conversation.get('labels.length') === 0) {
              dirty = true;
            } else if (selectedLabels.length === 0 && conversation.get('labels.length') === 1) {
              dirty = true;
            } else if (selectedLabels.length === 1 && conversation.get('labels.length') === 1 && selectedLabels[0].get('name') !== conversation.get('labels.firstObject.name')) {
              dirty = true;
            }
          }
          conversation.set('labels', selectedLabels);
        });
        var pagetitle = document.getElementById('page-title');
        if (pagetitle) {
          Ember.run.later(function () {
            pagetitle.blur();
          }, 10);
        }
        this.get('_focusElementAfterToast')();
        if (dirty) {
          this._saveChanges('categorize', false);
        }
        this.set('_showCategorizePopup', false);
        this.set('selectedLabel', undefined);
        this.set('selectedConversations', []);
      },
      uncheckAll: function uncheckAll() {
        this.set('selectedConversations', []);
        this.set('selectedLabel', undefined);
        var element = document.querySelector(".conversation-line-component__checkbox-wrapper" + ".conversation-line-component__checkbox-wrapper-margin");
        element.focus();
      },
      search: function search(perimeter, text) {
        this.set('searchPerimeter', perimeter);
        this.set('searchText', text);
        this.send('refreshModel');
      },
      openConversation: function openConversation(pageSource, conversation) {
        this.get('tagService').sendClickTag({
          name: 'contacter::messagerie::ouverture_message'
        });
        var interlocutor = this.get('interlocutorController.model');
        this.set('lbpService.openConversationClick', true);
        this.transitionToRoute('interlocutors.interlocutor.conversations.conversation', interlocutor, conversation.get('id'), {
          queryParams: {
            pageSource: pageSource
          }
        });
      },
      openDraftConversation: function openDraftConversation(pageSource, conversation) {
        var interlocutor = this.get('interlocutorController.model');
        this.transitionToRoute('interlocutors.interlocutor.conversations.drafts.draft', interlocutor, conversation.get('id'), {
          queryParams: {
            pageSource: pageSource
          }
        });
      },
      clearText: function clearText() {
        // Reset search text when the user clear the text
        this.set('searchText', '');
      },
      delete: function _delete() {
        this.get('selectedConversations').forEach(function (conversation) {
          conversation.set('status', 'DELETED');
        });
        this._focusAfterDelete();
        this._saveChanges('delete');
      },
      focusFirstCheckbox: function focusFirstCheckbox() {
        var firstCheckbox = document.querySelector('.lbp-icon-check');
        if (firstCheckbox) {
          firstCheckbox.focus();
        }
      },
      focusSelectAllCheckbox: function focusSelectAllCheckbox() {
        var selectAllCheckbox = document.querySelector('.conversation-line-component__element');
        if (selectAllCheckbox) {
          selectAllCheckbox.focus();
        }
      },
      handleKeyDown: function handleKeyDown(event) {
        this.keyDown(event);
      }
    }
  });
});