define("lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-unread-documents-indicators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uhFlUfvJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"hasUnreadNewDocuments\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ti-web-shared/projects/lbp-indicator\",null,[[\"icon\",\"label\",\"title\",\"action\"],[\"project-new-docs project-docs-indicator\",[27,\"t\",[\"projects.project.labels.newDocuments\"],[[\"nb\"],[[23,[\"unreadNewDocuments\"]]]]],[27,\"t\",[\"projects.project.description.indicators.unread-new-documents.title\"],[[\"nb\"],[[23,[\"unreadNewDocuments\"]]]]],[23,[\"action\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"hasUnreadValidatedDocuments\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ti-web-shared/projects/lbp-indicator\",null,[[\"icon\",\"label\",\"title\",\"action\"],[\"project-validated-docs project-docs-indicator\",[27,\"t\",[\"projects.project.labels.unvalidatedDocuments\"],[[\"nb\"],[[23,[\"unreadValidatedDocuments\"]]]]],[27,\"t\",[\"projects.project.description.indicators.unread-validated-documents.title\"],[[\"nb\"],[[23,[\"unreadValidatedDocuments\"]]]]],[23,[\"action\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"hasUnreadRejectedDocuments\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ti-web-shared/projects/lbp-indicator\",null,[[\"icon\",\"label\",\"title\",\"action\"],[\"project-rejected-docs project-docs-indicator\",[27,\"t\",[\"projects.project.labels.rejectedDocuments\"],[[\"nb\"],[[23,[\"unreadRejectedDocuments\"]]]]],[27,\"t\",[\"projects.project.description.indicators.unread-rejected-documents.title\"],[[\"nb\"],[[23,[\"unreadRejectedDocuments\"]]]]],[23,[\"action\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-unread-documents-indicators.hbs"
    }
  });
});