define("lbp-cust-web-application/templates/components/a11y/a11y-semantic-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zOnq2Mb2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[11,\"aria-hidden\",\"true\"],[12,\"class\",[28,[[21,\"iconClass\"]]]],[12,\"title\",[28,[[21,\"smuTitle\"]]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"displaySpanSrOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[28,[\"sr-only \",[21,\"fixClass\"]]]],[9],[1,[21,\"srText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"text\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[28,[[21,\"textClass\"]]]],[12,\"aria-hidden\",[28,[[21,\"srHidden\"]]]],[9],[1,[21,\"text\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/a11y/a11y-semantic-icon.hbs"
    }
  });
});