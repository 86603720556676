define("lbp-cust-web-application/initializers/mixins/ti-web-shared/toastMixin", ["exports", "ti-web-shared/mixins/toastMixin"], function (_exports, _toastMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = _exports.default = {
    name: 'mixins/ti-web-shared/toastMixin',
    // eslint-disable-line
    initialize: initialize
  };
  _toastMixin.default.reopen({
    closeLabelKey: 'shared.components.modals.title.closeButton',
    showErrorAndLog: function showErrorAndLog(intlKey, message, error, params) {
      this._setupToastOptions(intlKey, "Error", params);
      this.get('logger').error(message, error);
    },
    showError: function showError(intlKey, params) {
      this._setupToastOptions(intlKey, "Error", params);
    },
    showSuccess: function showSuccess(intlKey, params) {
      this._setupToastOptions(intlKey, "Success", params);
    },
    _setupToastOptions: function _setupToastOptions(intlKey, type, params) {
      var previousFocus = document.activeElement;
      var overrideOptions = {
        timeOut: 0,
        extendedTimeOut: 0,
        onclick: function onclick() {
          try {
            previousFocus.focus();
          } catch (e) {
            document.body.focus();
          }
        },
        onCloseClick: function onCloseClick() {
          try {
            previousFocus.focus();
          } catch (e) {
            document.body.focus();
          }
        },
        target: '#toastr-a11y-container',
        closeHtml: '<button type="button" class="toast-close-button"' + ' aria-describedby="contenu-toast-message"' + ' ><span aria-hidden="true">&times;' + '</span><span class="sr-only">Fermer ce message</span></button>'
      };
      this._createToast(intlKey, type, overrideOptions, false, true, params);
    },
    _createToast: function _createToast(intlKey, type, options, addAlertRole, setFocusOnCloseButton, params) {
      var alertDialog = null;
      if (type === 'Error') {
        alertDialog = this.get('toast').error('<p>' + this.get('intl').t(intlKey, params) + '</p>', '', options);
      } else if (type === 'Success') {
        alertDialog = this.get('toast').success('<p>' + this.get('intl').t(intlKey, params) + '</p>', '', options);
      } else if (type === 'Warning') {
        alertDialog = this.get('toast').warning('<p>' + this.get('intl').t(intlKey, params) + '</p>', '', options);
      } else {
        alertDialog = this.get('toast').info('<p>' + this.get('intl').t(intlKey, params) + '</p>', '', options);
      }
      if (alertDialog) {
        this._addAttributes(alertDialog, addAlertRole, setFocusOnCloseButton, type);
      }
    },
    _addAttributes: function _addAttributes(alertDialog, addAlertRole, setFocusOnCloseButton) {
      alertDialog.attr('aria-live', null);
      alertDialog.find('.toast-message').attr('id', 'contenu-toast-message');
      if (addAlertRole) {
        alertDialog.attr('role', 'status');
      }
      if (setFocusOnCloseButton) {
        // Set focus on close button
        Ember.run.later(this, function () {
          alertDialog.find('.toast-close-button').first().focus();
        }, 250);
      }
      var a11yContainer = document.getElementById('toastr-a11y-container');
      if (a11yContainer) {
        a11yContainer.appendChild(document.getElementById('toast-container'));
      }
      window.parent.postMessage('eesResetIframeScroll', '*');
    }
  });
});