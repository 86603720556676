define("lbp-cust-web-application/components/common/button/scroll-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    // Attribute bindings
    attributeBindings: ['label:aria-label', 'tabindex'],
    // Parameters
    tag: undefined,
    // Tag to scroll on click
    displayOnY: 0,
    // Display button from Y
    action: undefined,
    // Action after scrolling done
    scrollingTag: '#page-content-wrapper',
    // Tag which contains the scrolling bar
    duration: 200,
    // Animation duration,
    easing: 'swing',
    // Animation easing
    ariaRole: 'button',
    tabindex: 0,
    jScrollingTag: Ember.computed('scrollingTag', function () {
      return Ember.$(this.get('scrollingTag'));
    }),
    // Data
    _scrollListener: undefined,
    _coordinate: Ember.computed('tag', 'jScrollingTag', function () {
      if (this.get('tag')) {
        return this.get('jScrollingTag').scrollTop() + Ember.$(this.get('tag')).offset().top;
      }
      return 0;
    }),
    init: function init() {
      var _this = this;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);
      this.set('_scrollListener', function () {
        return Ember.run.later(_this, _this._manageIsVisible);
      });
      this.get('jScrollingTag').bind('scroll', this.get('_scrollListener'));
      this._manageIsVisible();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('jScrollingTag').unbind('scroll', this.get('_scrollListener'));
    },
    _manageIsVisible: function _manageIsVisible() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      var scrollY = this.get('jScrollingTag').scrollTop();
      this.set('isVisible', scrollY > this.get('displayOnY'));
    },
    // Onclick action
    // eslint-disable-next-line no-unused-vars
    click: function click(event) {
      var _this2 = this;
      var action = undefined;
      if (this.get('action')) {
        action = function action() {
          return Ember.run(_this2, _this2.get('action'));
        };
      } else {
        // eslint-disable-next-line ember/closure-actions
        action = function action() {
          return Ember.run(_this2, _this2.sendAction, 'afterScroll');
        };
      }
      this._scrollTo(action);
    },
    _scrollTo: function _scrollTo(complete) {
      var _this3 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('jScrollingTag').animate({
          scrollTop: _this3.get('_coordinate')
        }, _this3.get('duration'), _this3.get('easing'), complete).promise().then(resolve, reject);
      });
    }
  });
});