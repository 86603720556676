define("lbp-cust-web-application/initializers/components/ti-web-shared/spinner/spinner", ["exports", "ti-web-shared/components/ti-web-shared/spinner/spinner", "lbp-cust-web-application/templates/components/ti-web-shared/spinner/spinner"], function (_exports, _spinner, _spinner2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/components/ti-web-shared/spinner/spinner',
    // eslint-disable-line
    initialize: initialize
  };
  _spinner.default.reopen({
    layout: _spinner2.default
  });
});