define("lbp-cust-web-application/mixins/keydown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    keyDown: function keyDown(event) {
      // Catch only enter or space key
      if (event.keyCode === 13 || event.keyCode === 32) {
        if (document.activeElement) {
          document.activeElement.click();
        }
        event.preventDefault();
        return false;
      }
      this._super(event);
    }
  });
});