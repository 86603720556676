define("lbp-cust-web-application/initializers/engine-instance-override", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = _exports.default = {
    name: 'engine-instance-override',
    initialize: initialize
  };
  var overrideList = ['template', 'controller', 'route'];

  /*
   * Determine if a resource is ovveridable by the product in this file
   * return : object use as boolean
   */
  function isOverridable(fullName) {
    return split(fullName);
  }

  /*
   * Split a fullname into a structure {type: 'string', name: 'string'}
   * return a structure object or null if the resource is not allow to override
   */
  function split(fullName) {
    var split = fullName.split(':');
    var result = {
      type: split[0],
      name: split[1]
    };
    if (overrideList.includes(result.type)) {
      return result;
    }
    return null;
  }

  //
  // IMPORTANT:
  // The definition below redefines some Ember components.
  // Update this file after an update is mandatory
  // see: https://github.com/emberjs/ember.js/blob/v2.12.0/packages/ember-application/lib/system/engine-instance.js
  // and more precisely this mixin
  // https://github.com/emberjs/ember.js/blob/v2.14.1/packages/ember-runtime/lib/mixins/container_proxy.js#L94

  Ember.EngineInstance.reopen({
    engineParentContainer: Ember.computed('__container__.owner.mountPoint', function () {
      var owner = this.__container__.owner;
      if (owner.mountPoint) {
        var engineKey = Object.keys(owner).filter(function (key) {
          return key.includes('__ENGINE_PARENT__');
        })[0];
        return owner[engineKey];
      }
      return null;
    }),
    lookup: function lookup(fullName, options) {
      if (isOverridable(fullName)) {
        var owner = this.__container__.owner;
        if (owner.mountPoint) {
          var resourceInfo = split(fullName);
          var override = this.get('engineParentContainer').lookup("".concat(resourceInfo.type, ":").concat(owner.mountPoint, ".").concat(resourceInfo.name));
          if (override) {
            return override;
          }
        }
      }
      return this._super(fullName, options);
    }
  });
});