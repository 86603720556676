define("lbp-cust-web-application/routes/app/ticust-web-module-projects/interlocutors/interlocutor/help", ["exports", "ti-web-shared/mixins/router-mixin"], function (_exports, _routerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_routerMixin.default, {
    // Layout
    templateName: 'app/ticust-web-module-projects/interlocutors/interlocutor/help',
    // Services
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    tagService: Ember.inject.service('tags'),
    model: function model() {
      var _this = this;
      var criteria = {
        notification: 'HELP_PROJECTS'
      };
      return this.get('store').query('notification-template', criteria).then(function (templates) {
        var locale = _this.get('intl.locale')[0];
        var template = templates.find(function (template) {
          locale.startsWith(template.get('language'));
        });
        if (!template) {
          template = templates.get('firstObject');
        }
        return template;
      });
    },
    afterModel: function afterModel() {
      this.get('tagService').sendPageTag({
        name: 'contacter::espace_echange_documents::aide'
      });
    }
  });
});