define("lbp-cust-web-application/controllers/ticust-web-module-authentication/token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    queryParams: ['otu'],
    otu: null
  });
});