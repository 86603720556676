define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/conversation-message", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message"], function (_exports, _conversationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message',
    // eslint-disable-line
    initialize: initialize
  };
  _conversationMessage.default.reopen({
    displayAttachmentId: false,
    _isMessageExpanded: Ember.computed.oneWay('isMessageExpanded'),
    attachmentCount: Ember.computed('attachments', function () {
      return this.get('attachments').length;
    }),
    participants: Ember.computed.alias('message.participants'),
    fromParticipants: Ember.computed.filterBy('participants', 'type', 'FROM'),
    fromParticipantsMe: Ember.computed.filter('fromParticipants', function (participant) {
      return this.get('identityService.selectedInterlocutor.internalEmailAddress') === participant.email;
    })
  });
});