define("lbp-cust-web-application/initializers/services/ticust-web-common/identity-service", ["exports", "ticust-web-common/services/identity-service"], function (_exports, _identityService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-common/services/identity-service',
    initialize: initialize
  };
  _identityService.default.reopen({
    userTypo: Ember.computed.alias('session.data.authenticated.typo'),
    userAccounts: Ember.computed('session.data.authenticated.accounts', function () {
      return this.get('session.data.authenticated.accounts') || [];
    }),
    userCreditAccounts: Ember.computed('session.data.authenticated.creditAccounts', function () {
      return this.get('session.data.authenticated.creditAccounts') || [];
    }),
    userPhones: Ember.computed('session.data.authenticated.phones', function () {
      return this.get('session.data.authenticated.phones') || [];
    }),
    userDefaultAccount: Ember.computed.alias('userAccounts.firstObject'),
    userDefaultCreditAccount: Ember.computed.alias('userCreditAccounts.firstObject'),
    userFinancialCenter: Ember.computed.alias('session.data.authenticated.financialCenter'),
    userReturnUrl: Ember.computed.alias('session.data.authenticated.returnUrl'),
    userCallingCode: Ember.computed.alias('session.data.context.callingCode'),
    authSuccess: false,
    isBlacklisted: Ember.computed('selectedInterlocutor', 'selectedInterlocutor.blacklisted', function () {
      return !this.isActor() && this.get('selectedInterlocutor.blacklisted');
    }),
    isLbpCs: Ember.computed.equal('userTypo', '001'),
    userDeviceTheme: Ember.computed('userCallingCode', function () {
      var callingCode = this.get('userCallingCode');
      switch (callingCode) {
        case '9241':
        case '9240':
        case '9228':
          return 'MOBILE';
        case '9251':
        case '9250':
          return 'TABLETTE';
      }
      return 'DESKTOP';
    }),
    userOnDesktop: Ember.computed('userDeviceTheme', function () {
      return this.get('userDeviceTheme') === 'DESKTOP';
    }),
    legalGuardianIdPph: Ember.computed('userId', function () {
      var userId = this.get('userId');
      if (userId.includes(".")) {
        return userId.split('.')[0].slice(4);
      } else {
        return undefined;
      }
    }),
    legalGuardianSentence: '',
    loadRights: function loadRights(interlocutorId) {
      var _this = this;
      if (interlocutorId) {
        // get right object extract from token infos
        // In case of legal guardian we need have a user rights and
        // right.entity = "LegalGuardianId.InterlocutorId"
        var interlocutorSelectedRightObect = this.get('userRights').find(function (right) {
          return right.entity === interlocutorId;
        });
        return this.get('store').findRecord('profile', interlocutorSelectedRightObect.profile).then(function (profile) {
          var roles = profile.get('externalPermissions').map(function (role) {
            return role.name;
          });
          _this.set('selectedInterlocutorRoles', roles);
        }).catch(function (error) {
          _this.get('logger').error('An error occured on get of profile :' + interlocutorSelectedRightObect.profile, error);
          _this.set('selectedInterlocutorRoles', []);
        });
      } else {
        var userId = this.get('userId');
        return this.get('store').findRecord('user', userId).then(function (user) {
          return user.get('interlocutor').then(function (interlocutor) {
            if (_this.legalGuardianIdPph) {
              _this.set('legalGuardianSentence', _this.buildLegalGuardianSentence(user, interlocutor));
            }
            return _this.loadRights(interlocutor.get('id'));
          });
        });
      }
    },
    _userChanged: Ember.on('init', Ember.observer('userId', 'session.isAuthenticated', 'authSuccess', function () {
      var _this2 = this;
      var userId = this.get('userId');
      // Do not make the request if the user is not authenticated
      if (!userId || !this.get('session.isAuthenticated') || !this.get('authSuccess')) {
        this.set('user', null);
        this.set('userPromise', null);
        return;
      }
      if (this.get('userPromise')) {
        return;
      }
      this.set('user', null);
      this.set('userPromise', this.get('store').findRecord('user', userId));
      this.get('userPromise').then(function (result) {
        _this2.set('user', result);
      }).catch(function (error) {
        _this2.get('logger').error('Failed to get the current user data: ', error);
      });
    })),
    // From LBP sentence model:
    // « Message envoyé par <PRENOM_RL> <NOM_RL> ,
    // représentant(e) légal(e) de <TITRE_MP> <PRENOM_MP><NOM_MP> »
    buildLegalGuardianSentence: function buildLegalGuardianSentence(user, interlocutor) {
      var userArrInfo = [user.get('firstName'), ' ', user.get('lastName')];
      var interlocutorArrInfo = [interlocutor.get('civility') === undefined ? '' : interlocutor.get('civility'), ' ', interlocutor.get('firstName'), ' ', interlocutor.get('lastName')];
      return this.get('intl').t('interactions.conversation.message-info.legal-guardian', {
        userInfo: userArrInfo.join(''),
        interlocutorInfo: interlocutorArrInfo.join('')
      });
    },
    checkAndSetLegalGuardianSentence: function checkAndSetLegalGuardianSentence(messageContent) {
      var messageBody = messageContent.get('bodyTextPlainParts')[0].get('body');
      var htmlMessageBody = messageContent.get('bodyTextHtmlParts')[0].get('body');
      var sentenceToInclude = this.get('legalGuardianSentence');
      if (sentenceToInclude === '') {
        sentenceToInclude = this.buildLegalGuardianSentence(this.get('user'), this.get('selectedInterlocutor'));
      }
      if (messageBody.includes(sentenceToInclude) && htmlMessageBody.includes(sentenceToInclude)) {
        return messageContent;
      }
      var textPlain = sentenceToInclude + '\r\n\r\n' + messageBody;
      var textHtml = '<p>' + sentenceToInclude + '</p>\n<p>&nbsp;</p>\n' + htmlMessageBody;
      messageContent.get('bodyTextPlainParts')[0].set('body', textPlain);
      messageContent.get('bodyTextHtmlParts')[0].set('body', textHtml);
      return messageContent;
    }
  });
});