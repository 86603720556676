define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/conversation-message-list-line", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-list-line"], function (_exports, _conversationMessageListLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-list-line',
    // eslint-disable-line
    initialize: initialize
  };
  _conversationMessageListLine.default.reopen({
    role: 'listitem'
  });
});