define("lbp-cust-web-application/initializers/models/conversation", ["exports", "ember-data", "ticust-web-common/models/conversation"], function (_exports, _emberData, _conversation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-common/models/conversation',
    // eslint-disable-line
    initialize: initialize
  };
  _conversation.default.reopen({
    messageStatuses: _emberData.default.attr()
  });
});