define("lbp-cust-web-application/initializers/routes/ticust-web-module-authentication/login", ["exports", "ticust-web-module-authentication/routes/login", "ember-uuid", "ember-cp-validations"], function (_exports, _login, _emberUuid, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-authentication/routes/login',
    // eslint-disable-line
    initialize: initialize
  };
  var Validations = (0, _emberCpValidations.buildValidations)({
    username: (0, _emberCpValidations.validator)('presence', true),
    password: (0, _emberCpValidations.validator)('presence', true),
    company: (0, _emberCpValidations.validator)('presence', true),
    typo: (0, _emberCpValidations.validator)('presence', true),
    financialCenter: (0, _emberCpValidations.validator)('presence', true),
    accounts: (0, _emberCpValidations.validator)('presence', true),
    creditAccounts: (0, _emberCpValidations.validator)('presence', true),
    phones: (0, _emberCpValidations.validator)('presence', true),
    returnUrl: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'url'
    })],
    callingCode: (0, _emberCpValidations.validator)('presence', true)
  });
  _login.default.reopen({
    model: function model() {
      return Ember.Object.extend(Validations).create(Ember.getOwner(this).ownerInjection(), {
        username: '',
        das: '',
        password: '',
        legalGuardian: '',
        company: '',
        typo: '000',
        financialCenter: '338100',
        accounts: 'CCP|A|0132174A027,CNE|B|0132174A028',
        creditAccounts: 'CCP|A|0132174A027,CNE|B|0132174A028',
        phones: '000005596,000005597',
        returnUrl: 'http://google.fr',
        callingCode: '0004',
        callingOrigin: 'DESKTOP'
      });
    },
    _getCredentialsData: function _getCredentialsData() {
      var userName = this.get('controller.model.username');
      var legalGuardian = this.get('controller.model.legalGuardian');
      if (legalGuardian.trim().length > 0) {
        userName = legalGuardian + '.' + userName;
      }
      return {
        username: userName,
        das: this.get('controller.model.das'),
        password: this.get('controller.model.password'),
        legalGuardian: legalGuardian,
        company: this.get('controller.model.company').toLowerCase(),
        typo: this.get('controller.model.typo'),
        financialCenter: this.get('controller.model.financialCenter'),
        accounts: this.get('controller.model.accounts').split(',').map(function (data) {
          var account = data.split('|');
          return {
            label: '',
            nature: account[1],
            number: account[2],
            type: account[0]
          };
        }),
        creditAccounts: this.get('controller.model.creditAccounts').split(',').map(function (data) {
          var account = data.split('|');
          return {
            label: '',
            nature: account[1],
            number: account[2],
            type: account[0]
          };
        }),
        phones: this.get('controller.model.phones').split(','),
        returnUrl: this.get('controller.model.returnUrl'),
        callingCode: this.get('controller.model.callingCode')
      };
    },
    _getDataContext: function _getDataContext(credentialData) {
      var callingCode = credentialData.callingCode;
      var callingOrigin = "UNKNOWN";
      switch (callingCode) {
        case "0004":
          callingOrigin = "DESKTOP";
          break;
        case "9228":
          callingOrigin = "MOBI";
          break;
        case "9241":
        case "9251":
        case "9240":
        case "9250":
          callingOrigin = "APP";
          break;
      }
      return {
        callingCode: callingCode,
        callingOrigin: callingOrigin
      };
    },
    actions: {
      sendAuthRequest: function sendAuthRequest() {
        var _this = this;
        var sessionId = (0, _emberUuid.v4)();
        this.set('session.data.sessionId', sessionId);
        this.set('controller.showErrorUsername', true);
        this.set('controller.showErrorPassword', true);
        this.set('controller.showErrorCompany', true);
        this.set('controller._showErrorTypo', true);
        this.set('controller._showErrorFinancialCenter', true);
        this.set('controller._showErrorAccounts', true);
        this.set('controller._showErrorCreditAccounts', true);
        this.set('controller._showErrorPhones', true);
        this.set('controller._showErrorReturnUrl', true);
        this.set('controller._showErrorCallingCode', true);
        this.set('controller.errorCode', '');
        this.get('controller.model').validate().then(function (_ref) {
          var validations = _ref.validations;
          if (validations.get('isValid')) {
            _this.get('controller').resetData();
            var credentialData = _this._getCredentialsData();
            return _this.get('session').authenticate('authenticator:ti', credentialData).then(function () {
              _this.get('session').set('data.context', _this._getDataContext(credentialData));
              _this.transitionToExternal('host-base-route');
            }).catch(function (response) {
              var code = response && response.code ? response.code : 'UNKNOWN';
              _this.set('controller.errorCode', code);
              _this.set('controller.model.password', '');
              var errorMessageKey = 'authentication.error.' + code;
              if (!_this.get('intl').exists(errorMessageKey)) {
                errorMessageKey = 'authentication.error.UNKNOWN';
              }
              _this.showErrorAndLog(errorMessageKey, 'An error appear during authentication', response);
            });
          } else {
            _this.showError('authentication.login.message-info.error.invalid-form');
          }
        });
      }
    }
  });
});