define("lbp-cust-web-application/components/a11y/a11y-action-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tiLogger: Ember.inject.service('logger-service'),
    tagName: '',
    // Remove div tag
    iconClass: undefined,
    // Name of the icon css class
    label: '',
    // Alt text used
    title: '',
    srText: Ember.computed.oneWay('label', 'title', function () {
      // Specific text for screen readers
      return this.get('title') ? this.get('title') : this.get('label');
    }),
    unFocus: true,
    // Manage the unFocus after action execution
    enabled: true,
    action: function action() {
      this.get('tiLogger').debug('No Custom action defined');
    },
    _unfocus: function _unfocus() {
      if (this.get('unfocus')) {
        document.activeElement.blur();
      }
    },
    actions: {
      action: function action() {
        this.get('action')();
        // Remove focus
        this._unfocus();
      },
      nothing: function nothing() {
        // Nothing to do
      }
    }
  });
});