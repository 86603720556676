define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/composer/conversation-composer-create", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/composer/conversation-composer-create", "lbp-cust-web-application/mixins/focus", "ember-uuid"], function (_exports, _conversationComposerCreate, _focus, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'components/ticust-web-module-interactions/conversation/composer/conversation-composer-create',
    // eslint-disable-line
    initialize: initialize
  };
  _conversationComposerCreate.default.reopen(_focus.default, {
    // Do not show the modal when we open the component
    backToIntl: undefined,
    sendButtonDisabled: false,
    showModal: false,
    _showConfirmDelete: false,
    accessibilityMessageLabel: 'message-label',
    _attachmentCount: Ember.computed('_attachments.[]', function () {
      return this.get('_attachments').length;
    }),
    onMessageSent: function onMessageSent() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalDeleteId', 'popup-delete-conv-' + _emberUuid.default.v4());
      this.set('modalDeleteTitleId', 'popup-delete-conv-' + _emberUuid.default.v4());
    },
    actions: {
      sendClicked: function sendClicked() {
        this.get('tagService').sendClickTag({
          name: 'contacter::messagerie::reception_bouton_envoyer',
          type: 'navigation'
        });
        this._autoSavingChain(this._super.bind(this));
        this.get("onMessageSent")();
      },
      closeModal: function closeModal() {
        this.set('showModal', false);
        // Set focus on editor as LBP requested
        this.focus(document.querySelector('.message-editor textarea'));
        this.set('transition', null);
      },
      cancelClicked: function cancelClicked(param) {
        if (this.get('cancelButtonDisabled') || param) {
          // Disable the double cancel confirmation
          this.onComposerReset();
          // Close the modal
          this.set('showModal', false);
          this._super();
        } else {
          this.set('showModal', true);
          this.set('transition', null);
        }
      },
      saveClicked: function saveClicked() {
        // Disable the double cancel confirmation
        this.onComposerReset();
        // Close the modal
        this.set('showModal', false);
        this._super();
      },
      deleteConversationClicked: function deleteConversationClicked() {
        var _this = this;
        this._autoSavingChain(function () {
          _this.deleteConversation();
        });
      },
      deleteConversationPopup: function deleteConversationPopup() {
        this.toggleProperty('_showConfirmDelete');
      },
      handleBtnKeyUp: function handleBtnKeyUp(event) {
        // Open pop-up on enter
        if (event.which == '13' || event.which == '32') {
          this.$('#conversation-composer-uploader').click();
        }
      }
    }
  });
});