define("lbp-cust-web-application/services/image-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COMPRESS_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif'];
  var ALL_IN_JPG = 'cust.module.upload.image.all-in-jpg';
  var QUALITY_LEVEL = 'cust.module.upload.image.quality';
  var MAX_HEIGHT = 'cust.module.upload.image.max-height';
  var MAX_WIDTH = 'cust.module.upload.image.max-width';
  var _default = _exports.default = Ember.Service.extend({
    // Services
    apiSettings: Ember.inject.service(),
    logger: Ember.inject.service('logger-service'),
    // Functions
    compressImage: function compressImage(file) {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (COMPRESS_TYPES.includes(file.type)) {
          _this.get('logger').debug('Compress file in local before sending: ' + file.name);
          var reader = new FileReader();
          reader.readAsArrayBuffer(file);
          var self = _this;
          reader.onload = function (e) {
            self._compress(file, e.target.result).then(resolve).catch(reject);
          };
        } else {
          resolve(file);
        }
      });
    },
    _compress: function _compress(originalFile, bytes) {
      var _this2 = this;
      return this._getSettings().then(function (settings) {
        _this2.get('logger').debug('Compress file with settings: ', settings);
        return new Ember.RSVP.Promise(function (resolve) {
          var allInJpg = settings[ALL_IN_JPG];
          var tempImg = new Image();
          var blob = new Blob([bytes], {
            type: originalFile.type
          });
          tempImg.src = window.URL.createObjectURL(blob);
          var self = _this2;
          tempImg.onload = function () {
            var canvas = document.createElement('canvas');
            var sizeRatio = 1;

            // Compute size
            var maxWidth = settings[MAX_WIDTH];
            var maxHeight = settings[MAX_HEIGHT];
            if (tempImg.naturalHeight > tempImg.naturalWidth) {
              if (tempImg.naturalHeight > maxHeight) {
                sizeRatio = maxHeight / tempImg.naturalHeight;
              }
            } else if (tempImg.naturalWidth > maxWidth) {
              sizeRatio = maxWidth / tempImg.naturalWidth;
            }
            canvas.width = tempImg.naturalWidth * sizeRatio;
            canvas.height = tempImg.naturalHeight * sizeRatio;

            // Compute type
            var compressedType = allInJpg ? 'image/jpeg' : originalFile.type;

            // Draw image
            var canvasContext = canvas.getContext('2d');
            if (allInJpg) {
              canvasContext.fillStyle = '#FFFFFF';
              canvasContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            canvasContext.drawImage(tempImg, 0, 0, canvas.width, canvas.height);
            var compressedfile = canvas.toDataURL(compressedType, settings[QUALITY_LEVEL]);

            // Process new image bytes
            var raw = window.atob(compressedfile.split(';base64,')[1]);
            var uInt8Array = self._toArrayBuffer(raw);
            var compressedFilename = allInJpg ? self._getFilename(originalFile.name) + '.jpg' : originalFile.name;

            // Create blob
            var result = new Blob([uInt8Array], {
              type: compressedType
            });
            result.name = compressedFilename;
            result.lastModified = originalFile.lastModified;
            result.lastModifiedDate = originalFile.lastModifiedDate;
            resolve(result);
          };
        });
      });
    },
    _getFilename: function _getFilename(pathfilename) {
      // eslint-disable-next-line no-useless-escape
      var filenameextension = pathfilename.replace(/^.*[\\\/]/, '');
      var filename = filenameextension.substring(0, filenameextension.lastIndexOf('.'));
      return filename;
    },
    _toArrayBuffer: function _toArrayBuffer(raw) {
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return uInt8Array;
    },
    _getSettings: function _getSettings() {
      var _this3 = this;
      if (!this.get('_settings')) {
        return this.get('apiSettings').getSettingByKeys('organization', null, QUALITY_LEVEL, MAX_WIDTH, MAX_HEIGHT, ALL_IN_JPG).then(function (response) {
          var result = {};
          result[QUALITY_LEVEL] = parseFloat(response[QUALITY_LEVEL].get('settingValue'));
          result[MAX_WIDTH] = parseInt(response[MAX_WIDTH].get('settingValue'));
          result[MAX_HEIGHT] = parseInt(response[MAX_HEIGHT].get('settingValue'));
          result[ALL_IN_JPG] = JSON.parse(response[ALL_IN_JPG].get('settingValue'));
          _this3.set('_settings', result);
          return result;
        });
      }
      return Ember.RSVP.resolve(this.get('_settings'));
    }
  });
});