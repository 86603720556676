define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation/composer/conversation-modal-leave", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    backToIntl: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalId', 'popup-component-categorize-' + _emberUuid.default.v4());
      this.set('modalTitleId', 'popup-component-categorize-' + _emberUuid.default.v4());
    },
    keyDown: function keyDown(event) {
      // Close modal on escape
      if (event.keyCode === 27) {
        this.send('closeModal');
      }
    },
    // Actions
    cancelClicked: function cancelClicked() {
      throw new Error("Missing 'cancelClicked' handler");
    },
    closeModal: function closeModal() {
      throw new Error("Missing 'closeModal' handler");
    },
    saveClicked: function saveClicked() {
      throw new Error("Missing 'saveClicked' handler");
    },
    actions: {
      cancelClicked: function cancelClicked() {
        this.get('cancelClicked')();
      },
      closeModal: function closeModal() {
        this.get('closeModal')();
      },
      saveClicked: function saveClicked() {
        this.get('saveClicked')();
      }
    }
  });
});