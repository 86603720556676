define("lbp-cust-web-application/initializers/components/ti-web-shared/documents/document-component", ["exports", "ti-web-shared/components/ti-web-shared/documents/document-component", "ember-uuid"], function (_exports, _documentComponent, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/components/ti-web-shared/documents/document-component',
    // eslint-disable-line
    initialize: initialize
  };
  var projectPartDocumentStatuses = {
    OK: {
      labelKey: 'projects.project.documents.status.OK',
      class: 'project-document-info-status-OK'
    },
    TO_ACKNOWLEDGE: {
      labelKey: 'projects.project.documents.status.TO_ACKNOWLEDGE',
      class: 'project-document-info-status-ACK'
    },
    TO_UPLOAD: {
      labelKeys: {
        ACTOR: 'projects.project.documents.status.TO_UPLOAD_LBP',
        INTERLOCUTOR: 'projects.project.documents.status.TO_UPLOAD'
      },
      class: 'project-document-info-status-UP'
    },
    UPLOADED: {
      labelKey: 'projects.project.documents.status.UPLOADED',
      class: 'project-document-info-status-OK'
    },
    KO: {
      labelKey: 'projects.project.documents.status.KO',
      class: 'project-document-info-status-KO'
    },
    default: {
      labelKey: 'projects.project.documents.status.KO',
      class: 'project-document-info-status-KO'
    }
  };
  _documentComponent.default.reopen({
    showAddDocument: false,
    // Services
    intl: Ember.inject.service(),
    showModalDeletion: false,
    isUploading: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('uploadId', 'file-upload-' + _emberUuid.default.v4());
      this.set('modalDeletionId', 'popup-project-delete-file-' + _emberUuid.default.v4());
      this.set('modalDeletionTitleId', 'popup-project-delete-file-' + _emberUuid.default.v4());
      this.set('modalUploadId', 'popup-project-upload-file-' + _emberUuid.default.v4());
      this.set('modalUploadTitleId', 'popup-project-upload-file-' + _emberUuid.default.v4());
      if (this.get('settings') && this.get('settings')['prj.module.projects.documents.maxFiles']) {
        this.set('maxFiles', this.get('settings')['prj.module.projects.documents.maxFiles'].get('settingValue'));
      }
    },
    _isUnread: Ember.computed('document.clientReaders.[]', 'document.advisorReaders.[]', 'reader.id', 'reader.type', function () {
      var _this = this;
      var searchIn = this.get('reader.type') === 'CLIENT' ? 'clientReaders' : 'advisorReaders';
      return !this.get('document').get(searchIn).some(function (r) {
        return r === _this.get('reader.id');
      });
    }),
    // Get the right label and style matching the status
    documentStatus: Ember.computed('intl.locale', 'document.{status,providerType}', function () {
      // OK, KO, TO_UPLOAD, TO_ACKNOWLEDGE
      var status = this.get('document.status');
      var projectPartDocumentStatus = projectPartDocumentStatuses[status] || projectPartDocumentStatuses.default;
      var providerType = this.get('document.providerType');
      return {
        label: this.get('intl').t(projectPartDocumentStatus.labelKey || projectPartDocumentStatus.labelKeys[providerType]),
        class: projectPartDocumentStatus.class
      };
    }),
    rejectReason: Ember.computed('document.rejectReason', 'documentRejectReasons.[]', function () {
      var _this2 = this;
      if (this.get('documentRejectReasons') && this.get('document.rejectReason')) {
        return this.get('documentRejectReasons').find(function (r) {
          return r.id === _this2.get('document.rejectReason');
        });
      }
    }),
    statusText: Ember.computed.alias('documentStatus.label'),
    statusClass: Ember.computed.alias('documentStatus.class'),
    hasAllFilesInTrash: Ember.computed('projectPartDocumentFiles.@each.status', function () {
      return !this.get('projectPartDocumentFiles') || this.get('projectPartDocumentFiles').isEvery('status', 'KO');
    }),
    isCurrentInterlocutorDocument: Ember.computed('document.{creatorType,creatorId}', 'currentInterlocutor', function () {
      return this.get('document.creatorType') === 'INTERLOCUTOR' && this.get('document.creatorId') === this.get('currentInterlocutor.id');
    }),
    // Rule to decide if file can be added to this document
    filesAsArray: Ember.computed('projectPartDocumentFiles', function () {
      return this.get('projectPartDocumentFiles') ? this.get('projectPartDocumentFiles').toArray() : [];
    }),
    canAddFiles: Ember.computed('identity.{hasRoleProjectsCopyFileFromAnotherProject,hasRoleProjectsUploadFileFromLocal}', 'project.isReadOnly', 'projectPart.isReadOnly', 'document.{isInterlocutorProvider,hasStatusToAddFile}', 'filesAsArray.[]', function () {
      // Check Roles
      if (!this.get('identity.hasRoleProjectsCopyFileFromAnotherProject') && !this.get('identity.hasRoleProjectsUploadFileFromLocal')) {
        return false;
      }
      return !this.get('project.isReadOnly') && !this.get('projectPart.isReadOnly') && this.get('document.isInterlocutorProvider') && this.get('document.hasStatusToAddFile') && this.get('filesAsArray').length < this.get('maxFiles');
    }),
    canDelete: Ember.computed('project.isReadOnly', 'projectPart.isReadOnly', 'document.{isStatusNotOK,isFreeDocument}', 'isCurrentInterlocutorDocument', 'hasAllFilesInTrash', function () {
      return !this.get('project.isReadOnly') && !this.get('projectPart.isReadOnly') && this.get('document.isFreeDocument') && this.get('document.isStatusNotOK') && this.get('isCurrentInterlocutorDocument') && this.get('hasAllFilesInTrash');
    }),
    actions: {
      delete: function _delete() {
        this.set('showModalDeletion', true);
      },
      deleteAction: function deleteAction() {
        this.get('deleteDocument')(this.get('document'), this.get('projectPartDocumentFiles') ? this.get('projectPartDocumentFiles') : []);
        this.set('showModalDeletion', false);
      },
      uploadAttachment: function uploadAttachment(attachment) {
        this._super(attachment);
        this.set('isUploading', false);
      }
    }
  });
});