define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/sources/source", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/sources/source", "tag-commander-cli/mixins/tagged-page"], function (_exports, _source, _taggedPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/sources/source',
    // eslint-disable-line
    initialize: initialize
  };
  _source.default.reopen(_taggedPage.default, {
    // Services
    apiSettings: Ember.inject.service(),
    paginationHelper: Ember.inject.service('pagination-helper'),
    lbpService: Ember.inject.service('lbp-service'),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.run.scheduleOnce('afterRender', this, this.get('_focusPageTitleWhenBackButtonClicked'));
      Ember.run.scheduleOnce('afterRender', this, this.get('_focusNewMessageWhenBackButtonClicked'));
    },
    _focusNewMessageWhenBackButtonClicked: function _focusNewMessageWhenBackButtonClicked() {
      if (this.get('lbpService.inbentaCancelButtonClicked')) {
        var button = document.querySelector('.conversation-result-list__header');
        if (button) {
          button.focus();
        }
      }
      this.set('lbpService.inbentaCancelButtonClicked', false);
    },
    _focusPageTitleWhenBackButtonClicked: function _focusPageTitleWhenBackButtonClicked() {
      if (this.get('lbpService.returnToSourcePageClick') || this.get('lbpService.settingCancelButtonClicked')) {
        var mainTitle = document.getElementById('page-title');
        if (mainTitle) {
          mainTitle.setAttribute('tabindex', '-1');
          mainTitle.removeAttribute('aria-live');
          mainTitle.focus();
        }
      }
      this.set('lbpService.returnToSourcePageClick', false);
      this.set('lbpService.settingCancelButtonClicked', false);
    },
    requestData: function requestData(params) {
      var sourceName = this._getSourceName(params);
      return this.get('conversationQuery').getSourceRequestData(sourceName);
    },
    displayButtons: function displayButtons(params) {
      var sourceName = this._getSourceName(params);
      switch (sourceName) {
        case 'conversationMine':
          return {
            checkBox: true,
            archive: true,
            unarchive: false,
            markAs: true,
            labels: true,
            delete: true,
            deleteDraft: false,
            removeFromTrash: false,
            restore: false,
            search: this._isSearch(params)
          };
        case 'conversationSent':
          return {
            checkBox: true,
            archive: true,
            unarchive: false,
            markAs: true,
            labels: true,
            delete: true,
            deleteDraft: false,
            removeFromTrash: false,
            restore: false,
            search: this._isSearch(params)
          };
        case 'conversationDraft':
          return {
            checkBox: true,
            archive: false,
            unarchive: false,
            markAs: false,
            labels: false,
            delete: false,
            deleteDraft: true,
            removeFromTrash: false,
            restore: false,
            search: this._isSearch(params)
          };
        case 'all':
        case 'search':
          return {
            checkBox: false,
            archive: false,
            unarchive: false,
            markAs: false,
            labels: false,
            delete: false,
            deleteDraft: false,
            removeFromTrash: false,
            restore: false,
            search: this._isSearch(params)
          };
        case 'conversationTrashed':
          return {
            checkBox: true,
            archive: false,
            unarchive: false,
            markAs: false,
            labels: false,
            delete: false,
            deleteDraft: false,
            removeFromTrash: true,
            restore: true,
            search: this._isSearch(params)
          };
      }
      return {};
    },
    _getSourceName: function _getSourceName(params) {
      if (this._isSearch(params) && params.searchPerimeter) {
        return params.searchPerimeter;
      }
      return params.source;
    },
    _isSearch: function _isSearch(params) {
      return params.source === 'search';
    },
    _updateParams: function _updateParams(params) {
      // set page number for all page
      params.pagesToGet = this.get('paginationHelper').getPageNumber();
      // page "search" parameters
      var searchParams = this.get('paginationHelper').getSearchValues();
      if (searchParams) {
        params.currentPage = searchParams.currentPage;
        params.searchPerimeter = searchParams.searchPerimeter;
        params.searchText = searchParams.searchText;
      }
      return params;
    },
    model: function model(params) {
      var _this = this;
      if (params.source) {
        this.get('paginationHelper').setSourceName(params.source);
      }
      params = this._updateParams(params);
      return this._super(params).then(function (model) {
        model.pageSource = params.source;
        // Manage inbenta
        model.inbentaEnabled = _this.get('apiSettings').getSettingByKey('organization', null, 'cust.module.interactions.inbenta.enabled');
        return Ember.RSVP.hash(model);
      });
    },
    afterModel: function afterModel(model) {
      var pageTagData = undefined;
      if (model.pageSource === 'conversationMine') {
        pageTagData = {
          name: 'contacter::messagerie::reception'
        };
      } else if (model.pageSource === 'conversationSent') {
        pageTagData = {
          name: 'contacter::messagerie::envoyees'
        };
      } else if (model.pageSource === 'search') {
        pageTagData = {
          name: 'contacter::messagerie::recherche'
        };
      }
      if (model.inbentaEnabled) {
        model.inbentaEnabled = model.inbentaEnabled.get('settingValue');
      }
      this.set('pageTagData', pageTagData);
    }
  });
});