define("lbp-cust-web-application/translations/de-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "common-projects": {
      "closed": "Closed Project",
      "components": {
        "annex-area-button": {
          "openConversation": {
            "buttonLabel": "Go to conversation",
            "title": "Conversation"
          }
        },
        "audit-component": {
          "results": {
            "error": "An error has occurred while loading audit lines",
            "loading": "Loading, please wait..."
          },
          "table": {
            "no-result": "No results to show"
          }
        },
        "buttons": {
          "close": "Close"
        },
        "file-uploader-label": "Upload",
        "file-uploading": "Upload in progress ...",
        "header": "History",
        "project-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Loading result(s)...",
          "no-results": "No entity is matching this term",
          "required": "An entity must be selected"
        },
        "table": {
          "action": "Action",
          "date": "Date",
          "newValue": "New value",
          "no-result": "No result",
          "oldValue": "Old value",
          "user": "User"
        }
      },
      "document": {
        "audit": {
          "action": {
            "fileInvalid": "Marked as invalid",
            "fileValid": "Marked as valid",
            "init": "Initialization",
            "status": "Status changed",
            "uploaded": "Deposit"
          },
          "button": {
            "close": "Close"
          },
          "column": {
            "action": "Action",
            "actor": "Actor",
            "date": "Date",
            "detail": "Detail",
            "type": "Type"
          },
          "userType": {
            "ADVISER": "Adviser",
            "CLIENT": "Client"
          }
        },
        "button": {
          "add": "Add a file",
          "audit": "See history about this document",
          "cancel": "Cancel",
          "copy": "Copy-paste a file from an existing project",
          "create": {
            "copy": "Copy a file",
            "new": "Blank document",
            "upload": "Upload a file"
          },
          "download": "Download all files of the document",
          "edit": {
            "show": "Edit"
          },
          "hideButtons": "Hide action buttons for the document and its files",
          "info": {
            "hide": "Hide complementary information about this document",
            "show": "See more information about this document"
          },
          "markAsNotNeeded": "Already present",
          "markAsNotValid": "Mark the document as Invalid",
          "mergeFiles": "Merge files",
          "save": "Validate",
          "showButtons": "Show action buttons for the document and its files",
          "undoAsNotNeeded": "Undo already present",
          "upload": "Upload a new file from device"
        },
        "create": {
          "new": {
            "title": "New Document"
          },
          "title": "Add a new document"
        },
        "fields": {
          "description": "Description",
          "nature": "Nature",
          "providerType": "Provider",
          "signatureRequired": "Signature required",
          "title": "Name"
        },
        "message-info": {
          "error": {
            "duplicate-title": "The document title already exists.",
            "invalid-form": "The form is invalid, correct it and try again.",
            "markAsNotNeeded": "An error occurred while updating the document as Already Present, please try again later.",
            "markAsNotValid": "An error occurred while updating the file as Invalid, please try again later.",
            "markAsValid": "An error occurred while updating the file as Valid, please try again later.",
            "save": "An error occurred while saving the document, please try again later.",
            "undoNotNeeded": "An error occurred while reverting Already Present status, please try again later."
          },
          "info": {
            "markAsNotNeeded": "The document has been marked as Already Present.",
            "markAsNotValid": "The document has been marked as Invalid.",
            "markAsValid": "The document has been marked as Valid.",
            "onlyAdviserAllowed": "* Only the document with Adviser provider can be set as signature required",
            "save": "The document has been saved successfully.",
            "undoNotNeeded": "The document Already Present status reverted."
          }
        },
        "nature": {
          "ADVERTISEMENT": "Advertisement",
          "BANK_STATEMENT": "Bank statement",
          "BIRTH_CERTIFICATE": "Birth certificate",
          "COMPROMISE_SALE": "Compromise sale",
          "IBAN": "IBAN",
          "IDENTIFICATION": "Proof of identity",
          "INSURANCE": "Insurance",
          "LOAN_OFFER": "Loan offer",
          "PAYSLIP": "Payslip",
          "POLICE_RECORD": "Police record",
          "TAX_NOTICE": "Tax notice",
          "UNKNOWN": "Unknown",
          "label": "Nature:"
        },
        "providerType": {
          "ACTOR": "Adviser",
          "INTERLOCUTOR": "Customer",
          "UNKNOWN": "Unknown",
          "label": "Provider:"
        },
        "refusedReason": {
          "BLURRY": "The document is blurry.",
          "ERROR": "The document is off topic.",
          "ILLEGIBLE": "The document is illegible.",
          "label": "Reason to reject:"
        },
        "status": {
          "KO": "Invalid",
          "NOT_NEEDED": "Already present",
          "OK": "Valid",
          "TO_ACKNOWLEDGE": "To be validated",
          "TO_UPLOAD": "New",
          "UPLOADED": "Uploaded"
        },
        "statusMessage": {
          "KO": "This document has been flagged as Invalid by advisor. Please upload a new file.",
          "NOT_NEEDED": "This document is already present.",
          "TO_ACKNOWLEDGE": "This document is awaiting validation by an advisor.",
          "TO_UPLOAD": "This document has been created. Please upload the corresponding file."
        },
        "type": {
          "FREE": "Free",
          "STRUCTURED": "Structured",
          "UNKNOWN": "Unknown",
          "label": "Type of document:"
        }
      },
      "file": {
        "button": {
          "delete": "Delete this file",
          "download": "Download this file",
          "markAsNotValid": "Mark the file as Invalid",
          "markAsValid": "Mark the file as Valid",
          "preview": "Preview this file",
          "sign": "Sign this file"
        },
        "help": {
          "signature-required": "The file needs to be signed",
          "signed": "The file is already signed"
        },
        "message-info": {
          "error": {
            "delete": "An error occurred while deleting the file, please try again later.",
            "get-e-signiture-url": "An error occurred while getting the URL to sign the file, please try again later.",
            "mark-as-not-valid": "An error occurred while updating the file as Invalid, please try again later.",
            "mark-as-valid": "An error occurred while updating the file as Valid, please try again later."
          },
          "info": {
            "delete": "The file has been deleted.",
            "mark-as-not-valid": "The file has been marked as Invalid.",
            "mark-as-valid": "The file has been marked as Valid.",
            "pop-up": {
              "delete": "Do you want to delete this file?"
            }
          }
        },
        "status": {
          "KO": "Invalid",
          "OK": "Valid",
          "TO_SIGN": "To sign",
          "UPLOADED": "Uploaded"
        }
      },
      "file-upload": {
        "error": {
          "contentType": "This file type is not allowed."
        },
        "info": {
          "uploadSucess": "Your file has been uploaded."
        }
      },
      "folder": "Folder",
      "list": {
        "tabs": {
          "personal": "Personal",
          "shared": "Shared"
        },
        "title": "{interlocutorName}'s projects"
      },
      "modal-copy": {
        "buttons": {
          "cancel": "Cancel",
          "copy": "Copy"
        },
        "document": "Choose the document to copy to",
        "file": "Choose the file to copy",
        "label": {
          "search": "Search"
        },
        "message-info": {
          "error": {
            "post-400": "An error occurred while copying the file, please check the document status or that you don't have file with such name.",
            "unknown": "An error occurred while copying the file, please try again later."
          },
          "info": {
            "save": "{nb, plural, =1 {Copied file has been saved.} other {Copied files have been saved.}}",
            "saveAttachment": "Copied attachment has been saved.",
            "uploading": "File is uploading..."
          }
        }
      },
      "modal-merge": {
        "buttons": {
          "cancel": "Abbrechen",
          "confirm": "Absenden",
          "move-down": "Nach unten",
          "move-up": "Nach oben",
          "preview": "Vorschau"
        },
        "filename": "Name der Zieldatei: ",
        "header": "Dateien zusammenführen",
        "list": "Liste der zusammenzuführenden Dateien: ",
        "message-info": {
          "error": {
            "generic": "Beim Zusammenführen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."
          },
          "info": {
            "info": "Einige Dateien werden möglicherweise nicht zusammengeführt.",
            "loading": "Die Datei wird zusammengeführt, bitte warten...",
            "saved": "Datei wurde zusammengeführt und gespeichert."
          },
          "invalid-input": {
            "filename-duplicate": "Dateiname existiert bereits im Dokument.",
            "filename-empty": "Der Dateiname darf nicht leer sein.",
            "filename-long": "Dateiname ist zu lang.",
            "list-empty": "Es sollten mindestens zwei Dateien ausgewählt werden.",
            "list-too-large": "Das Gewicht der Dateien ist zu groß."
          }
        }
      },
      "modal-share": {
        "buttons": {
          "cancel": "Cancel",
          "save": "Save"
        },
        "message-info": {
          "error": {
            "loading": "An error occurred while loading the trusted interlocutors, please try again later.",
            "save": "An error occurred while sharing the project, please try again later."
          },
          "info": {
            "empty-result": "You don't have any trusted interlocutors.",
            "save": "Sharing information has been saved."
          }
        },
        "placeholder": "Rights",
        "title": "Project rights"
      },
      "modal-upload": {
        "message-info": {
          "error": {
            "failed-due-bad-request": "An error occurred while uploading the file. please check the size or type of the file.",
            "failed-to-upload": "An error occurred while uploading the file. please try again later.",
            "post-400": "An error occurred while uploading the file, please check the document status or that you don't have file with such name.",
            "unknown": "An error occurred while uploading the file, please try again later.",
            "wrong-format": "The format of a file for which a signature is required can only be a PDF"
          },
          "info": {
            "file-uploading": "Upload in progress ...",
            "save": "Your file has been uploaded."
          }
        }
      },
      "page-error-message": "An unknown error occurred. Please try again later or contact your service support",
      "preview-component": {
        "buttons": {
          "close": "Close"
        },
        "error": "An error occurred while downloading the picture, please try again later.",
        "loading": "Loading; please wait...",
        "title": {
          "filedBy": "Filed by:",
          "filedOn": "Filed on:"
        }
      },
      "project": {
        "assignation": "This project is handled by: ",
        "audit": {
          "action": {
            "CREATE": "Project creation",
            "SHARED_INTERLOCUTOR_ADD": "Add interlocutor {interlocutorName} as {newProfileName}",
            "SHARED_INTERLOCUTOR_REMOVE": "Remove interlocutor {interlocutorName}",
            "SHARED_INTERLOCUTOR_UPDATE": "Update interlocutor {interlocutorName} from {previousProfileName} to {newProfileName}",
            "STATUS_CLOSED": "Project closed",
            "STATUS_REOPENED": "Project reopened",
            "UPDATE": "Project updated",
            "auditToAdviser": "Notification send to adviser {receiverName}",
            "auditToCustomer": "Notification send to customer {receiverName}"
          },
          "button": {
            "close": "Close"
          },
          "column": {
            "action": "Action",
            "date": "Date",
            "detail": "Detail",
            "sender": "Author"
          }
        },
        "button": {
          "audit": "See history about this project",
          "back": "Return to the list of projects",
          "close": "Close the project",
          "download": "Download all files of the project",
          "edit": "Edit the project",
          "hideInformations": "Hide informations",
          "notify-to-adviser": "Notify my Adviser that my project has been updated",
          "notify-to-customer": "Notify my Customer that his project has been updated",
          "open": "Open the project",
          "openConversation": "Open the linked conversation",
          "reopen": "Reopen the project",
          "share": "Manage project publication",
          "showInformations": "Show more informations"
        },
        "closedDate": "Closed on",
        "createdBy": "By",
        "creationDate": "Created on",
        "finishDate": "Expected completion on",
        "lastUpdateDate": "Modified on {date} by {name}",
        "message-error": {
          "download": "An error occurred while downloading the project, please try again later."
        },
        "message-info": {
          "error": {
            "LIMITED_CLICK_FOR_ADVISER": "You have already notified your customer less than {limit, plural, =1 {# hour} other {# hours}} ago.",
            "LIMITED_CLICK_FOR_CUSTOMER": "You have already notified your adviser less than {limit, plural, =1 {# hour} other {# hours}} ago.",
            "close": "An error occurred while updating the project as closed, please try again later.",
            "reopen": "An error occurred while updating the project as in-progress, please try again later.",
            "unknown": "An error occurred while sending notification, please try again later."
          },
          "info": {
            "close": "The project has been closed.",
            "notify-update": "The notification has been sent.",
            "pop-up": {
              "close": "Do you want to close this project?",
              "reopen": "Do you want to reopen this project?"
            },
            "reopen": "The project has been reopened."
          }
        },
        "new": "New",
        "reopenDate": "Reopened on",
        "shared": {
          "byMe": "This project is currently published to {to} with the profile: ",
          "not": "This project is currently not published to other clients.",
          "right": {
            "FULL_ACCESS": "Full access",
            "NO_ACCESS": "No access",
            "READ_ONLY": "Read only"
          },
          "toMe": "This project is currently published by {from} with the profile: "
        },
        "statistics": {
          "messages": {
            "KO": "{nb, plural, =1 {# document} other {# documents}} rejected",
            "OK": "{nb, plural, =1 {# document} other {# documents}} approved",
            "TO_ACKNOWLEDGE": "{nb, plural, =1 {# document} other {# documents}} to acknowledge",
            "UPLOADED": "{nb, plural, =1 {# document} other {# documents}} uploaded"
          }
        },
        "type": {
          "FREE": "Free",
          "STRUCTURED": "Structured"
        }
      },
      "project-card": {
        "invalid-documents": "{numDocs, plural, =1 {# document was} other {# documents were}} flagged as invalid.",
        "project-closed-alt": "Project closed",
        "project-shared-alt": "Project shared"
      },
      "project-element-list": {
        "sort": {
          "creationDate": {
            "asc": "The oldest",
            "desc": "The most recent"
          },
          "lastUpdateDate": {
            "asc": "The oldest update",
            "desc": "The most recent update"
          },
          "sortBy": "Sort by:"
        },
        "status-filters": {
          "all": "All",
          "all-help": "All projects",
          "closed": "Closed",
          "closed-help": "Closed projects",
          "opened": "Open",
          "opened-help": "Open projects"
        }
      },
      "project-group-title": {
        "button": {
          "close": "Close",
          "open": "Open"
        }
      },
      "project-part": {
        "close": "Close this part",
        "open": "Open this part",
        "title": "Dokumente"
      },
      "project-part-validator": {
        "error": {
          "duplicate-title": "The template folder name already exists for this project."
        }
      },
      "project-template-duplicate-validator": {
        "error": {
          "duplicate-title": "The project template label already exists."
        }
      },
      "project-template-part-document-validator": {
        "error": {
          "duplicate-title": "The document template name already exists for this folder."
        }
      },
      "selectors": {
        "document-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-nature-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-part-document-file-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        },
        "project-part-document-selector": {
          "error": "An error occurred during the search",
          "loading": "Now loading...",
          "no-results": "No results to show"
        }
      },
      "side-menu": {
        "new-project": "New Project",
        "projects": "Projects"
      },
      "title": "Project"
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} is required.",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} is required.",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "file-empty": "File is not selected",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} is required.",
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "unsupported-file": "File type is not supported for signature required document",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} has the wrong length (should be {is} characters)"
    },
    "interactions": {
      "message-composer-attachments": {
        "multiple-upload": {
          "error": "{nbUploadsFailed, plural, one {# Anhang konnte nicht} other {# Anhänge konnten nicht}} hochgeladen werden.",
          "success": "{nbUploadsPassed, plural, one {Die Anhang wurde} other {# Anhänge wurden}} hochgeladen."
        }
      }
    },
    "projects": {
      "list": {
        "title": "Projects"
      },
      "project": {
        "interlocutor-new": {
          "help": "Ein neues Projekt für {interlocutor} erstellen",
          "title": "Neues Projekt für {interlocutor}"
        },
        "new": {
          "help": "Ein neues Projekt erstellen",
          "title": "Neues Projekt"
        }
      },
      "search": {
        "filter": {
          "label": {
            "assignedTo": "Zugewiesen:",
            "interlocutor": "Ansprechpartner:"
          }
        },
        "legend": "Search",
        "message-info": {
          "error": {
            "project-load": "Die Projektliste konnte nicht geladen werden"
          }
        }
      },
      "side-menu": {
        "closed-projects": "Geschlossen",
        "in-progress-projects": "In Bearbeitung",
        "interlocutor-menu-label": "Projekt von {name}",
        "my-projects": "Meine",
        "projects": "Projects"
      },
      "title": {
        "empty": "Projects",
        "mine": "Projects"
      }
    },
    "shared": {
      "accessibility": {
        "pagination": "Pagination"
      },
      "components": {
        "a11y": {
          "modals": {
            "alert-modal": {
              "closeButtonText": "Close",
              "closeButtonTitle": "Close the window"
            }
          }
        },
        "absence-table": {
          "absence-backend-error": "Error when retreiving absence date for an adviser",
          "granularity-dateformat-day": "DD/MM/YYYY",
          "granularity-dateformat-month": "MMMM YYYY",
          "granularity-dateformat-week": "Week {week} - {year}",
          "granularity-day": "day",
          "granularity-legend": "Display by",
          "granularity-month": "month",
          "granularity-week": "week",
          "grid-caption": "List of absences",
          "next-day-button-label": "Next day",
          "next-month-button-label": "Next month",
          "next-week-button-label": "Next week",
          "prev-day-button-label": "Previous day",
          "prev-month-button-label": "Previous month",
          "prev-week-button-label": "Previous week",
          "range-label": "From {start} to {end}",
          "range-label-backup": "Backup is {backup}",
          "today-button-label": "Today"
        },
        "charts": {
          "adviser-dashboard-chart": {
            "ACKNOWLEDGE": "Acknowledged conversations",
            "CLOSED": "Closed conversations",
            "CLOSED-AFTER-CRITICAL-DUE-DATE": "Abgeschlossene Gespräche nach dem kritischen Fälligkeitsdatum",
            "CLOSED-BEFORE-CRITICAL-DUE-DATE": "Abgeschlossene Gespräche vor dem kritischen Fälligkeitsdatum",
            "CLOSED-BEFORE-DUE-DATE": "Abgeschlossene Gespräche vor Fälligkeit",
            "OPEN": "Opened conversations",
            "REOPEN": "Reopened conversations",
            "nb-conversations": "Number of conversations"
          }
        },
        "date": {
          "cancel": "Cancel",
          "dow": {
            "abbr": {
              "Fr": "Friday",
              "Mo": "Monday",
              "Sa": "Saturday",
              "Su": "Sunday",
              "Th": "Thursday",
              "Tu": "Tuedsay",
              "We": "Wednesday"
            },
            "text": {
              "Fr": "Fr",
              "Mo": "Mo",
              "Sa": "Sa",
              "Su": "Su",
              "Th": "Th",
              "Tu": "Tu",
              "We": "We"
            }
          },
          "format": "DD/MM/YYYY",
          "formatWithTime": "DD/MM/YYYY HH:mm",
          "month": {
            "april": "April",
            "august": "August",
            "december": "December",
            "february": "February",
            "january": "January",
            "july": "July",
            "june": "June",
            "march": "March",
            "may": "May",
            "november": "November",
            "october": "October",
            "september": "September"
          },
          "month-year": "Month Year",
          "next-month": "Next month",
          "next-year": "Next year",
          "ok": "OK",
          "picker-button-alt": "Choose a date",
          "placeholderFormat": "DD/MM/YYYY",
          "placeholderFormatWithTime": "DD/MM/YYYY hh:mm",
          "previous-month": "Previous month",
          "previous-year": "Previous year"
        },
        "dropdown": {
          "default-button-title": "Actions"
        },
        "form-component": {
          "form-fields-required-label": "All fields marked with an * are required."
        },
        "form-group-component": {
          "missing-text": "This field is required."
        },
        "modals": {
          "audio-preview": {
            "loading": "Loading...",
            "modal-title": "Audio abspielen"
          },
          "authenticator-confirm-modal": {
            "cancel": "Trennen",
            "confirm": "Ja",
            "message": "Sie werden in wenigen Augenblicken abgemeldet.",
            "question": "Möchten Sie Ihre Sitzung verlängern?",
            "title": "Information"
          },
          "document-preview": {
            "error-message": "Beim Herunterladen des Dokuments ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.",
            "modal-title": "Dokumentenvorschau",
            "select-page": "Seite auswählen",
            "upload-by": "Hochgeladen von {actor} am {date}",
            "zoom-in": "Vergrößern",
            "zoom-out": "Auszoomen"
          },
          "image-preview": {
            "error-message": "An error occurred while downloading the picture, please try again later.",
            "loading-message": "Loading, please wait...",
            "modal-title": "File preview",
            "upload-by": "Uploaded by",
            "upload-date": "Uploaded on",
            "zoom-in": "Heranzoomen",
            "zoom-out": "Herauszoomen"
          },
          "modal-component": {
            "modal-cancel": "Cancel",
            "modal-confirm": "Confirm"
          },
          "title": {
            "closeButton": "Close this message"
          }
        },
        "multi-selectors": {
          "cc-multi-selector": {
            "error": "An error occurred during the search.",
            "error-external-on-secured": "Cannot add external email on secured email channel",
            "error-secured-on-external": "Cannot add secured email on secured email channel",
            "errors": {
              "no-account-found-external": "No account with external mail '{email}' has been found",
              "no-account-found-secured": "No account with secured mail '{email}' has been found"
            },
            "loading": "Loading result(s)...",
            "no-results": "No match",
            "type": {
              "actor": "Advisor: ",
              "entity": "Entity: ",
              "external": "Ext.",
              "interlocutor": "",
              "trustedInterlocutor": "Trusted interlocutor"
            }
          },
          "tags-multi-selector": {
            "error": "An error occurred during the search.",
            "loading": "Loading result(s)...",
            "no-results": "No match"
          }
        },
        "panels": {
          "showLess": "Show less",
          "showMore": "Show more"
        },
        "presets-selector": {
          "title": "Preset"
        },
        "rating": {
          "message-info": {
            "error": {
              "rate": "An error occurred while saving rate, please try again later."
            },
            "info": {
              "rate": "The rate has been successfully saved."
            }
          },
          "title": "Do you want to change your grade?",
          "title-new": "Are you satisfied by this answer?"
        },
        "result-list": {
          "results": {
            "error": "An error occurred while loading, please try again later.",
            "loading": "Laden...",
            "none": "No result available",
            "wait": "Please wait"
          }
        },
        "text-editor": {
          "actions-list": {
            "f-ai": {
              "choose-template": "Choose a reply template:",
              "ko-declaration": "Declare the templates as not satisfying:",
              "show-templates": "Show semantic analysis reply templates"
            },
            "f-aligncenter": "Align center",
            "f-alignleft": "Align left",
            "f-alignright": "Align right",
            "f-attachments": {
              "help": "Kopieer bijlagen"
            },
            "f-bold": "Bold",
            "f-color": {
              "help": "Farbe",
              "label": "Textfarbe ändern"
            },
            "f-font": {
              "help": "Schriftart",
              "label": "Schriftfamilie wählen"
            },
            "f-gallery": "Add image",
            "f-italic": "Italic",
            "f-link": {
              "help": "Add link",
              "ok": "OK",
              "url": "URL"
            },
            "f-list": "Switch from/to list",
            "f-signature": {
              "apply": "Apply signature",
              "choose": "Choose signature",
              "label": "Signature..."
            },
            "f-size": {
              "help": "Schriftgröße",
              "label": "Schriftgröße wählen"
            },
            "f-spellCheck": {
              "check-spelling": "Check spelling",
              "choose-language": "Choose language",
              "ignore": "Ignorieren",
              "ignore-all": "Alles ignorieren"
            },
            "f-underline": "Underline",
            "f-upload": {
              "help": "Add an attachment"
            }
          },
          "initial-attachments": {
            "copy-attachment": "Een bijlage kopiëren",
            "message-info": {
              "error": {
                "copy-attachment": "Er is een fout opgetreden tijdens het kopiëren van de bijlage, probeer het later nog eens."
              },
              "info": {
                "file-removed": "De bijlage is verwijderd.",
                "file-uploaded": "De bijlage is gekopieerd."
              }
            }
          },
          "inline-images": {
            "message-info": {
              "error": {
                "empty-file": "You can't upload empty files. Please try again with a valid file.",
                "preview": "An error occurred while downloading the picture, please try again later."
              }
            }
          },
          "message-composer-attachments": {
            "delete": "Delete this attachment",
            "download": "Download this attachment",
            "dropdown-button-title": "Actions",
            "message-info": {
              "error": {
                "download": "An error occurred while downloading the attachment, please try again later.",
                "empty-file": "You can't upload empty files. Please try again with a valid file.",
                "failed-to-upload": "Failed to upload the attachment. Please try again later",
                "file-name-already-used": "An attachment with the same name already exists.",
                "maxAttachmentReached": "You have reached the maximum number of attachments allowed.",
                "message-size-limit": "You have exceeded the max size of message attachments.",
                "not-allowed-content-type": "Die Art der Anlage ist nicht zulässig.",
                "preview": "An error occurred while downloading the picture, please try again later."
              },
              "info": {
                "file-uploaded": "The attachment has been uploaded",
                "file-uploading": "Upload in progress ..."
              }
            },
            "preview": "Preview this attachment"
          }
        }
      },
      "file-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "You can't upload empty files. Please try again with a valid file.",
          "NOT_ALLOWED_CONTENT_TYPE": "Der Typ des Anhangs ist nicht erlaubt.",
          "addFile": "The file has not been uploaded. Please try again later.",
          "contentType": "The type of attachment is not allowed.",
          "length": "The size of the attachment is greater than the maximum size allowed."
        }
      },
      "helpers": {
        "date-short-view": {
          "today": "Today, {hour}"
        }
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Adviser }  CLIENT {Interlocutor } other {}}{gender, select, MR {Mr }  MME {Ms } other {}}{lastname} {firstname}",
        "unknown-person": "Unknown person"
      },
      "inline-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "You can't upload empty files. Please try again with a valid file.",
          "length": "Die Größe des Inline-Bildes ist größer als die maximal zulässige Größe."
        }
      },
      "numeric-unit": {
        "billion": "G",
        "million": "M",
        "thousand": "K"
      },
      "pagination": "pagination",
      "skip-link": "Navigation (Skip menu)",
      "spellchecker": {
        "languages": {
          "de": "German",
          "en": "English",
          "fr": "French",
          "nl": "Dutch",
          "ru": "Russian"
        },
        "message-info": {
          "error": {
            "unknown": "An error occurred."
          }
        }
      },
      "theme": {
        "criticalDueDateDelay": {
          "error": {
            "message": "Dieses Feld muss größer sein als Late in"
          }
        }
      },
      "treeview": {
        "button": {
          "list.label": "Show the result list only",
          "search.label": "Search",
          "select.default.label": "Choisir",
          "tree.label": "Show the complete tree"
        },
        "message": {
          "noresult": "Search returned no result"
        }
      }
    },
    "side-panel": {
      "accessibility": {
        "buttons": {
          "close": {
            "title": "Close"
          }
        },
        "contrast": {
          "enhanced": "Enhanced",
          "standard": "Standard"
        },
        "disparities": "Disparities",
        "message-info": {
          "error": {
            "unknown": "An error occurred while changing the accessibility contrast, please try again later."
          }
        },
        "title": "Accessibility"
      },
      "burger": {
        "label": "Show / Hide the Menu"
      },
      "hide-menu": "Seitenmenü ausblenden",
      "logo": {
        "by": "by",
        "ti": "Trusted Interactions",
        "worldline": "Worldline"
      },
      "logo-alt": "WL Trusted Interactions",
      "module": {
        "title": "Module"
      },
      "settings": {
        "myprofile": "Settings",
        "name": "Settings",
        "preferences": "Einstellungen"
      },
      "show-menu": "Seitenmenü anzeigen",
      "toggle-title": "Opens/Closes the menu"
    },
    "site-map": {
      "modules": "Module",
      "title": "Seitenverzeichnis"
    }
  };
});