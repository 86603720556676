define("lbp-cust-web-application/components/common/pagination/lbp-pagination-component", ["exports", "lbp-cust-web-application/templates/components/common/pagination/lbp-pagination-component"], function (_exports, _lbpPaginationComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _exports.default = Ember.Component.extend({
    // Layout
    layout: _lbpPaginationComponent.default,
    // Text of button
    title: '',
    label: '',
    // Style
    setBottomDivider: false,
    // Display component
    isVisible: Ember.computed.not('_isLastPage'),
    // Number of loaded elements
    displayedItems: 0,
    // Total elements to load
    totalItems: 0,
    // Limit
    limit: false,
    // Determine if we display the number of data to load
    displayNumberToLoad: false,
    // Action inherited
    onClick: undefined,
    // Manage limit
    _manageLimit: Ember.computed('limit', function () {
      return this.get('limit') > 0;
    }),
    // Max number of elements
    _maxElements: Ember.computed('_manageLimit', 'limit', 'totalItems', function () {
      return this.get('_manageLimit') && this.get('limit') < this.get('totalItems') ? this.get('limit') : this.get('totalItems');
    }),
    // The number of data to load
    _numberToLoad: Ember.computed('_maxElements', 'displayedItems', function () {
      var diff = this.get('_maxElements') - this.get('displayedItems');
      return diff < 0 ? 0 : diff;
    }),
    // Last page to load
    _isLastPage: Ember.computed('totalItems', 'displayedItems', function () {
      return this.get('totalItems') <= this.get('displayedItems');
    }),
    // Limit reached
    _isLimitReached: Ember.computed('_manageLimit', 'limit', 'displayedItems', function () {
      return this.get('_manageLimit') && this.get('limit') <= this.get('displayedItems');
    }),
    // Actions
    actions: {
      _onClick: function _onClick() {
        if (!this.get('_isLastPage')) {
          var buttonClicked = this.get('onClick');
          if (buttonClicked) {
            buttonClicked();
          }
        }
      }
    }
  });
});