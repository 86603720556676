define("lbp-cust-web-application/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QQZckKZZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"ti-web-shared/spinner/page-spinner\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/loading.hbs"
    }
  });
});