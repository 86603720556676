define("lbp-cust-web-application/initializers/controllers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/abstractCreateConversation", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/abstractCreateConversation", "lbp-cust-web-application/mixins/self-service"], function (_exports, _abstractCreateConversation, _selfService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/controller/interlocutors/interlocutor/conversations/abstractCreateConversation',
    // eslint-disable-line
    initialize: initialize
  };
  _abstractCreateConversation.default.reopen(_selfService.default, {
    // Does not show the Self Service AI pop up
    _showSelfServiceModal: false,
    _selfServiceResponse: null,
    _selfServiceFoundTxt: '',
    selfServiceValidateAction: function selfServiceValidateAction() {},
    selfServiceCloseAction: function selfServiceCloseAction() {},
    // settings
    iaIsEnabled: Ember.computed.alias('settings.iaIsEnabled'),
    // Character limit for editor
    maxlength: 100000,
    fieldsForThemeErrorIndex: -1,
    fieldsForTheme: Ember.computed('conversation.theme.fields', function () {
      // Get additional fields related to the selected theme
      var fields = this.get('conversation.theme.fields');
      // Set selected options of additional fields
      var caracteristics = this.get('conversation.data.caracteristics');
      if (fields && caracteristics) {
        // if theme selected is not the theme of the conversation
        // reset additional fields selected options

        if (caracteristics['theme-key'] !== this.get('themeSelected.key')) {
          fields.forEach(function (item) {
            item.set('selectedOption', '');
          });
        } else {
          // otherwhise the theme selected is the theme of the conversation
          // set additional fields selected options with existing values
          fields.forEach(function (item) {
            var value = caracteristics[item.name];
            item.set('selectedOption', '');
            item.options.forEach(function (option) {
              if (option.value === value) {
                item.set('selectedOption', option);
              }
            });
          });
        }
      }
      return fields;
    }),
    _validateFields: function _validateFields() {
      var hasFieldsError = false;
      if (this.get('fieldsForTheme')) {
        var errorIndex = -1;
        this.get('fieldsForTheme').forEach(function (field, index) {
          if (field.get('validations') && !field.get('validations.isValid')) {
            field.set('hasError', true);
            hasFieldsError = true;
            if (errorIndex === -1) {
              errorIndex = index;
            }
          } else {
            field.set('hasError', false);
          }
        });
        this.set('fieldsForThemeErrorIndex', errorIndex);
      }
      return hasFieldsError;
    },
    isSubjectInValid: function isSubjectInValid() {
      if (this.get('conversation.subject').trim().length === 0) {
        this.set('showErrors.subject', true);
      } else {
        this.set('showErrors.subject', false);
      }
    },
    displaySubjectError: Ember.computed('validations.attrs.model.conversation.subject.isInvalid', 'showErrors.subject', 'conversation.subject', function () {
      if (this.get('conversation.subject').length === 0) {
        return this.get('validations.attrs.model.conversation.subject.isInvalid') && this.get('showErrors.subject');
      } else {
        this.isSubjectInValid();
        return this.get('validations.attrs.model.conversation.subject.isInvalid') || this.get('showErrors.subject');
      }
    }),
    isCharacterLimitExceeded: Ember.computed('_textHtml.length', function () {
      return this.get('_textHtml.length') > this.get('maxlength');
    }),
    messageIsEmpty: Ember.computed('_textHtml', function () {
      return !this.get('_textHtml') || this.get('_textHtml').split(' ').join('') === '<p></p>';
    }),
    displayMessageError: Ember.computed('showErrors.message', 'messageIsEmpty', function () {
      return this.get('showErrors.message') && (this.get('messageIsEmpty') || this.get('isCharacterLimitExceeded'));
    }),
    actions: {
      textHtmlChanged: function textHtmlChanged(textHtml) {
        this.set('_textHtml', textHtml);
      },
      onSelectedThemeChange: function onSelectedThemeChange(themeSelectedId) {
        var themeSelected = this.get('model.themes').find(function (theme) {
          return theme.id === themeSelectedId;
        });
        this.set('themeSelected', themeSelected);

        // iOS a11y fix
        document.getElementById('conversation-composer-create-select-theme').focus();
      },
      onFieldForThemeChange: function onFieldForThemeChange(field, value) {
        var optionSelected = field.get('options').find(function (option) {
          return option.value === value;
        });
        field.set('selectedOption', optionSelected);

        // iOS a11y fix
        document.getElementById('conversation-composer-create-select-' + field.name).focus();
      }
    }
  });
});