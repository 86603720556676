define("lbp-cust-web-application/initializers/controllers/ticust-web-module-projects/interlocutors/interlocutor/projects/project", ["exports", "ticust-web-module-projects/controllers/interlocutors/interlocutor/projects/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-projects/controllers/interlocutors/interlocutor/projects/project',
    // eslint-disable-line
    initialize: initialize
  };
  _project.default.reopen({});
});