define("lbp-cust-web-application/initializers/mixins/attachment-settings", ["exports", "ticust-web-common/mixins/attachment-settings"], function (_exports, _attachmentSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-common/mixins/attachment-settings',
    initialize: initialize
  };
  _attachmentSettings.default.reopen({
    /**
     * List of the types that can be allowed/disallowed
     */
    types: [{
      contentType: 'image/gif',
      label: 'image-gif',
      isAllowed: true
    }, {
      contentType: 'image/jpeg',
      label: 'image-jpeg',
      isAllowed: true
    }, {
      contentType: 'image/png',
      label: 'image-png',
      isAllowed: true
    }, {
      contentType: 'application/pdf',
      label: 'application-pdf',
      isAllowed: true
    }, {
      contentType: 'text/plain',
      label: 'text-plain',
      isAllowed: true
    }, {
      contentType: 'application/rtf',
      label: 'application-rtf',
      isAllowed: true
    }, {
      contentType: 'application/msword',
      label: 'application-msword',
      isAllowed: true
    }, {
      contentType: 'application/vnd.ms-excel',
      label: 'application-vnd.ms-excel',
      isAllowed: true
    }, {
      contentType: 'application/vnd.ms-powerpoint',
      label: 'application-vnd.ms-powerpoint',
      isAllowed: true
    },
    // eslint-disable-line
    {
      contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      label: 'application-vnd.openxmlformats-officedocument.wordprocessingml.document',
      isAllowed: true
    },
    // eslint-disable-line
    {
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      label: 'application-vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      isAllowed: true
    },
    // eslint-disable-line
    {
      contentType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      label: 'application-vnd.openxmlformats-officedocument.presentationml.presentation',
      isAllowed: true
    },
    // eslint-disable-line
    {
      contentType: 'application/vnd.oasis.opendocument.spreadsheet',
      label: 'application-vnd.oasis.opendocument.spreadsheet',
      isAllowed: true
    },
    // eslint-disable-line
    {
      contentType: 'application/vnd.oasis.opendocument.text',
      label: 'application-vnd.oasis.opendocument.text',
      isAllowed: true
    } // eslint-disable-line
    ]
  });
});