define("lbp-cust-web-application/translations/it-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "authentication": {
      "error": {
        "AUTHENTICATION_FAILED": "Autenticazione fallita",
        "BAD_CREDENTIALS": "Errore: nome utente o password errati",
        "UNKNOWN": "Si è verificato un errore sconosciuto. Riprova più tardi"
      },
      "login": {
        "company": "Organizzazione",
        "forgot": "Ho dimenticato i miei dati di accesso",
        "message-info": {
          "error": {
            "invalid-form": "Almeno un campo del modulo non è compilato.",
            "invalid-input": {
              "company": "L'organizzazione è obbligatoria.",
              "password": "La password è obbligatoria.",
              "username": "Il nome utente è obbligatorio."
            }
          }
        },
        "password": "Password",
        "title": "Identificazione utente",
        "username": "Nome utente",
        "valid": "Accedi"
      },
      "prospect": {
        "create": {
          "company": "Organizzazione",
          "email": "Indirizzo email",
          "firstName": "Nome",
          "lastName": "Cognome",
          "link": "Unisciti a noi",
          "user": {
            "password": "Password",
            "title": "Crea una password"
          }
        },
        "message": {
          "save": {
            "error": "Si è verificato un errore durante il salvataggio del potenziale cliente. Si prega di riprovare più tardi.",
            "has-no-company": "Questa organizzazione non esiste",
            "info": "Il potenziale cliente è stato registrato.",
            "user": "L'utente è stato creato.",
            "wrong-email": "Questa email è già in uso. Si prega di utilizzarne un'altra."
          },
          "validation": {
            "invalid-form": "Il modulo non è valido, si prega di correggerlo e riprovare."
          }
        },
        "new": {
          "buttons": {
            "cancel": "Annulla",
            "confirm": "Conferma",
            "create": "Conferma"
          }
        },
        "title": "Nuovo utente"
      },
      "pwdreinit": {
        "cancel": "Annulla",
        "email": "Email",
        "header": "Inserisci il tuo indirizzo email per ricevere il link di reset",
        "message-info": {
          "error": {
            "invalid-form": "Il modulo è compilato in modo errato.",
            "invalid-input": {
              "email": "L'email inserita non è corretta."
            }
          },
          "info": {
            "success": "Se un account è associato all'indirizzo '{email}', riceverai una email contenente un link per resettare la tua password."
          }
        },
        "title": "Reset password",
        "validate": "Conferma"
      },
      "pwdrenew": {
        "email": "Email",
        "firstnewpassword": "Inserisci la tua nuova password",
        "message-info": {
          "error": {
            "echec": "Impossibile modificare la tua password.",
            "invalid-form": "Il modulo è compilato in modo errato. Si prega di controllare i campi.",
            "invalid-input": {
              "firstPassword": "La prima password non è compilata.",
              "secondPassword": "La seconda password non è stata inserita."
            }
          },
          "info": {
            "success": "La tua password è stata cambiata correttamente."
          }
        },
        "password": "Password",
        "secondnewpassword": "Conferma la tua nuova password",
        "title": "Cambio password",
        "validate": "Conferma"
      }
    },
    "common": {
      "application": {
        "title": "Interazioni affidabili"
      },
      "file-upload": {
        "error": {
          "addFile": "Si è verificato un errore, l'allegato non è stato aggiunto. Riprova più tardi.",
          "contentType": "Il tipo di allegato non è consentito.",
          "length": "La dimensione dell'allegato supera la dimensione massima consentita."
        }
      },
      "filter": {
        "datePrefix": "Data",
        "dateTimeLocale": "fr",
        "hide": "Nascondi",
        "lessCriteria": "Mostra meno criteri",
        "message-info": {
          "error": {
            "invalid-form": "Il modulo non è valido, correggilo e riprova."
          }
        },
        "moreCriteria": "Mostra più criteri",
        "preset": "Predefiniti:",
        "reset": "Reimposta i criteri",
        "search": "Avvia la ricerca",
        "show": "Mostra"
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Consulente } CLIENT {Interlocutore } other {}}{gender, select, MR {Sig. }  MME {Sig.ra } other {}}{lastname} {firstname}",
        "unknown-person": "Persona sconosciuta"
      },
      "message-info": {
        "error": {
          "403": "Non hai i permessi per visualizzare questa pagina.",
          "unknown": "Si è verificato un errore durante il caricamento della pagina. Riprova più tardi."
        }
      },
      "notification": {
        "CUST_RESET_PASSWORD": {
          "label": "Cambio password",
          "variables": {
            "URL_LINK": "URL_LINK"
          }
        },
        "LOWER_CONFIDENTIALITY_ACCEPTED": {
          "label": "Accettazione riduzione privacy della conversazione",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "TITOLO_INTERLOCUTORE",
            "INTERLOCUTOR_FIRSTNAME": "NOME_INTERLOCUTORE",
            "INTERLOCUTOR_LASTNAME": "COGNOME_INTERLOCUTORE",
            "INTERLOCUTOR_NATURE": "TIPO_INTERLOCUTORE"
          }
        },
        "LOWER_CONFIDENTIALITY_REFUSED": {
          "label": "Rifiuto riduzione privacy della conversazione",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "TITOLO_INTERLOCUTORE",
            "INTERLOCUTOR_FIRSTNAME": "NOME_INTERLOCUTORE",
            "INTERLOCUTOR_LASTNAME": "COGNOME_INTERLOCUTORE",
            "INTERLOCUTOR_NATURE": "TIPO_INTERLOCUTORE"
          }
        },
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": {
          "label": "Nuovo messaggio ricevuto dall'interlocutore",
          "variables": {
            "CONVERSATION_SUBJECT": "OGGETTO_CONVERSAZIONE",
            "MESSAGE_EXTRACT": "ESTRATTO_MESSAGGIO",
            "URL_LINK": "URL_LINK"
          }
        }
      },
      "page-error-message": "Si è verificato un errore sconosciuto. Riprova più tardi o contatta il servizio di supporto.",
      "pagination-component": {
        "currentPage": "Pagina corrente",
        "firstPage": "Prima pagina",
        "lastPage": "Ultima pagina",
        "nextPage": "Pagina successiva",
        "page": "Pagina",
        "previousPage": "Pagina precedente"
      },
      "panel-collapse": {
        "close": "Riduci",
        "open": "Espandi",
        "title-default": "Titolo del pannello mancante"
      },
      "result-list": {
        "action": {
          "show-grid": "Visualizzazione a icone",
          "show-list": "Visualizzazione a righe"
        },
        "results": {
          "error": "Si è verificato un errore durante il caricamento. Riprova più tardi.",
          "none": "Nessun risultato disponibile"
        },
        "sort": {
          "by": "Ordina per"
        }
      },
      "selectors": {
        "actor-selector": {
          "error": "Si è verificato un errore durante la ricerca.",
          "loading": "Caricamento in corso...",
          "no-results": "Nessun destinatario corrisponde a questa ricerca",
          "required": "Deve essere selezionato un destinatario"
        },
        "theme-selector": {
          "error": "Si è verificato un errore durante la ricerca.",
          "loading": "Caricamento in corso...",
          "no-results": "Nessun tema corrisponde a questa ricerca",
          "required": "Deve essere selezionato un tema"
        }
      },
      "sidemenu": {
        "label": "Menu",
        "minimize": "Chiudi il menu",
        "pulldown": "Apri il menu",
        "search": "Cerca"
      },
      "spellchecker": {
        "languages": {
          "en": "Inglese",
          "fr": "Francese"
        },
        "message-info": {
          "error": {
            "unknown": "Si è verificato un errore."
          }
        }
      },
      "variables": {
        "notificationCommon": {
          "EVENT_DATE": "DATA_EVENTO",
          "RECIPIENT_CIVILITY": "TITOLO_DESTINATARIO",
          "RECIPIENT_FIRSTNAME": "NOME_DESTINATARIO",
          "RECIPIENT_LASTNAME": "COGNOME_DESTINATARIO"
        }
      }
    },
    "common-projects": {
      "closed": "Progetto Chiuso",
      "components": {
        "annex-area-button": {
          "openConversation": {
            "buttonLabel": "Visualizza la conversazione",
            "title": "Conversazione"
          }
        },
        "audit-component": {
          "results": {
            "error": "Si è verificato un errore durante il caricamento",
            "loading": "Caricamento in corso..."
          },
          "table": {
            "no-result": "Nessun risultato"
          }
        },
        "buttons": {
          "close": "Chiudi"
        },
        "file-uploader-label": "Salva",
        "file-uploading": "Salvataggio in corso...",
        "header": "Cronologia",
        "project-nature-selector": {
          "error": "Si è verificato un errore durante la ricerca",
          "loading": "Caricamento dei risultati...",
          "no-results": "Nessuna entità corrisponde a questo termine",
          "required": "Devi selezionare un'entità"
        },
        "table": {
          "action": "Azione",
          "date": "Data",
          "newValue": "Nuovo valore",
          "no-result": "Nessun risultato",
          "oldValue": "Vecchio valore",
          "user": "Utente"
        }
      },
      "document": {
        "audit": {
          "action": {
            "fileInvalid": "Contrassegnato come non valido",
            "fileValid": "Contrassegnato come valido",
            "init": "Inizializzazione",
            "status": "Stato modificato",
            "uploaded": "Deposito"
          },
          "button": {
            "close": "Chiudi"
          },
          "column": {
            "action": "Azione",
            "actor": "Consulente",
            "date": "Data",
            "detail": "Dettaglio",
            "type": "Tipo"
          },
          "userType": {
            "ADVISER": "Consulente",
            "CLIENT": "Cliente"
          }
        },
        "button": {
          "add": "Aggiungi un nuovo file",
          "audit": "Visualizza la cronologia del documento",
          "cancel": "Annulla",
          "copy": "Aggiungi un file da un file esistente",
          "create": {
            "copy": "Copia un file",
            "new": "Documento vuoto",
            "upload": "Deposito di un file"
          },
          "download": "Scarica tutti i file del documento",
          "edit": {
            "show": "Modifica"
          },
          "hideButtons": "Nascondi tutti i pulsanti per il documento e i suoi file.",
          "info": {
            "hide": "Nascondi le informazioni aggiuntive del documento",
            "show": "Visualizza ulteriori informazioni sul documento"
          },
          "markAsNotNeeded": "Già presente",
          "markAsNotValid": "Contrassegna il documento come Non Conforme",
          "mergeFiles": "Unisci file",
          "save": "Convalida",
          "showButtons": "Mostra tutti i pulsanti per il documento e i suoi file.",
          "undoAsNotNeeded": "Annulla già presente",
          "upload": "Aggiungi un file"
        },
        "create": {
          "new": {
            "title": "Nuovo Documento"
          },
          "title": "Aggiungi un nuovo documento"
        },
        "fields": {
          "description": "Descrizione",
          "nature": "Natura",
          "providerType": "Fornitore",
          "signatureRequired": "Firma richiesta",
          "title": "Nome"
        },
        "message-info": {
          "error": {
            "duplicate-title": "Il titolo del documento esiste già.",
            "invalid-form": "Il modulo non è valido, per favore correggilo e riprova.",
            "markAsNotNeeded": "Si è verificato un errore durante l'aggiornamento del documento come già presente, riprova più tardi.",
            "markAsNotValid": "Si è verificato un errore, il documento non è stato contrassegnato come Non Conforme. Riprova più tardi.",
            "markAsValid": "Si è verificato un errore, il documento non è stato contrassegnato come Conforme. Riprova più tardi.",
            "save": "Si è verificato un errore durante il salvataggio del documento. Riprova più tardi.",
            "undoNotNeeded": "Si è verificato un errore durante il ritorno allo stato già presente, riprova più tardi."
          },
          "info": {
            "markAsNotNeeded": "Il documento è stato contrassegnato come già presente.",
            "markAsNotValid": "Il documento è stato contrassegnato come Non Conforme",
            "markAsValid": "Il documento è stato contrassegnato come Conforme",
            "onlyAdviserAllowed": "* Solo il documento con il fornitore del consulente può essere impostato come firma richiesta",
            "save": "Il documento è stato salvato.",
            "undoNotNeeded": "Lo stato del documento già presente è stato annullato."
          }
        },
        "nature": {
          "ADVERTISEMENT": "Pubblicitario",
          "BANK_STATEMENT": "Estratto conto bancario",
          "BIRTH_CERTIFICATE": "Certificato di nascita",
          "COMPROMISE_SALE": "Compromesso di vendita",
          "IBAN": "IBAN",
          "IDENTIFICATION": "Documento di identità",
          "INSURANCE": "Assicurazione",
          "LOAN_OFFER": "Offerta di prestito",
          "PAYSLIP": "Scheda paga",
          "POLICE_RECORD": "Certificato penale",
          "TAX_NOTICE": "Dichiarazione dei redditi",
          "UNKNOWN": "Sconosciuto",
          "label": "Natura: "
        },
        "providerType": {
          "ACTOR": "Consulente",
          "INTERLOCUTOR": "Cliente",
          "UNKNOWN": "Sconosciuto",
          "label": "Fornitore: "
        },
        "refusedReason": {
          "BLURRY": "Il documento è sfocato.",
          "ERROR": "Il documento è fuori tema.",
          "ILLEGIBLE": "Il documento è illeggibile.",
          "label": "Motivo del rifiuto:"
        },
        "status": {
          "KO": "Non conforme",
          "NOT_NEEDED": "Già presente",
          "OK": "Conforme",
          "TO_ACKNOWLEDGE": "Da validare",
          "TO_UPLOAD": "Nuovo",
          "UPLOADED": "Scaricato"
        },
        "statusMessage": {
          "KO": "Questo documento è stato contrassegnato come Non Conforme. Si prega di inviare un altro file.",
          "NOT_NEEDED": "Questo documento è già presente.",
          "TO_ACKNOWLEDGE": "Questo documento deve essere validato.",
          "TO_UPLOAD": "Questo documento deve essere depositato."
        },
        "type": {
          "FREE": "Libero",
          "STRUCTURED": "Strutturato",
          "UNKNOWN": "Sconosciuto",
          "label": "Tipo di documento: "
        }
      },
      "file": {
        "button": {
          "delete": "Elimina il file",
          "download": "Scarica il file",
          "markAsNotValid": "Contrassegna il file come Non Conforme",
          "markAsValid": "Contrassegna il file come Conforme",
          "preview": "Anteprima del file",
          "sign": "Firma questo file"
        },
        "help": {
          "signature-required": "La cartella deve essere firmata",
          "signed": "Il file è già firmato"
        },
        "message-info": {
          "error": {
            "delete": "Si è verificato un errore, il file non è stato eliminato. Riprova più tardi.",
            "get-e-signiture-url": "Si è verificato un errore durante l'ottenimento dell'URL per firmare il file. Riprova più tardi.",
            "mark-as-not-valid": "Si è verificato un errore, il file non è stato contrassegnato come Non Conforme. Riprova più tardi.",
            "mark-as-valid": "Si è verificato un errore, il file non è stato contrassegnato come Conforme. Riprova più tardi."
          },
          "info": {
            "delete": "Il file è stato eliminato.",
            "mark-as-not-valid": "Il file è stato contrassegnato come Non Conforme.",
            "mark-as-valid": "Il file è stato contrassegnato come Conforme.",
            "pop-up": {
              "delete": "Vuoi davvero eliminare questo file?"
            }
          }
        },
        "status": {
          "KO": "Non valido",
          "OK": "Valido",
          "TO_SIGN": "Da firmare",
          "UPLOADED": "In validazione"
        }
      },
      "file-upload": {
        "error": {
          "contentType": "Questo tipo di file non è consentito."
        },
        "info": {
          "uploadSucess": "Il file è stato scaricato."
        }
      },
      "folder": "Cartella",
      "list": {
        "tabs": {
          "personal": "Personali",
          "shared": "Condivisi"
        },
        "title": "Progetti di {interlocutorName}"
      },
      "modal-copy": {
        "buttons": {
          "cancel": "Annulla",
          "copy": "Copia"
        },
        "document": "Seleziona il documento da copiare",
        "file": "Scegli il file da copiare",
        "label": {
          "search": "Cerca"
        },
        "message-info": {
          "error": {
            "post-400": "Si è verificato un errore durante la copia del file, controlla lo stato del documento o assicurati di non avere un file con lo stesso nome.",
            "unknown": "Si è verificato un errore durante la copia del file. Riprova più tardi."
          },
          "info": {
            "save": "{nb, plural, =1 {Il file copiato è stato salvato.} other {I file copiati sono stati salvati.}}",
            "saveAttachment": "Il file copiato è stato salvato.",
            "uploading": "Il file è in fase di download..."
          }
        }
      },
      "modal-merge": {
        "buttons": {
          "cancel": "Annulla",
          "confirm": "Conferma",
          "move-down": "Sposta giù",
          "move-up": "Sposta su",
          "preview": "Anteprima"
        },
        "filename": "Nome del file di destinazione: ",
        "header": "Unione di file",
        "list": "Elenco dei file da unire: ",
        "message-info": {
          "error": {
            "generic": "Si è verificato un errore durante l'unione del file. Riprova più tardi."
          },
          "info": {
            "info": "Alcuni file potrebbero non essere uniti.",
            "loading": "Il file è in fase di unione, attendere...",
            "saved": "Il file è stato unito e salvato."
          },
          "invalid-input": {
            "filename-duplicate": "Il nome del file esiste già nel documento.",
            "filename-empty": "Il nome del file non può essere vuoto.",
            "filename-long": "Il nome del file è troppo lungo.",
            "list-empty": "È necessario selezionare almeno due file.",
            "list-too-large": "Il peso di tutti i file è troppo elevato."
          }
        }
      },
      "modal-share": {
        "buttons": {
          "cancel": "Annulla",
          "save": "Salva"
        },
        "message-info": {
          "error": {
            "loading": "Si è verificato un errore durante il caricamento dei contatti di fiducia. Riprova più tardi.",
            "save": "Si è verificato un errore, la condivisione non è stata registrata. Riprova più tardi."
          },
          "info": {
            "empty-result": "Non hai un contatto di fiducia.",
            "save": "Le informazioni di condivisione sono state salvate."
          }
        },
        "placeholder": "Diritti",
        "title": "Modifica dei diritti del progetto"
      },
      "modal-upload": {
        "message-info": {
          "error": {
            "failed-due-bad-request": "Si è verificato un errore durante il download del file. Controlla la dimensione o il tipo del file.",
            "failed-to-upload": "Si è verificato un errore durante il download del file. Riprova più tardi.",
            "post-400": "Si è verificato un errore durante il download del file, controlla lo stato del documento o assicurati di non avere un file con lo stesso nome.",
            "unknown": "Si è verificato un errore durante il download del file. Riprova più tardi.",
            "wrong-format": "Il formato di un file per il quale è richiesta una firma può essere solo PDF."
          },
          "info": {
            "file-uploading": "Salvataggio in corso...",
            "save": "Il file è stato scaricato."
          }
        }
      },
      "page-error-message": "Si è verificato un errore sconosciuto. Riprova più tardi o contatta il servizio di supporto",
      "preview-component": {
        "buttons": {
          "close": "Chiudi"
        },
        "error": "Si è verificato un errore durante il caricamento dell'immagine. Riprova più tardi.",
        "loading": "Caricamento in corso...",
        "title": {
          "filedBy": "Depositato da:",
          "filedOn": "Depositato il:"
        }
      },
      "project": {
        "assignation": "Questo progetto è gestito da: ",
        "audit": {
          "action": {
            "CREATE": "Creazione del progetto",
            "SHARED_INTERLOCUTOR_ADD": "Aggiunta dell'interlocutore {interlocutorName} come {newProfileName}",
            "SHARED_INTERLOCUTOR_REMOVE": "Rimozione dell'interlocutore {interlocutorName}",
            "SHARED_INTERLOCUTOR_UPDATE": "Modifica dell'interlocutore {interlocutorName} da {previousProfileName} a {newProfileName}",
            "STATUS_CLOSED": "Chiusura del progetto",
            "STATUS_REOPENED": "Riapertura del progetto",
            "UPDATE": "Aggiornamento del progetto",
            "auditToAdviser": "Invio della notifica al consulente {receiverName}",
            "auditToCustomer": "Invio di un avviso al cliente {receiverName}"
          },
          "button": {
            "close": "Chiudi"
          },
          "column": {
            "action": "Azione",
            "date": "Data",
            "detail": "Dettaglio",
            "sender": "Autore"
          }
        },
        "button": {
          "audit": "Visualizza la cronologia di questo progetto",
          "back": "Torna alla lista dei progetti",
          "close": "Chiudi il progetto",
          "download": "Scarica il progetto",
          "edit": "Modifica il progetto",
          "hideInformations": "Riduci le informazioni",
          "notify-to-adviser": "Informa il mio Consulente che il mio progetto è stato aggiornato",
          "notify-to-customer": "Informa il mio Cliente che il suo progetto è stato aggiornato",
          "open": "Apri il progetto",
          "openConversation": "Apri la conversazione corrispondente",
          "reopen": "Riapri il progetto",
          "share": "Gestisci la pubblicazione del progetto",
          "showInformations": "Visualizza ulteriori informazioni"
        },
        "closedDate": "Chiuso il",
        "createdBy": "Da",
        "creationDate": "Creato il",
        "finishDate": "Fine prevista il",
        "lastUpdateDate": "Modificato il {date} da {name}",
        "message-error": {
          "download": "Si è verificato un errore, il progetto non è stato scaricato. Riprova più tardi."
        },
        "message-info": {
          "error": {
            "LIMITED_CLICK_FOR_ADVISER": "Hai già notificato il tuo cliente meno di {limit, plural, =1 {# ora} other {# ore}} fa.",
            "LIMITED_CLICK_FOR_CUSTOMER": "Hai già notificato il tuo consulente meno di {limit, plural, =1 {# ora} other {# ore}} fa.",
            "close": "Si è verificato un errore, il file non è stato chiuso. Riprova più tardi.",
            "reopen": "Si è verificato un errore, il file non è stato riaperto. Riprova più tardi.",
            "unknown": "Si è verificato un errore durante l'invio della notifica, riprova più tardi."
          },
          "info": {
            "close": "Il progetto è stato chiuso.",
            "notify-update": "La notifica è stata inviata.",
            "pop-up": {
              "close": "Vuoi davvero chiudere questo progetto?",
              "reopen": "Vuoi davvero riaprire questo progetto?"
            },
            "reopen": "Il progetto è stato riaperto."
          }
        },
        "new": "Nuovo",
        "reopenDate": "Riaperto il",
        "shared": {
          "byMe": "Questo progetto è attualmente pubblicato a {to} con il profilo: ",
          "not": "Questo progetto attualmente non è pubblicato ad altri clienti.",
          "right": {
            "FULL_ACCESS": "Accesso completo",
            "NO_ACCESS": "Nessun accesso",
            "READ_ONLY": "Sola lettura"
          },
          "toMe": "Questo progetto è stato pubblicato da {from} con il profilo: "
        },
        "statistics": {
          "messages": {
            "KO": "{nb, plural, =1 {# documento respinto} other {# documenti respinti}}",
            "OK": "{nb, plural, =1 {# documento approvato} other {# documenti approvati}}",
            "TO_ACKNOWLEDGE": "{nb, plural, =1 {# documento} other {# documenti}} da validare",
            "UPLOADED": "{nb, plural, =1 {# documento scaricato} other {# documenti scaricati}}"
          }
        },
        "type": {
          "FREE": "Libero",
          "STRUCTURED": "Strutturato"
        }
      },
      "project-card": {
        "invalid-documents": "{numDocs, plural, =1 {# documento è stato contrassegnato come non valido} other {# documenti sono stati contrassegnati come non validi}}",
        "project-closed-alt": "Progetto chiuso",
        "project-shared-alt": "Progetto condiviso"
      },
      "project-element-list": {
        "sort": {
          "creationDate": {
            "asc": "I più vecchi",
            "desc": "I più recenti"
          },
          "lastUpdateDate": {
            "asc": "Aggiornamento più vecchio",
            "desc": "Aggiornamento più recente"
          },
          "sortBy": "Ordina per:"
        },
        "status-filters": {
          "all": "Tutti",
          "all-help": "Tutti i progetti",
          "closed": "Chiusi",
          "closed-help": "Progetti chiusi",
          "opened": "Aperti",
          "opened-help": "Progetti aperti"
        }
      },
      "project-group-title": {
        "button": {
          "close": "Riduci",
          "open": "Espandi"
        }
      },
      "project-part": {
        "close": "Chiudi questa sezione",
        "open": "Apri questa sezione",
        "title": "Documenti"
      },
      "project-part-validator": {
        "error": {
          "duplicate-title": "Il nome del template della cartella esiste già per questo progetto."
        }
      },
      "project-template-duplicate-validator": {
        "error": {
          "duplicate-title": "La descrizione del modello di progetto esiste già."
        }
      },
      "project-template-part-document-validator": {
        "error": {
          "duplicate-title": "Il nome del template del documento esiste già per questa cartella."
        }
      },
      "selectors": {
        "document-nature-selector": {
          "error": "Si è verificato un errore durante la ricerca",
          "loading": "Caricamento in corso...",
          "no-results": "Nessun risultato"
        },
        "project-nature-selector": {
          "error": "Si è verificato un errore durante la ricerca",
          "loading": "Caricamento in corso...",
          "no-results": "Nessun risultato"
        },
        "project-part-document-file-selector": {
          "error": "Si è verificato un errore durante la ricerca",
          "loading": "Caricamento in corso...",
          "no-results": "Nessun risultato"
        },
        "project-part-document-selector": {
          "error": "Si è verificato un errore durante la ricerca",
          "loading": "Caricamento in corso...",
          "no-results": "Nessun risultato"
        }
      },
      "side-menu": {
        "new-project": "Nuovo Progetto",
        "projects": "Progetti"
      },
      "title": "Progetto"
    },
    "errors": {
      "accepted": "{description} deve essere accettato",
      "after": "{description} deve essere dopo {after}",
      "before": "{description} deve essere prima di {before}",
      "blank": "{description} non può essere vuoto",
      "collection": "{description} deve essere una collezione",
      "confirmation": "{description} non corrisponde a {on}",
      "date": "{description} deve essere una data valida",
      "description": "Questo campo",
      "email": "{description} deve essere un indirizzo email",
      "empty": "{description} non può essere vuoto",
      "equalTo": "{description} deve essere uguale a {is}",
      "even": "{description} deve essere pari",
      "exclusion": "{description} è riservato",
      "file-empty": "Nessun file selezionato",
      "greaterThan": "{description} deve essere maggiore di {gt}",
      "greaterThanOrEqualTo": "{description} deve essere maggiore o uguale a {gte}",
      "inclusion": "{description} non è incluso nell'elenco",
      "invalid": "{description} non è valido",
      "lessThan": "{description} deve essere minore di {lt}",
      "lessThanOrEqualTo": "{description} deve essere minore o uguale a {lte}",
      "notANumber": "{description} deve essere un numero",
      "notAnInteger": "{description} deve essere un numero",
      "odd": "{description} deve essere dispari",
      "otherThan": "{description} deve essere diverso da {value}",
      "phone": "{description} deve essere un numero di telefono",
      "positive": "{description} deve essere positivo",
      "present": "{description} deve essere vuoto",
      "singular": "{description} non può essere una collezione",
      "tooLong": "{description} è troppo lungo (il massimo è {max} caratteri)",
      "tooShort": "{description} è troppo corto (il minimo è {min} caratteri)",
      "unsupported-file": "Il tipo di file non è supportato per il documento da firmare",
      "url": "{description} deve essere un URL",
      "wrongDateFormat": "{description} deve essere nel formato seguente {format}",
      "wrongLength": "{description} non ha la dimensione corretta (deve essere di {is} caratteri)"
    },
    "interactions": {
      "conversation": {
        "action-bar": {
          "archive": "Archivia la conversazione",
          "categorize": "Classifica la conversazione",
          "delete": "Elimina la conversazione",
          "download": "Esporta la conversazione",
          "expiring-date": "Questa conversazione scade il:",
          "mark-as-read": "Segna la conversazione come letta",
          "mark-as-unread": "Segna la conversazione come non letta",
          "open-project": "Apri il progetto",
          "print": "Stampa la conversazione",
          "removeFromTrash": "Elimina definitivamente la conversazione",
          "restore": "Recupera la conversazione",
          "sort-asc": "Ordina i messaggi per data dal più vecchio al più recente",
          "sort-desc": "Ordina i messaggi per data dal più recente al più vecchio",
          "unarchive": "Recupera la conversazione"
        },
        "button": {
          "pause": "Pausa",
          "play": "Riproduci",
          "recordVoiceMessage": {
            "download": "Scarica",
            "label": "Registra messaggio vocale",
            "speed": "Velocità di riproduzione {speed}x",
            "text": "Vocale",
            "times": "×",
            "volume": "Volume {volumeLevel}%"
          }
        },
        "cc": {
          "current": "Questa conversazione è collegata al cliente: {name}"
        },
        "composer": {
          "attachments": {
            "buttons": {
              "delete": {
                "title": "Elimina l'allegato"
              },
              "download": {
                "title": "Scarica l'allegato"
              },
              "preview": {
                "title": "Visualizza l'allegato"
              }
            },
            "upload-in-progress": "(in corso)"
          },
          "buttons": {
            "add-attachment": {
              "title": "Aggiungi nuovo allegato"
            },
            "cancel": {
              "title": "Annulla modifiche",
              "value": "Annulla"
            },
            "save": {
              "title": "Salva il messaggio come bozza",
              "value": "Salva bozza"
            },
            "send": {
              "title": "Invia nuovo messaggio",
              "value": "Invia"
            }
          },
          "notifications": {
            "attachment-uploaded": "L'allegato è stato salvato",
            "empty-file": "Non è possibile scaricare allegati vuoti. Riprova con un file valido.",
            "file-name-already-used": "Esiste già un allegato con lo stesso nome.",
            "message-size-limit": "Gli allegati superano la dimensione massima consentita.",
            "upload-in-progress": "Un allegato è in fase di invio. Impossibile inviare il messaggio."
          },
          "placeholder": "Scrivi un messaggio"
        },
        "create": {
          "cc": "Cc",
          "confidentiality": {
            "HIGH": "Alta",
            "LOW": "Normale",
            "label": "Privacy:"
          },
          "flash-answer": {
            "answer": {
              "no": "No",
              "yes": "Sì"
            },
            "header": "Risultato della risposta rapida",
            "indication": "In caso contrario, continua a inviare il messaggio facendo clic su \"No\"",
            "loading": "Analisi del messaggio ...",
            "question": "Hai trovato questa risposta pertinente?",
            "sent": "Il tuo messaggio è stato inviato al consulente.",
            "title": "Questa risposta automatica sembra rispondere al tuo messaggio:"
          },
          "message-info": {
            "error": {
              "invalid-form": "Il modulo non è valido, controlla l'inserimento.",
              "pop-up": {
                "leaving-page": "Le tue modifiche non sono state salvate, vuoi lasciare la pagina?"
              },
              "recipient": "Il destinatario della conversazione è obbligatorio.",
              "save": {
                "conversation": "Si è verificato un errore, la conversazione non è stata creata. Riprova più tardi.",
                "conversation-message": "Si è verificato un errore, il messaggio non è stato creato. Riprova più tardi."
              },
              "subject": "L'oggetto della conversazione è obbligatorio."
            },
            "info": {
              "save": "La conversazione è stata creata."
            }
          },
          "subject": "Oggetto:",
          "theme": "Argomento:",
          "title": "Nuova conversazione",
          "to": "Destinatario:"
        },
        "draft": {
          "button": {
            "delete": {
              "label": "Elimina",
              "title": "Elimina la conversazione in bozza."
            }
          },
          "message-info": {
            "error": {
              "delete": "Si è verificato un errore, la bozza della conversazione non è stata eliminata. Riprova più tardi.",
              "invalid-form": "Il modulo non è valido, controlla l'inserimento.",
              "save": {
                "conversation": "Si è verificato un errore, la bozza della conversazione non è stata aggiornata. Riprova più tardi.",
                "conversation-message": "Si è verificato un errore, la bozza del messaggio non è stata aggiornata. Riprova più tardi."
              }
            },
            "info": {
              "delete": "La bozza è stata eliminata.",
              "pop-up": {
                "delete": "Vuoi eliminare questa conversazione?"
              },
              "save": "La bozza è stata salvata."
            }
          },
          "title": "Modifica una bozza"
        },
        "errors": {
          "delete": "Impossibile eliminare la bozza. Si prega di riprovare più tardi",
          "save": "Impossibile salvare il messaggio. Si prega di riprovare più tardi",
          "send": "Impossibile inviare il messaggio. Si prega di riprovare più tardi"
        },
        "export": {
          "attachments": "Con allegati",
          "button": {
            "cancel": "Annulla",
            "download": "Scarica"
          },
          "options": "Opzioni di esportazione"
        },
        "header": {
          "has-attachments": "Questa conversazione ha allegati",
          "is-confidential": "Questa conversazione è confidenziale",
          "message-number": "{nb, plural, =0 {nessun messaggio} =1 {1 messaggio} other {# messaggi}} ",
          "open-project": "Questa conversazione è collegata a un progetto personale ",
          "status": {
            "CLOSED": "Archiviata",
            "DELETED": "Eliminata",
            "INIT": "Bozza",
            "IN_PROGRESS": "In corso",
            "TO_ARCHIVE": "Eliminata definitivamente",
            "title": "Stato"
          },
          "unread": "Non letto"
        },
        "message-info": {
          "error": {
            "accept": "Si è verificato un errore durante l'accettazione della richiesta di riservatezza. Riprova più tardi.",
            "archive": "Si è verificato un errore durante l'archiviazione. Riprova più tardi.",
            "categorize": "Si è verificato un errore durante la classificazione. Riprova più tardi.",
            "confidentiality-response": "Si è verificato un errore durante la risposta alla richiesta di privacy. Riprova più tardi.",
            "delete": "Si è verificato un errore durante l'eliminazione. Riprova più tardi.",
            "download": "Si è verificato un errore durante l'esportazione. Riprova più tardi.",
            "markAsRead": "Si è verificato un errore durante il contrassegno come letto. Riprova più tardi.",
            "markAsUnread": "Si è verificato un errore durante il contrassegno come non letto. Riprova più tardi.",
            "pop-up": {
              "archive": "Vuoi archiviare questa conversazione?",
              "delete": "Vuoi davvero eliminare questa conversazione? Questa azione è irreversibile.",
              "leaving-page": "Le tue modifiche non sono state salvate, vuoi uscire dalla pagina?"
            },
            "print": "Si è verificato un errore durante la stampa. Riprova più tardi.",
            "refuse": "Si è verificato un errore durante il rifiuto della richiesta di privacy. Riprova più tardi.",
            "removeFromTrash": "Si è verificato un errore durante l'eliminazione definitiva. Riprova più tardi.",
            "restore": "Si è verificato un errore durante il ripristino. Riprova più tardi.",
            "unarchive": "Si è verificato un errore durante il ripristino. Riprova più tardi."
          },
          "info": {
            "accept": "La richiesta di abbassamento del livello di privacy è stata accettata.",
            "archive": "La conversazione è stata archiviata.",
            "categorize": "La conversazione è stata classificata.",
            "delete": "La conversazione è stata eliminata.",
            "markAsRead": "La conversazione è stata contrassegnata come letta.",
            "markAsUnread": "La conversazione è stata contrassegnata come non letta.",
            "pop-up-removeFromTrash": "Sei sicuro di voler eliminare definitivamente questa conversazione?",
            "refuse": "La richiesta di abbassamento della riservatezza è stata respinta.",
            "removeFromTrash": "La conversazione è stata eliminata definitivamente.",
            "restore": "La conversazione è stata recuperata.",
            "unarchive": "La conversazione è stata ripristinata."
          }
        },
        "showMore": {
          "button": "Visualizza più messaggi.",
          "message-info": {
            "error": {
              "showMoreMessages": "Si è verificato un errore, i messaggi non sono stati scaricati. Si prega di riprovare più tardi."
            },
            "info": {
              "showMoreMessages": "Sono stati scaricati messaggi più vecchi."
            }
          }
        },
        "sr-only": {
          "folderColor": "Associato alla cartella dei colori",
          "lastMessageReceivedDate": "Data dell ultimo messaggio ricevuto.",
          "messageText": "Messaggio che inizia con"
        },
        "success": {
          "delete": "La bozza è stata eliminata",
          "save": "La bozza è stata salvata",
          "send": "Il messaggio è stato inviato"
        },
        "templates": {
          "confidentiality": {
            "accept": "<p>Ciao,</p><p>Il cliente ha accettato di abbassare il livello di privacy della conversazione.</p><p>Cordiali saluti,</p>",
            "refuse": "<p>Ciao,</p><p>Il cliente ha rifiutato di abbassare il livello di privacy della conversazione.</p><p>Cordiali saluti,</p>"
          }
        }
      },
      "conversation-action-bar-component": {
        "actions": {
          "archive": {
            "display": "Archivia",
            "title": "Archivia tutte le conversazioni selezionate"
          },
          "categorize": {
            "display": "Classifica in ...",
            "labelSelected": "Classifica tutte le conversazioni selezionate in {label}",
            "title": "Classifica tutte le conversazioni selezionate in ...",
            "toggle": "Apri il menu 'Classifica in ...'"
          },
          "checkAll": "Seleziona tutte le conversazioni visualizzate",
          "delete": {
            "display": "Elimina",
            "title": "Elimina tutte le conversazioni selezionate"
          },
          "markAs": {
            "display": "Segna come ...",
            "markAsRead": {
              "display": "Segna come letto",
              "title": "Segna come lette tutte le conversazioni selezionate"
            },
            "markAsUnread": {
              "display": "Segna come non letto",
              "title": "Segna come non lette tutte le conversazioni selezionate"
            },
            "toggle": "Apri il menu 'Segna come ...'"
          },
          "removeFromTrash": {
            "display": "Elimina dal cestino",
            "title": "Elimina dal cestino tutte le conversazioni selezionate"
          },
          "restore": {
            "display": "Recupera",
            "title": "Recupera tutte le conversazioni selezionate"
          },
          "unarchive": {
            "display": "Ripristina",
            "title": "Ripristina tutte le conversazioni selezionate"
          },
          "uncheckAll": "Deseleziona tutte le conversazioni selezionate"
        },
        "search": {
          "button": "Filtra le conversazioni con il testo inserito",
          "input": "Inserisci il testo da cercare",
          "label": "Cercare nelle conversazioni:"
        },
        "sort": {
          "sortBy": "Ordina per:"
        }
      },
      "conversation-line-component": {
        "buttons": {
          "check": "Seleziona la conversazione.",
          "uncheck": "Deseleziona la conversazione."
        },
        "confidential": "La conversazione è confidenziale.",
        "conversationSubject": "Oggetto della conversazione.",
        "hasAttachments": "La conversazione ha almeno un messaggio con un allegato.",
        "isRead": {
          "read": "L'ultimo messaggio della conversazione è stato letto.",
          "unread": "L'ultimo messaggio della conversazione è stato non letto."
        },
        "lastMessageDisplayName": "Nome del consulente associato all'ultimo messaggio.",
        "lastMessageReceivedDate": "Data dell'ultimo messaggio ricevuto.",
        "lastMessageReceivedcontent": "Contenuto dell'ultimo messaggio ricevuto.",
        "lastMessageStatus": {
          "DRAFT": "L'ultimo messaggio della conversazione è una bozza.",
          "RECEIVED": "L'ultimo messaggio della conversazione proviene da un consulente.",
          "SENT": "L'ultimo messaggio della conversazione proviene da te."
        }
      },
      "conversation-message": {
        "body": {
          "buttons": {
            "show-less": {
              "text": "--- visualizza il messaggio parziale ---",
              "title": "Mostra il messaggio parziale"
            },
            "show-more": {
              "text": "--- visualizza il messaggio completo ---",
              "title": "Mostra il messaggio completo"
            }
          }
        },
        "header": {
          "expand-button": {
            "label-expand": "Ingrandisci l'intestazione",
            "label-shrink": "Riduci l'intestazione"
          },
          "expanded": {
            "cc": "Cc:",
            "cc-type": {
              "actors": "(Consulente)",
              "externals": "(Esterno)",
              "interlocutors": "(Cliente)"
            },
            "channel": "Canale:",
            "channels": {
              "MAIL": "Email"
            },
            "from": "Da:",
            "receiveDate": "Ricevuto il:",
            "sendDate": "Inviato a:",
            "subject": "Oggetto:",
            "tags": "Etichette:",
            "to": "A:",
            "virus-scan": "Scansione antivirus:"
          },
          "shrunken": {
            "cc": "Cc:",
            "from": "Da:",
            "to": "A:"
          },
          "title": "Informazioni sul messaggio"
        }
      },
      "conversation-message-attachment": {
        "attachment": "Allegati",
        "download": "Scarica il file",
        "dropdown-button-title": "Azioni",
        "preview": "Anteprima del file"
      },
      "conversation-message-confidentiality": {
        "accept": "Hai accettato di abbassare il livello di privacy il {date}",
        "asking": "Riduci il livello di privacy di questa conversazione:",
        "buttons": {
          "accept": "Accepter",
          "refuse": "Refuser"
        },
        "refuse": "Hai rifiutato di abbassare il livello di privacy il {date}"
      },
      "conversations": {
        "search": {
          "list": {
            "title": "Conversazioni"
          },
          "message-info": {
            "error": {
              "archive": "Si è verificato un errore durante l'archiviazione. Riprova più tardi.",
              "categorize": "Si è verificato un errore durante la classificazione. Riprova più tardi.",
              "delete": "Si è verificato un errore durante l'eliminazione. Riprova più tardi.",
              "markAsRead": "Si è verificato un errore durante il contrassegno come letto. Riprova più tardi.",
              "markAsUnread": "Si è verificato un errore durante il contrassegno come non letto. Riprova più tardi.",
              "removeFromTrash": "Si è verificato un errore durante l'eliminazione definitiva. Riprova più tardi.",
              "restore": "Si è verificato un errore durante il ripristino. Riprova più tardi.",
              "unarchive": "Si è verificato un errore durante il ripristino. Riprova più tardi."
            },
            "info": {
              "archive": "L'archiviazione è stata effettuata.",
              "categorize": "La classificazione è stata effettuata.",
              "delete": "L'eliminazione è stata effettuata.",
              "markAsRead": "Il segnalibro come letto è stato effettuato.",
              "markAsUnread": "Il segnalibro come non letto è stato effettuato.",
              "pop-up-removeFromTrash": "Sei sicuro di voler effettuare l'eliminazione?",
              "removeFromTrash": "L'eliminazione definitiva è stata effettuata.",
              "restore": "Il ripristino è stato effettuato.",
              "unarchive": "Il ripristino è stato effettuato."
            }
          }
        },
        "sort": {
          "lastMessageReceivedDate": {
            "asc": "Il più vecchio",
            "desc": "Il più recente"
          }
        }
      },
      "forbidden-interlocutor": "L'accesso a questo utente non è consentito",
      "label": {
        "categorize-component": {
          "buttons": {
            "close": "Chiudi",
            "manage": "Gestisci i miei archivi personali",
            "save": "Salva"
          },
          "header": {
            "text": "I miei archivi personali",
            "title": "I miei archivi personali"
          },
          "loading": "Caricamento in corso...",
          "no-data": "Nessun archivio personale da mostrare"
        },
        "popup-edit-component": {
          "buttons": {
            "close": "Annulla",
            "save": "Salva"
          },
          "fields": {
            "color": {
              "label-color-1": "Colore 1",
              "label-color-2": "Colore 2",
              "label-color-3": "Colore 3",
              "label-color-4": "Colore 4",
              "label-color-5": "Colore 5",
              "text": "Colore:",
              "title": "Colore"
            },
            "description": {
              "text": "Descrizione:",
              "title": "Descrizione"
            },
            "name": {
              "text": "Nome:",
              "title": "Nome"
            }
          },
          "header": {
            "edit": {
              "text": "Archivio personale: [{name}]",
              "title": "Archivio personale"
            },
            "new": {
              "text": "Nuovo archivio personale",
              "title": "Nuovo archivio personale"
            }
          },
          "message-info": {
            "error": {
              "duplication": "Questo nome è già utilizzato per un altro archivio personale.",
              "invalid-form": "Il modulo non è valido, controlla l'inserimento."
            }
          }
        },
        "settings": {
          "buttons": {
            "add": {
              "label": "Aggiungi",
              "title": "Aggiungi"
            },
            "delete": {
              "label": "Elimina",
              "title": "Elimina"
            },
            "edit": {
              "label": "Modifica",
              "title": "Modifica"
            }
          },
          "fields": {
            "defaultText": {
              "text": "Aa",
              "title": "Esempio di etichetta"
            },
            "description": {
              "title": "Descrizione"
            },
            "name": {
              "title": "Nome dell'etichetta"
            }
          },
          "message-info": {
            "error": {
              "create": "Si è verificato un errore, la cartella personale non è stata aggiunta. Riprova più tardi.",
              "delete": "Si è verificato un errore, la cartella personale non è stata eliminata. Riprova più tardi.",
              "edit": "Si è verificato un errore, la cartella personale non è stata modificata. Riprova più tardi.",
              "get-404": "Si è verificato un errore, la cartella personale non è stata trovata. Riprova più tardi.",
              "unknow": "Si è verificato un errore durante il caricamento della pagina. Riprova più tardi."
            },
            "info": {
              "create": "Il tuo archivio personale è stato creato.",
              "delete": "Il tuo archivio personale è stato eliminato.",
              "deleteConfirm": "Vuoi eliminare l'archivio personale [{name}] ?",
              "edit": "Il tuo archivio personale è stato aggiornato."
            }
          },
          "search": {
            "results": {
              "title": "Archivi personali"
            }
          }
        }
      },
      "message-attachment": {
        "message-info": {
          "error": {
            "download": "Il download non è riuscito. Riprova più tardi."
          }
        }
      },
      "message-composer-attachments": {
        "multiple-upload": {
          "error": "{nbUploadsFailed, plural, one {# allegato non è} other {# allegati non sono}} riusciti a essere {nbUploadsFailed, plural, one {caricato} other {caricati}}.",
          "success": "{nbUploadsPassed, plural, one {L'allegato è stato caricato} other {# allegati sono stati caricati}} con successo."
        }
      },
      "preview-component": {
        "buttons": {
          "close": "Indietro"
        },
        "error": "Si è verificato un errore durante il caricamento dell'immagine. Riprova più tardi.",
        "loading": "Caricamento in corso...",
        "title": {
          "uploadDate": "Caricato il:",
          "uploader": "Caricato da:"
        }
      },
      "side-menu": {
        "conversations": {
          "label": "Conversazioni",
          "lines": {
            "archived": "Archiviate",
            "draft": "Bozze",
            "mine": "Le mie conversazioni",
            "sent": "Inviate",
            "trashed": "Cestino"
          },
          "sr-only": "conversazioni"
        },
        "labels": {
          "configurationButton": "Configurazione",
          "label": "Cartelle personali"
        },
        "new-conversation": "Nuova conversazione"
      }
    },
    "interlocutors": {
      "breadcrumb": {
        "title": "Interlocuteurs"
      },
      "interlocutor": {
        "projects": {
          "breadcrumb": {
            "title": "Progetti"
          },
          "search": {
            "closed": "Progetti chiusi",
            "open": "Progetti in corso"
          }
        }
      }
    },
    "profile": {
      "coordinates": {
        "edit": {
          "cancel": "Annulla",
          "email": {
            "clear": "Cancella il campo email esterna.",
            "label": "Email esterna:",
            "title": "Email esterna"
          },
          "mobile-phone": {
            "clear": "Cancella il campo telefono cellulare.",
            "invalid-message": "Il numero di telefono inserito non è valido.",
            "label": "Telefono cellulare:",
            "title": "Telefono cellulare"
          },
          "save": "Salva"
        },
        "header": "Contatti",
        "message-info": {
          "error": {
            "invalid-form": "Il modulo non è valido, correggilo e riprova.",
            "save": "Si è verificato un errore durante l'aggiornamento dei tuoi contatti. Riprova più tardi."
          },
          "info": {
            "save": "I tuoi contatti sono stati aggiornati."
          }
        },
        "view": {
          "edit": "Modifica",
          "email": {
            "label": "Email esterna:"
          },
          "mobile-phone": {
            "label": "Telefono cellulare:"
          }
        }
      },
      "infos": {
        "field": {
          "firstname": {
            "label": "Cognome:",
            "title": "Cognome"
          },
          "name": {
            "label": "Nome:",
            "title": "Nome"
          }
        },
        "header": "Informazioni personali"
      },
      "notifications": {
        "NEW_CAMPAIGN_MESSAGE_RECEIVED": "Nuovo messaggio di campagna ricevuto",
        "NEW_DOCUMENT_UPLOADED_BY_ADVISER": "Nuovo documento creato da un consulente",
        "NEW_FILE_AVAILABLE_TO_SIGN": "Nuovo file da firmare",
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nuovo messaggio ricevuto",
        "PROJECT_PUBLICATION_STOPPED": "Condivisione del progetto interrotta",
        "PROJECT_PUBLISHED_TO_INTERLOCUTOR": "Nuovo progetto condiviso con me",
        "SEND_DOCUMENT_REJECT_TO_CUSTOMER": "Documento rifiutato da un consulente",
        "SEND_UPDATE_TO_CUSTOMER": "Aggiornamento del progetto"
      },
      "preferences": {
        "authorizeAudioMessage": {
          "label": "Messaggi audio consentiti:",
          "title": "Messaggi audio consentiti:"
        },
        "authorizeAudioMessages": {
          "false": "No",
          "true": "Sì"
        },
        "availableLangages": {
          "en-gb": "English",
          "fr-fr": "Français",
          "it-it": "Italiano"
        },
        "closeErrorMessage": {
          "label": "Chiusura dei messaggi di errore:",
          "title": "Chiusura dei messaggi di errore:"
        },
        "closeErrorMessages": {
          "false": "Automatico",
          "true": "Manuale"
        },
        "edit": {
          "cancel": "Annulla",
          "save": "Salva"
        },
        "header": "Preferenze",
        "language": {
          "label": "Lingua dell'interfaccia:",
          "title": "Lingua"
        },
        "message-info": {
          "error": {
            "save": "Si è verificato un errore durante l'aggiornamento delle preferenze. Riprova più tardi."
          },
          "info": {
            "save": "Le preferenze sono state aggiornate."
          }
        },
        "spellchecker-language": {
          "label": "Lingua di verifica predefinita:",
          "title": "Lingua di verifica predefinita"
        },
        "view": {
          "edit": "Modifica"
        }
      },
      "side-bar": {
        "sign-out": "Disconnetti",
        "title": "Impostazioni dell'account"
      },
      "side-menu": {
        "personal-informations": "Informazioni personali",
        "preferences": "Preferenze",
        "subscriptions": "Notifiche"
      },
      "subscriptions": {
        "edit": {
          "cancel": "Annulla",
          "save": "Salva"
        },
        "header": "I miei abbonamenti",
        "message-info": {
          "error": {
            "loading": "Si è verificato un errore durante il caricamento degli abbonamenti alle notifiche. Riprova più tardi.",
            "save": "Si è verificato un errore durante il salvataggio degli abbonamenti alle notifiche. Riprova più tardi."
          },
          "info": {
            "save": "I tuoi abbonamenti alle notifiche sono stati aggiornati."
          }
        },
        "view": {
          "edit": "Modifica",
          "state": {
            "disabled": "Inattivo",
            "enabled": "Attivo"
          }
        }
      }
    },
    "projects": {
      "breadcrumb": {
        "choose-project": "Scelta Progetto",
        "create": {
          "free": {
            "title": "Progetto Libero"
          },
          "title": "Nuovo Progetto"
        },
        "edit": {
          "free": {
            "title": "Modifica Progetto"
          }
        },
        "project-details": "Dettagli",
        "projects-status": {
          "CLOSED": "Progetti chiusi",
          "IN_PROGRESS": "Progetti in corso"
        }
      },
      "create": {
        "project": {
          "buttons": {
            "cancel": "Annulla",
            "preview": "Anteprima",
            "save": "Salva"
          },
          "fields": {
            "assigned-to": "Assegnato a",
            "date-format": "DD/MM/YYYY",
            "description": "Descrizione",
            "enddate": "Data di fine prevista",
            "errors": {
              "description-absent": "La descrizione del progetto non può essere vuota.",
              "description-too-long": "La descrizione del progetto non può superare i 255 caratteri.",
              "nature-absent": "La natura del progetto non può essere vuota.",
              "ownerActor-absent": "Il progetto deve essere assegnato a un consulente.",
              "title-absent": "Il nome del progetto non può essere vuoto.",
              "title-too-long": "Il nome del progetto non può superare i 255 caratteri."
            },
            "nature": "Natura",
            "title": "Nome"
          },
          "folder": {
            "no-data": "Il progetto non ha alcuna cartella.",
            "status": {
              "CLOSED": "Chiuso",
              "INIT": "Inizializzato",
              "OPENED": "Aperto"
            },
            "title": "Cartelle"
          },
          "message-info": {
            "error": {
              "create": "Si è verificato un errore durante la creazione del progetto. Riprova più tardi.",
              "duplicate-title": "Il nome esiste già per questo interlocutore.",
              "invalid-form": "Il modulo non è valido, correggilo e riprova."
            },
            "info": {
              "create": "Il progetto è stato creato."
            }
          },
          "pop-up": {
            "not-saved-transition": "Le modifiche non sono state salvate. Sei sicuro di voler lasciare questa pagina?"
          }
        }
      },
      "document": {
        "no-data": "Questa sezione non ha un documento."
      },
      "edit": {
        "project": {
          "buttons": {
            "cancel": "Annulla",
            "preview": "Anteprima",
            "save": "Salva"
          },
          "fields": {
            "assigned-to": "Assegnato a",
            "date-format": "DD/MM/YYYY",
            "description": "Descrizione",
            "enddate": "Data di fine prevista",
            "errors": {
              "description-absent": "La descrizione del progetto non può essere vuota.",
              "nature-absent": "La natura del progetto non può essere vuota.",
              "title-absent": "Il nome del progetto non può essere vuoto."
            },
            "nature": "Natura",
            "title": "Nome"
          },
          "folder": {
            "no-data": "Il progetto non ha alcuna cartella.",
            "status": {
              "CLOSED": "Chiuso",
              "INIT": "Inizializzato",
              "OPENED": "Aperto"
            },
            "title": "Cartelle"
          },
          "message-info": {
            "error": {
              "duplicate-title": "Il nome esiste già per questo interlocutore.",
              "edit": "Si è verificato un errore durante la modifica del progetto. Riprova più tardi.",
              "invalid-form": "Il modulo non è valido, correggilo e riprova."
            },
            "info": {
              "edit": "Il progetto è stato modificato."
            }
          },
          "pop-up": {
            "not-saved-transition": "Le modifiche non sono state salvate. Sei sicuro di voler lasciare questa pagina?"
          }
        }
      },
      "list": {
        "message-info": {
          "error": {
            "get-404": "Si è verificato un errore, il progetto non è stato trovato. Riprova più tardi.",
            "unknown": "Si è verificato un errore durante il caricamento della pagina. Riprova più tardi."
          }
        },
        "title": "Progetti"
      },
      "new-project": {
        "buttons": {
          "choose-btn": "Scegli"
        },
        "free": {
          "description": "In un progetto libero, il cliente o tu stesso puoi liberamente aggiungere documenti.",
          "title": "Nuovo Progetto Libero"
        }
      },
      "project": {
        "interlocutor-new": {
          "help": "Crea un nuovo progetto per {interlocutor}",
          "title": "Nuovo progetto per {interlocutor}"
        },
        "new": {
          "help": "Crea un nuovo progetto",
          "title": "Nuovo progetto"
        }
      },
      "project-group": {
        "no-data": "Questo gruppo di progetti è vuoto."
      },
      "project-group-title": {
        "projects": "Progetti appartenenti a {interlocutorFullName}",
        "projects-shared": "Progetti condivisi con {interlocutorFullName}"
      },
      "project-part": {
        "no-data": "Questo progetto non ha una sezione."
      },
      "search": {
        "filter": {
          "label": {
            "assignedTo": "Assegnato a ::",
            "interlocutor": "Interlocutore:"
          }
        },
        "legend": "Cerca",
        "message-info": {
          "error": {
            "project-load": "Impossibile caricare l'elenco dei progetti"
          }
        }
      },
      "side-menu": {
        "closed-projects": "Chiudi",
        "in-progress-projects": "In corso",
        "interlocutor-menu-label": "Progetti di {name}",
        "my-projects": "I miei",
        "projects": "Progetti"
      },
      "signature": {
        "spinner": {
          "primary-error": "Si è verificato un errore",
          "primary-success": "Il documento è stato firmato",
          "primary-wait": "Verifica della firma del documento...",
          "secondary-error": "Il documento non è stato firmato",
          "secondary-success": "Puoi chiudere questa scheda",
          "secondary-wait": "Veuillez attendre"
        }
      },
      "title": {
        "empty": "Progetti",
        "mine": "Progetti"
      }
    },
    "shared": {
      "accessibility": {
        "pagination": "Pagination"
      },
      "components": {
        "a11y": {
          "modals": {
            "alert-modal": {
              "closeButtonText": "Chiudi",
              "closeButtonTitle": "Chiudi finestra"
            }
          }
        },
        "absence-table": {
          "absence-backend-error": "Errore durante il recupero delle assenze di un consulente",
          "granularity-dateformat-day": "DD/MM/YYYY",
          "granularity-dateformat-month": "MMMM YYYY",
          "granularity-dateformat-week": "Settimana {week} - {year}",
          "granularity-day": "giorno",
          "granularity-legend": "Visualizzazione",
          "granularity-month": "mese",
          "granularity-week": "settimana",
          "grid-caption": "Elenco delle assenze",
          "next-day-button-label": "Giorno successivo",
          "next-month-button-label": "Mese successivo",
          "next-week-button-label": "Settimana successiva",
          "prev-day-button-label": "Giorno precedente",
          "prev-month-button-label": "Mese precedente",
          "prev-week-button-label": "Settimana precedente",
          "range-label": "Da {start} a {end}",
          "range-label-backup": "Sostituito da {backup}",
          "today-button-label": "Oggi"
        },
        "charts": {
          "adviser-dashboard-chart": {
            "ACKNOWLEDGE": "Conversazioni acquisite",
            "CLOSED": "Conversazioni gestite",
            "CLOSED-AFTER-CRITICAL-DUE-DATE": "Numero di conversazioni chiuse dopo la data di scadenza critica",
            "CLOSED-BEFORE-CRITICAL-DUE-DATE": "Numero di conversazioni chiuse prima della data di scadenza critica",
            "CLOSED-BEFORE-DUE-DATE": "Numero di conversazioni chiuse prima della data di scadenza",
            "OPEN": "Conversazioni create",
            "REOPEN": "Conversazioni riaperte",
            "nb-conversations": "Numero di conversazioni"
          }
        },
        "date": {
          "cancel": "Annulla",
          "dow": {
            "abbr": {
              "Fr": "Venerdì",
              "Mo": "Lunedì",
              "Sa": "Sabato",
              "Su": "Domenica",
              "Th": "Giovedì",
              "Tu": "Martedì",
              "We": "Mercoledì"
            },
            "text": {
              "Fr": "Ve",
              "Mo": "Lu",
              "Sa": "Sa",
              "Su": "Do",
              "Th": "Gio",
              "Tu": "Ma",
              "We": "Me"
            }
          },
          "format": "DD/MM/YYYY",
          "formatWithTime": "DD/MM/YYYY HH:mm",
          "month": {
            "april": "Aprile",
            "august": "Agosto",
            "december": "Dicembre",
            "february": "Febbraio",
            "january": "Gennaio",
            "july": "Luglio",
            "june": "Giugno",
            "march": "Marzo",
            "may": "Maggio",
            "november": "Novembre",
            "october": "Ottobre",
            "september": "Settembre"
          },
          "month-year": "Mese Anno",
          "next-month": "Mese successivo",
          "next-year": "Anno successivo",
          "ok": "OK",
          "picker-button-alt": "Scegli una data",
          "placeholderFormat": "GG/MM/AAAA",
          "placeholderFormatWithTime": "GG/MM/AAAA hh:mm",
          "previous-month": "Mese precedente",
          "previous-year": "Anno precedente"
        },
        "dropdown": {
          "default-button-title": "Azioni"
        },
        "form-component": {
          "form-fields-required-label": "I campi contrassegnati con * sono obbligatori."
        },
        "form-group-component": {
          "missing-text": "Questo campo è obbligatorio."
        },
        "modals": {
          "audio-preview": {
            "loading": "Caricamento in corso...",
            "modal-title": "Riproduzione audio"
          },
          "authenticator-confirm-modal": {
            "cancel": "Disconnetti",
            "confirm": "SÌ",
            "message": "Verrai disconnesso tra pochi istanti.",
            "question": "Desideri prolungare la tua sessione?",
            "title": "Informazione"
          },
          "document-preview": {
            "error-message": "Si è verificato un errore durante il caricamento dell'immagine. Riprova più tardi.",
            "modal-title": "Anteprima del file",
            "select-page": "Seleziona una pagina",
            "upload-by": "Caricato da {actor} il {date}",
            "zoom-in": "Ingrandisci",
            "zoom-out": "Riduci"
          },
          "image-preview": {
            "error-message": "Si è verificato un errore durante il caricamento dell'immagine. Riprova più tardi.",
            "loading-message": "Caricamento in corso...",
            "modal-title": "Anteprima del file",
            "upload-by": "Caricato da",
            "upload-date": "Caricato il",
            "zoom-in": "Ingrandisci",
            "zoom-out": "Riduci"
          },
          "modal-component": {
            "modal-cancel": "Annulla",
            "modal-confirm": "Conferma"
          },
          "title": {
            "closeButton": "Chiudi questo messaggio"
          }
        },
        "multi-selectors": {
          "cc-multi-selector": {
            "error": "Si è verificato un errore durante la ricerca.",
            "error-external-on-secured": "Impossibile aggiungere una email esterna al canale di posta elettronica sicura",
            "error-secured-on-external": "Impossibile aggiungere una email sicura al canale di posta elettronica esterna",
            "errors": {
              "no-account-found-external": "Nessun account trovato con l'email esterna '{email}'",
              "no-account-found-secured": "Nessun account trovato con l'email sicura '{email}'"
            },
            "loading": "Caricamento in corso...",
            "no-results": "Nessun risultato",
            "type": {
              "actor": "Consulente: ",
              "entity": "Entità: ",
              "external": "Est.",
              "interlocutor": "",
              "trustedInterlocutor": "Interlocutore di fiducia"
            }
          },
          "tags-multi-selector": {
            "error": "Si è verificato un errore durante la ricerca.",
            "loading": "Caricamento in corso...",
            "no-results": "Nessun risultato"
          }
        },
        "panels": {
          "showLess": "Mostra di meno",
          "showMore": "Mostra di più"
        },
        "presets-selector": {
          "title": "Predefiniti"
        },
        "rating": {
          "message-info": {
            "error": {
              "rate": "Si è verificato un errore durante il salvataggio del voto, riprova più tardi."
            },
            "info": {
              "rate": "Il voto è stato salvato con successo."
            }
          },
          "title": "Vuoi cambiare il tuo voto?",
          "title-new": "Sei soddisfatto di questo scambio?"
        },
        "result-list": {
          "results": {
            "error": "Si è verificato un errore durante il caricamento. Riprova più tardi.",
            "loading": "Caricamento in corso...",
            "none": "Nessun risultato disponibile",
            "wait": "Attendere prego"
          }
        },
        "text-editor": {
          "actions-list": {
            "f-ai": {
              "choose-template": "Scegli un modello di risposta:",
              "ko-declaration": "Segnala i modelli come non soddisfacenti:",
              "show-templates": "Mostra i modelli di risposta dell'analisi semantica"
            },
            "f-aligncenter": "Centra",
            "f-alignleft": "Allinea a sinistra",
            "f-alignright": "Allinea a destra",
            "f-attachments": {
              "help": "Copia gli allegati"
            },
            "f-bold": "Grassetto",
            "f-color": {
              "help": "Colore",
              "label": "Cambia il colore del testo"
            },
            "f-font": {
              "help": "Carattere",
              "label": "Scegli la famiglia di caratteri"
            },
            "f-gallery": "Aggiungi un'immagine",
            "f-italic": "Corsivo",
            "f-link": {
              "help": "Aggiungi un link",
              "ok": "OK",
              "url": "URL"
            },
            "f-list": "Passa da/a elenco",
            "f-signature": {
              "apply": "Applica la firma",
              "choose": "Scegli una firma",
              "label": "Firma..."
            },
            "f-size": {
              "help": "Dimensione del carattere",
              "label": "Scegli la dimensione del carattere"
            },
            "f-spellCheck": {
              "check-spelling": "Verifica l'ortografia",
              "choose-language": "Scegli una lingua",
              "ignore": "Ignora",
              "ignore-all": "Ignora tutto"
            },
            "f-underline": "Sottolineato",
            "f-upload": {
              "help": "Aggiungi allegato"
            }
          },
          "initial-attachments": {
            "copy-attachment": "Copia un allegato",
            "message-info": {
              "error": {
                "copy-attachment": "Si è verificato un errore durante la copia dell'allegato. Riprova più tardi."
              },
              "info": {
                "file-removed": "L'allegato è stato eliminato.",
                "file-uploaded": "L'allegato è stato copiato."
              }
            }
          },
          "inline-images": {
            "message-info": {
              "error": {
                "empty-file": "Non è possibile scaricare allegati vuoti. Riprova con un file valido.",
                "preview": "Il caricamento dell'immagine non è riuscito. Riprova più tardi."
              }
            }
          },
          "message-composer-attachments": {
            "delete": "Elimina questo allegato",
            "download": "Scarica questo allegato",
            "dropdown-button-title": "Azioni",
            "message-info": {
              "error": {
                "download": "Il download non è riuscito. Riprova più tardi.",
                "empty-file": "Non è possibile scaricare allegati vuoti. Riprova con un file valido.",
                "failed-to-upload": "Impossibile inviare l'allegato. Riprova più tardi.",
                "file-name-already-used": "Esiste già un allegato con lo stesso nome.",
                "maxAttachmentReached": "Hai raggiunto il numero massimo di allegati consentiti.",
                "message-size-limit": "Gli allegati superano la dimensione massima consentita.",
                "not-allowed-content-type": "Il tipo di allegato non è consentito.",
                "preview": "Il caricamento dell'immagine non è riuscito. Riprova più tardi."
              },
              "info": {
                "file-uploaded": "L'allegato è stato salvato",
                "file-uploading": "Salvataggio in corso..."
              }
            },
            "preview": "Visualizza questo allegato"
          }
        }
      },
      "file-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "Non è possibile caricare allegati vuoti. Riprova con un file valido.",
          "NOT_ALLOWED_CONTENT_TYPE": "Il tipo di allegato non è consentito.",
          "addFile": "Si è verificato un errore, l'allegato non è stato aggiunto. Riprova più tardi.",
          "contentType": "Il tipo di allegato non è consentito.",
          "length": "La dimensione dell'allegato supera la dimensione massima consentita."
        }
      },
      "helpers": {
        "date-short-view": {
          "today": "Oggi, {hour}"
        }
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Consulente } CLIENT {Interlocutore } other {}}{gender, select, MR {Sig. }  MME {Sig.ra } other {}}{lastname} {firstname}",
        "unknown-person": "Persona sconosciuta"
      },
      "inline-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "Non è possibile caricare allegati vuoti. Riprova con un file valido.",
          "length": "La dimensione dell'immagine online supera la dimensione massima consentita."
        }
      },
      "numeric-unit": {
        "billion": "G",
        "million": "M",
        "thousand": "K"
      },
      "pagination": "paginazione",
      "skip-link": "Navigazione (Esci dal menu)",
      "spellchecker": {
        "languages": {
          "de": "Tedesco",
          "en": "Inglese",
          "fr": "Francese",
          "nl": "Olandese",
          "ru": "Russo"
        },
        "message-info": {
          "error": {
            "unknown": "Si è verificato un errore."
          }
        }
      },
      "theme": {
        "criticalDueDateDelay": {
          "error": {
            "message": "Questo campo deve essere maggiore di In ritardo"
          }
        }
      },
      "treeview": {
        "button": {
          "list": {
            "label": "Mostra elenco dei risultati"
          },
          "search": {
            "label": "Cerca"
          },
          "select": {
            "default": {
              "label": "Seleziona"
            }
          },
          "tree": {
            "label": "Mostra l'albero completo"
          }
        },
        "message": {
          "noresult": "Nessun risultato di ricerca"
        }
      }
    },
    "side-panel": {
      "accessibility": {
        "buttons": {
          "close": {
            "title": "Chiudi"
          }
        },
        "contrast": {
          "enhanced": "Potenziati",
          "standard": "Standard"
        },
        "disparities": "Contrasti",
        "message-info": {
          "error": {
            "unknown": "Si è verificato un errore durante la modifica del contrasto di accessibilità, riprova più tardi."
          }
        },
        "title": "Accessibilità"
      },
      "burger": {
        "label": "Mostra / Nascondi il menu"
      },
      "hide-menu": "Nascondi il menu laterale",
      "logo": {
        "by": "di",
        "ti": "Interazioni affidabili",
        "worldline": "Worldline"
      },
      "logo-alt": "WL Trusted Interactions",
      "module": {
        "title": "Modulo"
      },
      "settings": {
        "myprofile": "Impostazioni",
        "name": "Preferenze",
        "preferences": "Preferenze"
      },
      "show-menu": "Mostra il menu laterale",
      "toggle-title": "Apri/Chiudi il menu"
    },
    "site-map": {
      "modules": "Moduli",
      "title": "Mappa del sito"
    },
    "topbar": {
      "burger": {
        "label": "Mostra / Nascondi il menu"
      },
      "interlocutors": {
        "myself": "Il mio account"
      },
      "message-info": {
        "log-out-confirmation": "Vuoi disconnetterti? Perderai i dati non salvati."
      },
      "module": {
        "administration": "Amministrazione",
        "interactions": "Messaggi",
        "profile": "Profilo",
        "projects": "Progetti"
      },
      "nav": {
        "label": "Barra multi modulo"
      },
      "organisation": {
        "name": "equensWorldline"
      },
      "settings": {
        "profile": "Impostazioni",
        "sign-out": "Disconnetti"
      }
    }
  };
});