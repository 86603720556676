define("lbp-cust-web-application/controllers/interlocutors/interlocutor/parameters/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    externalEmailAddress: Ember.computed.alias('model.externalEmailAddress'),
    hasBody: true,
    subscription: Ember.computed.alias('model.subscription'),
    intl: Ember.inject.service(),
    choiceObserver: Ember.on('init', Ember.observer('model.choice', function () {
      if (this.get('model')) {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name', {
          pageName: this.get('intl').t("interactions.parameters.title")
        });
      }
      this.set('choices', this.get('model.choice') ? true : false);
    })),
    actions: {
      choose: function choose(choice) {
        this.set('choices', choice);
      }
    }
  });
});