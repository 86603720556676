define("lbp-cust-web-application/templates/components/ti-web-shared/documents/document-file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Rq1uE1tX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"document-uploader\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn project-document-button-add\"],[12,\"aria-label\",[28,[[27,\"t\",[\"projects.project.documents.file.add.aria-label\"],[[\"document\",\"status\"],[[23,[\"projectPartDocument\",\"title\"]],[23,[\"statusText\"]]]]]]]],[9],[0,\"\\n    \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"textClass\",\"text\",\"iconClass\",\"srHidden\"],[\"project-add-text\",[27,\"t\",[\"projects.project.documents.file.add.title\"],null],\"project-file-upload\",true]]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"click\"]],[10],[0,\"\\n  \"],[1,[27,\"ti-web-shared/file-uploader\",null,[[\"id\",\"url\",\"onStartUpload\",\"onDidUpload\",\"onDidError\",\"allowedTypes\",\"maxSizeOfAttachment\"],[[23,[\"uploaderId\"]],[23,[\"urlUpload\"]],[27,\"action\",[[22,0,[]],\"uploadAttachmentOnStartUpload\"],null],[27,\"action\",[[22,0,[]],\"uploadAttachmentOnDidUpload\"],null],[27,\"action\",[[22,0,[]],\"uploadAttachmentDidError\"],null],[23,[\"allowedTypes\"]],[23,[\"maxSizeOfAttachment\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/documents/document-file-uploader.hbs"
    }
  });
});