define("lbp-cust-web-application/initializers/ticust-web-common/number-polyfill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* eslint-disable */

  function initialize() {
    Number.isInteger = Number.isInteger || function (value) {
      return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    };
    Number.prototype.isInteger = Number.prototype.isInteger || function () {
      return (this ^ 0) === +this;
    };
  }
  var _default = _exports.default = {
    name: 'ti-web-common/number-polyfill',
    initialize: initialize
  };
});