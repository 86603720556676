define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/label/color-select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HgYdGYgt",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[23,[\"dataItems\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\"],[12,\"id\",[22,1,[]]],[12,\"name\",[21,\"triggerId\"]],[12,\"value\",[22,1,[]]],[12,\"checked\",[27,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"selected\"]]],null],\"checked\"],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"selectionChanged\",[22,1,[]]],null]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n  \"],[7,\"label\"],[12,\"for\",[22,1,[]]],[12,\"class\",[28,[\"label-color \",[22,1,[]]]]],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"no-display\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"concat\",[\"interactions.label.popup-edit-component.fields.color.\",[22,1,[]]],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/label/color-select-component.hbs"
    }
  });
});