define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/inbenta/inbenta-iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OHDvcHIq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\"],[11,\"class\",\"inbentaIframe\"],[12,\"title\",[28,[[27,\"t\",[\"interactions.conversations.inbenta.iframe-title\"],null]]]],[11,\"id\",\"inbentaIframe\"],[12,\"src\",[28,[[21,\"inbentaUrl\"]]]],[11,\"style\",\"width:100%; border: 0; overflow: hidden;\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/inbenta/inbenta-iframe.hbs"
    }
  });
});