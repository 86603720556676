define("lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-project-bloc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8tRHcaLm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"project-bloc \",[21,\"blocClass\"]]]],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"aria-level\",\"3\"],[11,\"class\",\"project-title\"],[11,\"role\",\"heading\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/lbp-project-bloc.hbs"
    }
  });
});