define("lbp-cust-web-application/initializers/services/ti-web-shared/project-service", ["exports", "ti-web-shared/services/project-service", "ti-web-shared/mixins/toastMixin"], function (_exports, _projectService, _toastMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/services/project-service',
    // eslint-disable-line
    initialize: initialize
  };
  _projectService.default.reopen(_toastMixin.default, {
    // Services
    store: Ember.inject.service(),
    identityService: Ember.inject.service('identity-service'),
    logger: Ember.inject.service('logger-service'),
    _hasRoleForWebmail: Ember.computed.or('identityService.hasRoleConversationModuleAccess', 'identityService.hasRoleAccessToInteractions'),
    enrichProjectList: function enrichProjectList(projects, interlocutor) {
      var model = {
        projects: projects,
        conversations: this.getProjectConversations(projects, interlocutor),
        stats: this.getProjectStatistics(projects)
      };
      return Ember.RSVP.hash(model).then(function (model) {
        model.projects.forEach(function (project) {
          if (model.conversations && model.conversations[project.get('id')]) {
            project.set('conversation', model.conversations[project.get('id')]);
          }
          if (model.stats && model.stats[project.get('id')]) {
            project.set('stats', model.stats[project.get('id')]);
          }
        });
        return model.projects;
      });
    },
    getProjectConversations: function getProjectConversations(projects, interlocutor) {
      var _this = this;
      if (!this.get('_hasRoleForWebmail') || !projects || projects.length === 0) {
        return;
      }
      var projectIds = projects.map(function (project) {
        return project.get('id');
      });
      var payload = {
        projectIds: projectIds,
        owner: interlocutor.get('id')
      };
      return this.get('store').query('conversation', payload).then(function (response) {
        var data = response.reduce(function (accumulator, currentValue) {
          accumulator[currentValue.get('projectId')] = currentValue;
          return accumulator;
        }, {});
        return data;
      }).catch(function () {
        _this.get('logger').error('Cannot get project conversations');
      });
    },
    getProjectStatistics: function getProjectStatistics(projects) {
      var _this2 = this;
      var projectsStatistics = {};
      projects.forEach(function (project) {
        projectsStatistics[project.id] = _this2.getStatistics(project.id);
      });
      return Ember.RSVP.hash(projectsStatistics).catch(function (error) {
        _this2.showErrorAndLog('projects.list.message-info.error.unknown', 'An error appear during loading of projects statistics', error);
      });
    }
  });
});