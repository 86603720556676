define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/conversation-message-attachments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gOa130nu",
    "block": "{\"symbols\":[\"attachment\"],\"statements\":[[7,\"div\"],[11,\"class\",\"attachments-panel\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"attachments-panel__header\"],[11,\"role\",\"heading\"],[12,\"aria-level\",[21,\"titleLevel\"]],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"interactions.conversation-message-header.attachments.label\"],[[\"nb\"],[[23,[\"nbAttachments\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"attachments-panel__list container-fluid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row row-list\"],[9],[0,\"\\n      \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"attachments\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"ticust-web-module-interactions/conversation/conversation-message-attachment\",null,[[\"displayAttachmentId\",\"model\",\"message\",\"class\",\"fromMe\",\"contentType\"],[[23,[\"displayAttachmentId\"]],[22,1,[]],[23,[\"message\"]],\"attachment-inline\",[23,[\"fromMe\"]],[22,1,[\"headers\",\"Content-Type\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/conversation-message-attachments.hbs"
    }
  });
});