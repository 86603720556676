define("lbp-cust-web-application/templates/components/ti-web-shared/panels/results-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rE/eRfHo",
    "block": "{\"symbols\":[\"element\",\"index\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"hasError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"feedback-message negative\"],[9],[1,[27,\"t\",[\"shared.components.result-list.results.error\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"ti-web-shared/spinner/component-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"elements\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"elements\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"hasFooter\"]]],null,{\"statements\":[[0,\"        \"],[14,3,[[23,[\"list\"]],[22,1,[]],[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[14,3,[[22,1,[]],[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"feedback\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"empty-icon\"],[9],[0,\"\\n        \"],[1,[27,\"a11y/a11y-semantic-icon\",null,[[\"iconClass\"],[\"folder-empty-overall\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"empty-text\"],[9],[1,[27,\"t\",[[23,[\"noResultKey\"]]],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[4,\"if\",[[23,[\"hasFooter\"]]],null,{\"statements\":[[0,\"  \"],[14,3,[[23,[\"footer\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/panels/results-list.hbs"
    }
  });
});