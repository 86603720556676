define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/composer/conversation-modal-leave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sOtKwKei",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ti-web-shared/modals/modal-component\",null,[[\"modalTitleId\",\"modalTitle\",\"modalId\",\"isOpen\",\"confirm\",\"custom\",\"cancel\",\"showConfirmButton\",\"showCustomButton\",\"showCancelButton\",\"showTriggerButton\",\"confirmButtonDisabled\",\"confirmButtonIntl\",\"customButtonIntl\",\"cancelButtonIntl\",\"backToIntl\"],[[23,[\"modalTitleId\"]],[27,\"t\",[\"interactions.conversation.composer.modal.title\"],null],[23,[\"modalId\"]],[23,[\"isOpen\"]],[27,\"action\",[[22,0,[]],\"saveClicked\"],null],[27,\"action\",[[22,0,[]],\"closeModal\"],null],[27,\"action\",[[22,0,[]],\"cancelClicked\"],null],true,true,true,false,[23,[\"saveButtonDisabled\"]],\"interactions.conversation.composer.modal.buttons.save.value\",\"interactions.conversation.composer.modal.buttons.back.value\",\"interactions.conversation.composer.buttons.cancel.value\",[23,[\"backToIntl\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel-title-body panel-body-modal-title no-line-height\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"modal-body-text\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"interactions.conversation.composer.modal.body\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/composer/conversation-modal-leave.hbs"
    }
  });
});