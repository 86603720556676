define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation/lbp-conversation-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    lbpService: Ember.inject.service('lbp-service'),
    // Layout
    tagName: '',
    hasDraft: undefined,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._focusReturnButtonIfNoDraft();
    },
    _focusReturnButtonIfNoDraft: function _focusReturnButtonIfNoDraft() {
      if (!this.get('hasDraft') && this.get('lbpService.openConversationClick')) {
        var returnButton = document.querySelector('.lbp-panel-header-prev.lbp-panel-header-prev-icon');
        if (returnButton) {
          returnButton.focus();
        }
      }
      this.set('lbpService.openConversationClick', false);
    },
    // Actions
    answer: function answer() {
      throw new Error("Missing 'answer' handler");
    },
    back: function back() {
      throw new Error("Missing 'back' handler");
    },
    actions: {
      answer: function answer() {
        this.get('answer')();
      },
      back: function back() {
        this.get('back')();
      }
    }
  });
});