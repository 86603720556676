define("lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/conversation-message-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OC0Y1jMA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"conversation-message-body__text-base \",[27,\"if\",[[23,[\"isCampaign\"]],\"conversation-campaign\",\"\"],null],\"\\n  \",[27,\"if\",[[23,[\"displayFullBody\"]],\"conversation-message-body__text--size-long\",\"conversation-message-body__text--size-short\"],null],\"\\n  \",[27,\"if\",[[23,[\"messageIsTooLong\"]],\"conversation-message-body__text--isTooLong\"],null],\"\\n  conversation-message-body__inline\"]]],[12,\"aria-hidden\",[28,[[27,\"unless\",[[23,[\"displayFullBody\"]],\"true\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"hasHtmlContent\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"safeHtmlContent\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"pre\"],[9],[1,[21,\"textContent\"],false],[10],[0,\"\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ticust-web-module-interactions/conversation/conversation-message-body.hbs"
    }
  });
});