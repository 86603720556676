define("lbp-cust-web-application/initializers/controllers/ticust-web-module-interactions/interlocutors/interlocutor/conversations/conversation", ["exports", "ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/conversation", "lbp-cust-web-application/mixins/self-service", "lbp-cust-web-application/mixins/focus", "ember-uuid", "ti-web-shared/mixins/invalidate-session-mixin"], function (_exports, _conversation, _selfService, _focus, _emberUuid, _invalidateSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line
  // eslint-disable-line
  // eslint-disable-line

  var TABBABLE_ELEMENTS = 'a[href], area[href], input:not([disabled]), select:not([disabled]), ' + 'textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], ' + '*[contenteditable], span[role=heading][aria-level=2][tabindex=0], h1[tabindex=0]';
  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/controllers/interlocutors/interlocutor/conversations/conversation',
    // eslint-disable-line
    initialize: initialize
  };
  _conversation.default.reopen(_selfService.default, _focus.default, _invalidateSessionMixin.default, {
    // Services
    tagService: Ember.inject.service('tags'),
    lbpMessage: Ember.inject.service('lbp-message'),
    lbpService: Ember.inject.service('lbp-service'),
    identity: Ember.inject.service('identity-service'),
    paginationHelper: Ember.inject.service('pagination-helper'),
    tiLogger: Ember.inject.service('logger-service'),
    session: Ember.inject.service(),
    queryParams: ['pageSource'],
    hasDraft: Ember.computed('drafts', function () {
      return this.get('drafts').length > 0;
    }),
    isDraftConversation: Ember.computed.and('hasDraft', 'isPageSourceDraft'),
    queue: Ember.inject.service(),
    isPageSourceDraft: Ember.computed('pageSource', function () {
      return this.get('pageSource') === 'conversationDraft';
    }),
    displayConversationComposer: Ember.computed.and('identity.hasRoleConversationCreateOrAnswer', '_shouldOpenComposer', 'isNotBlacklisted'),
    isBody: true,
    _answerButtonClicked: false,
    _showConfirmDelete: false,
    showHistoryButton: false,
    showHistory: true,
    categorizePopupIsVisible: false,
    showErrors: false,
    // Character limit for editor
    maxlength: 100000,
    _shouldOpenComposer: Ember.computed.or('_answerButtonClicked', 'hasDraft'),
    isNotBlacklisted: Ember.computed.not('identity.isBlacklisted'),
    // Does not show the Self Service AI pop up
    _showSelfServiceModal: false,
    _selfServiceResponse: null,
    _selfServiceFoundTxt: '',
    selfServiceValidateAction: function selfServiceValidateAction() {},
    selfServiceCloseAction: function selfServiceCloseAction() {},
    // Enable answer only if there is no message which is in read only mode (header: X-TI-READONLY)
    // and if we came from a mine/recevied mail, not from a draft/send/trashed mail
    _enableAnswer: Ember.computed('messages', 'pageSource', function () {
      return (this.get('pageSource') === 'conversationMine' || this.get('pageSource') === 'search' || this.get('pageSource') === 'project') && !(this.get('messages.length') === 1 && this.get('messages.firstObject.status') === 'SENT') && this.get('messages.firstObject.conversation.status') !== 'DELETED' && this.get('messages').every(function (message) {
        return message.get('messageContent.headers.X-TI-Readonly.firstObject') !== '1';
      }) && this.get('isNotBlacklisted') && !this.get('displayAnswerOrCreateButton');
    }),
    _enableBack: Ember.computed('pageSource', function () {
      return !!this.get('pageSource');
    }),
    ccParticipants: undefined,
    // Return True if we can display Create Button, False for Answer Button
    displayAnswerOrCreateButton: Ember.computed('model.settings.convMonthDelay', 'model.settings.convNewMessageDelay', 'conversation.creationDate', 'conversation.lastMessageReceivedDate', function () {
      var monthMilli = 2629800000;
      var creationDate = new Date(this.get('conversation.creationDate'));
      var lastMessageDate = new Date(this.get('conversation.lastMessageReceivedDate'));
      var actualDate = new Date();
      return !(actualDate.getTime() - creationDate.getTime() <= this.get('model.settings.convMonthDelay') * monthMilli && actualDate.getTime() - lastMessageDate.getTime() <= this.get('model.settings.convNewMessageDelay') * monthMilli);
    }),
    isInbentaBypass: Ember.computed('model.settings.inbentaBypass', function () {
      return this.get('model.settings.inbentaBypass') === 'true';
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalDeleteId', 'popup-delete-conv-' + _emberUuid.default.v4());
      this.set('modalDeleteTitleId', 'popup-delete-conv-' + _emberUuid.default.v4());
    },
    selfServiceValidateMisc: function selfServiceValidateMisc() {
      this.send('cancelMessage');
      this.set('isSendingMessage', false);
      this.set('composerIsDirty', false);
      this.send('refreshModel');
    },
    createClicked: function createClicked() {
      var _this = this;
      this.get('tagService').sendClickTag({
        name: 'contacter::messagerie::reception_bouton_nouveau_message',
        type: 'navigation'
      });
      var interlocutor = this.get('interlocutorController.model');
      if (this.lbpService.get('isPads')) {
        var uriNewMsg = this.lbpService.get('uriNewMessage');
        this.tiLogger.info("PADS - New message button clicked, redirecting to ".concat(uriNewMsg));
        this._invalidateSession({
          route: uriNewMsg,
          type: 'external'
        }).then(function () {
          _this.get('session').invalidate('authenticator:lbp-token-auth');
        });
      } else if (this.get('isInbentaBypass')) {
        this.transitionToRoute('interlocutors.interlocutor.conversations.create', interlocutor);
      } else {
        this.transitionToRoute('interlocutors.interlocutor.conversations.inbenta', interlocutor);
      }
    },
    // Keep this override to manage the showModal parameter.
    // Avoid rendering the component if the draftPart has not changed.
    draftPart: Ember.computed('sendingMessage.messageContent', 'draft.messageContent', function () {
      return this.get('sendingMessage.messageContent') || this.get('draft.messageContent') || null;
    }),
    _addLbpHeaders: function _addLbpHeaders(messageContent, conversation, theme) {
      // Get last received message
      var lastReceivedMessage = this.get('messages') ? this.get('messages').find(function (m) {
        return 'RECEIVED' === m.get('status');
      }) : null;

      // Add headers on reply message
      return this.get('lbpMessage').addHeadersForFieldsReply(messageContent, conversation, theme, lastReceivedMessage);
    },
    messageIsEmpty: Ember.computed('_textHtml', function () {
      return !this.get('_textHtml') || this.get('_textHtml').split(' ').join('') === '<p></p>';
    }),
    isCharacterLimitExceeded: Ember.computed('_textHtml.length', function () {
      return this.get('_textHtml.length') > this.get('maxlength');
    }),
    displayMessageError: Ember.computed('showErrors', 'messageIsEmpty', function () {
      return this.get('showErrors') && (this.get('messageIsEmpty') || this.get('isCharacterLimitExceeded'));
    }),
    _focusTextEditor: function _focusTextEditor() {
      var element = document.getElementById('message-editor-reply');
      if (element) {
        element.focus();
      }
    },
    _saveChange: function _saveChange(action) {
      var _this2 = this;
      (true && !(action) && Ember.assert('Missing action parametter', action));
      return this.get('conversation').save().then(function () {
        if (action === 'categorize') {
          _this2.genericFocusAfterToast('conversation-action-bar-button', true, false);
        } else {
          _this2.genericFocusAfterToast('page-title', true, true);
        }
        _this2.showSuccess('interactions.conversation.message-info.info.' + action);
      }).catch(function (error) {
        _this2.get('conversation').rollbackAttributes();
        _this2.showErrorAndLog('interactions.conversation.message-info.error.' + action, "An error occured on '" + action + "' action.", error);
        return Ember.RSVP.reject();
      });
    },
    actions: {
      openAnswer: function openAnswer() {
        var _this3 = this;
        this.get('tagService').sendClickTag({
          name: 'contacter::messagerie::reception_bouton_repondre',
          type: 'navigation'
        });
        this.set('_answerButtonClicked', true);
        this.set('showHistoryButton', true);
        this.set('showHistory', false);
        Ember.run.later(this, function () {
          _this3.focus(document.querySelector('#message-editor-reply'));
        }, 500);
      },
      createClicked: function createClicked() {
        this.createClicked();
      },
      closeAnswer: function closeAnswer() {
        this.set('_answerButtonClicked', false);
        if (this.get('_transition')) {
          this.get('_transition').retry();
        }
      },
      showHistoryButton: function showHistoryButton(param) {
        this.set('showHistoryButton', param);
      },
      showHistory: function showHistory(param) {
        this.set('showHistory', param);
      },
      manageHistory: function manageHistory() {
        this.set('showHistoryButton', false);
        this.set('showHistory', true);
        Ember.run.later(this, function () {
          var mainPanel = Ember.$('#page-content-wrapper');
          var focusOnOpen = mainPanel.find('[name=focusOnOpen]').filter(':visible').first();
          if (!focusOnOpen || focusOnOpen.length === 0 || !focusOnOpen.focus) {
            focusOnOpen = mainPanel.find(TABBABLE_ELEMENTS).filter(':visible').first();
          }
          if (focusOnOpen && focusOnOpen.length > 0 && focusOnOpen.focus) {
            focusOnOpen.focus();
          }
        }, 500);
      },
      removeFromTrashPopup: function removeFromTrashPopup() {
        this.set('_showConfirmDelete', true);
      },
      deleteDraftMessage: function deleteDraftMessage() {
        var _this4 = this;
        this.get('store').query('conversation-message', {
          conversation: this.get('conversation.id'),
          status: 'DRAFT'
        }).then(function (messages) {
          if (messages.get('length') > 0) {
            var message = messages.get('firstObject');
            message.deleteRecord();
            message.save().then(function () {
              _this4.showSuccess('interactions.conversation.draft-info.info.delete');
            }).then(function () {
              var interlocutor = _this4.get('interlocutorController.model');
              _this4.transitionToRoute('interlocutors.interlocutor', interlocutor);
            }).catch(function (error) {
              _this4.showErrorAndLog('interactions.conversations.search.message-info.error.deleteDraft', 'Failed to delete the draft message', error);
            });
            return message;
          }
        });
      },
      removeFromTrash: function removeFromTrash() {
        var _this5 = this;
        this.get('conversation').set('status', 'TO_ARCHIVE');
        this._saveChange('removeFromTrash').then(function () {
          var interlocutor = _this5.get('interlocutorController.model');
          _this5.transitionToRoute('interlocutors.interlocutor', interlocutor);
        });
      },
      cancelRemoveFromTrash: function cancelRemoveFromTrash() {
        // run later or it does not work with the icon
        Ember.run.later(this, function () {
          return document.getElementById('conversation-action-bar-button').focus();
        });
        this.set('_showConfirmDelete', false);
      },
      categorize: function categorize() {
        // Send Tag
        this.get('tagService').sendPageTag({
          name: 'contacter::messagerie::popin_associer_dossier_personnel'
        });
        // Open Popup
        this.toggleProperty('categorizePopupIsVisible');
      },
      saveCategorize: function saveCategorize(labels) {
        if (labels.length === 0 && this.get('conversation.labels.length') === 0 || this.get('conversation.labels.length') === 1 && labels.length === 1 && this.get('conversation.labels.firstObject.name') === labels[0].get('name')) {
          var actionBarOpenButton = document.getElementById('conversation-action-bar-button');
          if (actionBarOpenButton) {
            actionBarOpenButton.focus();
          }
          return;
        }
        // Call parent saveCategorize
        this._super(labels);
      },
      cancelMessage: function cancelMessage() {
        var callback = this._super.bind(this);
        this.get('queue').push(callback, 'stop', 'message');
      },
      saveMessage: function saveMessage(messageContent) {
        var _this6 = this;
        var autoSaving = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        if (autoSaving && !this.get('isDirty')) {
          return;
        }
        this.set('showErrors', true);
        if (this.get('messageIsEmpty') || this.get('isCharacterLimitExceeded')) {
          this.get('_focusTextEditor')();
          return;
        }
        this.get('queue').push(function () {
          // Update the record
          var record = _this6.createOrGetDraftRecord();
          record.set('receiveDate', new Date());
          record.set('messageContent', messageContent);
          record.set('participants', _this6.get('draftParticipants')), window.toastr.options.onHidden = function () {
            _this6.get('_focusTextEditor')();
          };

          // Save
          _this6.set('isSendingMessage', true);
          _this6.set('sendingMessage', record);
          return record.save().then(function () {
            if (!autoSaving) {
              _this6.showSuccess('interactions.conversation.success.save');
            }
            // Reset composer
            _this6.set('sendingMessage', null);
            if (_this6.get('_transition')) {
              _this6.get('_transition').retry();
            }
          }).catch(function (error) {
            _this6.showErrorAndLog('interactions.conversation.errors.save', 'Failed to save the message', error);
          }).finally(function () {
            _this6.set('isSendingMessage', false);
            if (!autoSaving) {
              _this6.send('refreshModel');
              Ember.run.later(_this6, function () {
                var element = document.querySelector('.toast.toast-success');
                if (element) {
                  element.setAttribute('tabindex', '-1');
                  element.focus();
                }
              }, 450);
            }
          });
        }, autoSaving ? 'continue' : 'stop', 'message');
      },
      sendMessage: function sendMessage(messageContent) {
        var _this7 = this;
        // Check error
        this.set('showErrors', true);
        if (this.get('messageIsEmpty') || this.get('isCharacterLimitExceeded')) {
          return;
        }

        // Create the record
        var record = this.createOrGetDraftRecord();

        // Set LBP headers
        messageContent = this._addLbpHeaders(messageContent, record.get('conversation'), this.get('model.theme'));
        if (this.get('identity.legalGuardianIdPph')) {
          messageContent = this.get('identity').checkAndSetLegalGuardianSentence(messageContent);
        }
        record.set('interlocutor', this.get('model.interlocutorSelectedInHeaderBar.id'));
        record.set('status', 'SENT');
        record.set('receiveDate', new Date());
        record.set('messageContent', messageContent);
        record.set('participants', this.get('draftParticipants'));

        // Save
        this.set('isSendingMessage', true);
        this.set('sendingMessage', record);
        record.save().then(function () {
          window.toastr.options.onHidden = function () {
            var elements = document.querySelectorAll('.panel-body.conversation-main.panel-body-border-exact ' + '.conversation-message-list-line .conversation-message-header__container.hidden-xs');
            if (elements) {
              if (elements.length > 0) {
                var element = elements.item(elements.length - 1);
                element.focus();
              }
            }
          };
          _this7.showSuccess('interactions.conversation.success.send');
          // Reset composer
          _this7.set('sendingMessage', null);
        }).catch(function (error) {
          _this7.showErrorAndLog('interactions.conversation.errors.send', 'Failed to send the message', error);
        }).finally(function () {
          _this7.set('isSendingMessage', false);
          // Reset composer after sent
          _this7.set('isDirty', false);
          _this7.send('refreshModel');
        });
        // Update display of conversation
        this.set('showHistoryButton', false);
        this.set('showHistory', true);
      },
      back: function back() {
        this.get('paginationHelper').setConversationId(this.get('conversation.id'));
        var interlocutor = this.get('interlocutorController.model');
        var pageSource = this.get('pageSource');
        this.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', interlocutor, pageSource);
        this.set('lbpService.returnToSourcePageClick', true);
      },
      restore: function restore() {
        var _this8 = this;
        this.get('conversation').set('status', 'IN_PROGRESS');
        // Override LBP: Need to wait end of writing changes to the store before transitionToRoute
        this._saveChange('restore').then(function () {
          var interlocutor = _this8.get('interlocutorController.model');
          if (_this8.get('conversation.lastMessageStatus') === 'RECEIVED') {
            _this8.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', interlocutor, 'conversationMine');
          } else if (_this8.get('conversation.lastMessageStatus') === 'SENT') {
            _this8.transitionToRoute('interlocutors.interlocutor.conversations.sources.source', interlocutor, 'conversationSent');
          }
        });
      },
      delete: function _delete() {
        var _this9 = this;
        this.get('conversation').set('status', 'DELETED');
        this.set('isDeleting', true);
        this._saveChange('delete').then(function () {
          var interlocutor = _this9.get('interlocutorController.model');
          _this9.transitionToRoute('interlocutors.interlocutor', interlocutor);
        });
      },
      textHtmlChanged: function textHtmlChanged(textHtml) {
        this.set('_textHtml', textHtml);
      },
      focusClosePopup: function focusClosePopup() {
        var actionBarOpenButton = document.getElementById('conversation-action-bar-button');
        if (actionBarOpenButton) {
          actionBarOpenButton.focus();
        }
      }
    }
  });
});