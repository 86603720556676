define("lbp-cust-web-application/components/ti-web-shared/projects/lbp-project-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    projectReaderService: Ember.inject.service(),
    _isUnread: Ember.computed('projectPartDocuments.[]', 'reader', function () {
      var _this = this;
      var result = false;
      var searchIn = this.get('reader.type') === 'CLIENT' ? 'clientReaders' : 'advisorReaders';
      this.get('projectPartDocuments').forEach(function (p) {
        result = result || !p.get(searchIn).some(function (r) {
          return r === _this.get('reader.id');
        });
      });
      return result;
    }),
    actions: {
      onOpen: function onOpen() {
        var _this2 = this;
        if (!this.get('_hasUpdatedReadStatus')) {
          this.toggleProperty('_hasUpdatedReadStatus');
          var searchIn = this.get('reader.type') === 'CLIENT' ? 'clientReaders' : 'advisorReaders';
          this.get('projectPartDocuments').forEach(function (p) {
            if (!p.get(searchIn).some(function (r) {
              return r === _this2.get('reader.id');
            })) {
              return _this2.get('projectReaderService').markDocumentAsRead(p.get('id'), _this2.get('reader.id'));
            }
          });
        }
      }
    }
  });
});