define("lbp-cust-web-application/templates/components/ti-web-shared/projects/project-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0Q+sB6pE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"project-desc\"],[9],[0,\"\\n  \"],[1,[23,[\"project\",\"description\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/components/ti-web-shared/projects/project-description.hbs"
    }
  });
});