define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/side-menu/side-menu", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/side-menu/side-menu", "lbp-cust-web-application/templates/components/side-menu/side-menu", "lbp-cust-web-application/mixins/side-menu", "ember-uuid", "lodash"], function (_exports, _sideMenu, _sideMenu2, _sideMenu3, _emberUuid, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/side-menu/side-menu',
    // eslint-disable-line
    initialize: initialize
  };
  _sideMenu.default.reopen(_sideMenu3.default, {
    // Layout
    layout: _sideMenu2.default,
    // Services
    tagService: Ember.inject.service('tags'),
    store: Ember.inject.service(),
    identity: Ember.inject.service('identity-service'),
    // Data
    _backText: 'interactions.side-menu.module.label',
    _switchPopupTitle: 'interactions.side-menu.switch-module.popup.label',
    _switchPopupText: 'interactions.side-menu.switch-module.popup.message-info.info.confirm-route',
    _switchPopup: false,
    configSections: Ember.computed.oneWay('config.ticustWebModuleInteractions.listSections'),
    _projects: Ember.on('init', function () {
      var _this = this;
      if (this.get('identity.legalGuardianIdPph')) {
        // Legal Guardian (French: "Représentant Légal") does not have access to the projects
        return;
      }
      var interlocutorId = this.get('model.id');
      var payload = {
        interlocutor: interlocutorId
      };
      // Get opened projects
      return this.get('store').query('project', payload).then(function (projects) {
        _this.set('_hasProjects', projects.get('length') > 0);
      });
    }),
    _hasProjects: false,
    hasRigthToSwitchModule: Ember.computed('identity.hasRoleProjectModuleAccess', '_hasProjects', function () {
      return this.get('identity.hasRoleProjectModuleAccess') && this.get('_hasProjects');
    }),
    listSections: Ember.computed('labelsLines.[]', 'configSections', function () {
      var sections = _lodash.default.clone(this.get('configSections'));

      // Create label lines
      var labelLines = this.get('labelsLines') || [];
      labelLines.push({
        customClasses: 'menu-icon-label-add',
        label: 'interactions.side-menu.labels.add.label',
        link: {
          route: 'interlocutors.interlocutor.labels.settings',
          models: ['model.id'],
          params: {
            currentPage: '1',
            selectedSort: 'lastMessageReceivedDate,desc',
            searchText: '',
            search: 'true',
            isCreateLabel: 'true'
          }
        }
      });
      labelLines.push({
        customClasses: 'menu-icon-label-manage',
        label: 'interactions.side-menu.labels.manage.label',
        link: {
          route: 'interlocutors.interlocutor.labels.settings',
          models: ['model.id'],
          params: {
            currentPage: '1',
            selectedSort: 'lastMessageReceivedDate,desc',
            searchText: '',
            search: 'true'
          }
        }
      });

      // Add label lines
      sections.push({
        // No link, we never want the labels' section title to be generated as a link
        lines: labelLines,
        label: 'interactions.side-menu.main-folder',
        customClasses: 'main-folder '
      });

      // Create help lines
      sections.push({
        label: 'interactions.side-menu.help.label',
        customClasses: 'main-folder ',
        lines: [{
          customClasses: 'menu-icon-help',
          label: 'interactions.side-menu.help.get.label',
          link: {
            route: 'interlocutors.interlocutor.help',
            models: ['model.id']
          }
        }]
      });

      // Create plus lines
      sections.push({
        label: 'interactions.side-menu.switch-module.title',
        customClasses: 'main-folder main-folder-plus ',
        lines: [{
          customClasses: 'menu-icon-project project-icon-text',
          label: 'interactions.side-menu.switch-module.label',
          action: {
            name: 'switch',
            buttonId: this.get('switchPopupId')
          }
        }, {
          customClasses: 'menu-icon-parameters',
          label: 'interactions.side-menu.parameters.label',
          link: {
            route: 'interlocutors.interlocutor.parameters.notifications',
            models: ['model.id']
          }
        }]
      });
      return sections;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalId', 'popup-to-project-' + _emberUuid.default.v4());
      this.set('modalTitleId', 'popup-to-project-title-' + _emberUuid.default.v4());
      this.set('switchPopupId', _emberUuid.default.v4());
    },
    handleAction: function handleAction(action) {
      if (action === 'switch') {
        this.set('_switchPopup', true);
      }
    },
    _getLineForLabel: function _getLineForLabel(label) {
      return Ember.Object.create({
        id: label.get('id'),
        customClasses: 'label-color ' + label.get('colorId'),
        label: label.get('name'),
        title: label.get('title'),
        link: {
          route: 'interlocutors.interlocutor.conversations.labels.label',
          models: ['model.id', label.get('id')],
          params: {
            currentPage: '1',
            selectedSort: 'lastMessageReceivedDate,desc',
            searchText: '',
            search: 'true'
          }
        }
      });
    },
    _hookCountRequest: function _hookCountRequest(request) {
      request['isRead'] = false;
      return request;
    },
    actions: {
      routeToSwitchModule: function routeToSwitchModule() {
        this.get('tagService').sendClickTag({
          name: 'contacter::messagerie::acces_espace_echange_documents',
          type: 'navigation'
        });
        this.get('transitionToExternalRoute')('ticust-web-module-projects');
      },
      focusMainContentTitle: function focusMainContentTitle() {
        var mainContentTitle = document.querySelector('.page-content-main.conversations .header-title-with-button h2');
        if (mainContentTitle) {
          mainContentTitle.setAttribute('tabindex', '-1');
          mainContentTitle.focus();
        }
      }
    }
  });
});