define("lbp-cust-web-application/initializers/routes/ticust-web-module-interactions/interlocutors/interlocutor/conversations/abstractCreateConversation", ["exports", "ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/abstractCreateConversation", "lodash", "ember-cp-validations"], function (_exports, _abstractCreateConversation, _lodash, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  var Validations = (0, _emberCpValidations.buildValidations)({
    'selectedOption': {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });
  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/routes/interlocutors/interlocutor/conversations/abstractCreateConversation',
    // eslint-disable-line
    initialize: initialize
  };
  var KNOWN_FIELDS = ['ACCOUNT_NUMBER', 'CREDIT_NUMBER', 'PHONE_NUMBER'];
  _abstractCreateConversation.default.reopen({
    // Services
    identity: Ember.inject.service('identity-service'),
    lbpMessage: Ember.inject.service('lbp-message'),
    lbpService: Ember.inject.service('lbp-service'),
    store: Ember.inject.service(),
    _accountNumberDefinition: Ember.computed('identity.userAccounts.[]', function () {
      return this.get('identity.userAccounts').map(function (account) {
        return {
          text: account.type + ' ' + account.number,
          value: account.nature + '|' + account.number
        };
      });
    }),
    _creditNumberDefinition: Ember.computed('identity.userCreditAccounts.[]', function () {
      return this.get('identity.userCreditAccounts').map(function (account) {
        return {
          text: account.type + ' ' + account.number,
          value: account.number
        };
      });
    }),
    _phoneNumberDefinition: Ember.computed('identity.userPhones.[]', function () {
      return this.get('identity.userPhones').map(function (phone) {
        return {
          text: phone.slice(0, -3) + '***',
          value: phone
        };
      });
    }),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('showExitConfirmationModal', false);

      // Find draft message
      var bodyText = '';
      if (model.messages) {
        var draftMessage = model.messages.find(function (m) {
          return "DRAFT" === m.get('status');
        });
        if (draftMessage) {
          bodyText = draftMessage.get('messageContent.bodyTextHtmlParts.firstObject.body');
        }
      }
      controller.set('_textHtml', bodyText);
      if (this.get('identity.isBlacklisted')) {
        controller.set('showBlacklistedModal', true);
      }
      if (this.lbpService.get('isPads')) {
        this.set('controller.themeSelected', model.themes[0]);
      }
    },
    _getThemesPromise: function _getThemesPromise() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (queryParams.flow === 'PADS') {
        return Promise.all([this.createThemeFromQueryParams(queryParams)]).then(this._mapThemes.bind(this));
      }

      // Adapter will call LBP, for now we have a mock adapter.
      var payload = {
        type: 'client',
        code: this.get('identity.userTypo'),
        device: this.get('identity.userDeviceTheme'),
        enabled: true,
        valid: true,
        size: 50,
        sort: 'label'
      };
      return this.get('store').query('theme', payload).then(this._mapThemes.bind(this));
    },
    createThemeFromQueryParams: function createThemeFromQueryParams(queryParams) {
      var selectAccountMode = new Number(queryParams.selectAccountMode || 0);
      var selectLoanMode = new Number(queryParams.selectLoanMode || 0);
      var treatmentDelay = queryParams.treatmentDelay ? new Number(queryParams.treatmentDelay) : null;
      var disabledField = {
        enable: false
      };
      var additionalFields = [selectAccountMode > 0 ? {
        name: 'ACCOUNT_NUMBER',
        label: 'account',
        mandatory: selectAccountMode > 1
      } : disabledField, selectLoanMode > 0 ? {
        label: "creditField",
        name: "CREDIT_NUMBER",
        type: "list",
        mandatory: selectLoanMode > 1
      } : disabledField, treatmentDelay ? {
        label: "delayOfTreatment",
        mandatory: true,
        name: "delayOfTreatment",
        type: "INTEGER",
        dueDateDelay: treatmentDelay,
        enable: true
      } : disabledField];
      return this.get('store').createRecord('theme', {
        label: queryParams.themeLabel,
        key: queryParams.themeCode,
        id: queryParams.guidThemeAE,
        description: queryParams.themeLabelDisplayed,
        active: true,
        additionalFields: additionalFields
      });
    },
    _mapThemes: function _mapThemes(themes) {
      var _this = this;
      themes.forEach(function (theme) {
        if (theme.get('additionalFields')) {
          var additionalFields = theme.get('additionalFields').filter(function (field) {
            return KNOWN_FIELDS.includes(field.name);
          }).map(function (field) {
            return _this._mapFieldTheme(field);
          });
          theme.set('fields', additionalFields);
        }
      });
      return themes;
    },
    _mapFieldTheme: function _mapFieldTheme(field) {
      // Add options
      field.options = this._getOptions(field.name);
      field.type = 'list';

      // Create validation
      var object = Ember.Object;
      if (field.mandatory) {
        object = object.extend(Validations);
      }

      // Create object
      return object.create(Ember.getOwner(this).ownerInjection(), field);
    },
    _getOptions: function _getOptions(name) {
      switch (name) {
        case 'ACCOUNT_NUMBER':
          return this.get('_accountNumberDefinition');
        case 'CREDIT_NUMBER':
          return this.get('_creditNumberDefinition');
        case 'PHONE_NUMBER':
          return this.get('_phoneNumberDefinition');
      }
    },
    _addLbpHeaders: function _addLbpHeaders(messageContent) {
      return this.get('lbpMessage').addHeadersForFields(messageContent, this.get('controller.themeSelected'), this.get('controller.fieldsForTheme'));
    },
    _addThemeInputInCaracteristics: function _addThemeInputInCaracteristics() {
      var caracteristics = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var result = _lodash.default.clone(caracteristics);
      var fields = this.get('controller.fieldsForTheme');
      if (fields) {
        fields.forEach(function (field) {
          var value = field.selectedOption ? field.selectedOption.value : null;
          if (value) {
            result['theme-' + field.name] = value;
          } else {
            delete result['theme-' + field.name];
          }
        });
      }
      if (this.get('controller.themeSelected')) {
        result['theme-key'] = this.get('controller.themeSelected.key');
        result['theme-label'] = this.get('controller.themeSelected.label');
      }
      return result;
    },
    getSettingsPromise: function getSettingsPromise() {
      var _this2 = this;
      return this.getSettingsPromiseOverride().then(function (result) {
        result.iaIsEnabled = _this2.getSettingsPromiseLbp();
        return Ember.RSVP.hash(result);
      }).then(function (hash) {
        return hash;
      });
    },
    // Override settings to differenciate CS / STD
    getSettingsPromiseOverride: function getSettingsPromiseOverride() {
      var _this3 = this;
      var attachmentsMaxSizeKey = 'cust.module.interactions.newMessage.attachment.out.maxSize';
      var attachmentsMaxNumberKey = 'cust.module.interactions.newMessage.attachments.out.maxNumber';
      var maxSizeOfMessageKey = 'cust.module.interactions.newMessage.attachments.out.maxSize';
      var attachmentsAllowedTypesKey = 'cust.module.interactions.newMessage.attachments.allowedTypes';

      // LBP, use other settings if the user is LBPCS
      if (this.get('identity.isLbpCs')) {
        attachmentsMaxSizeKey = 'cust.module.interactions.newMessageCs.attachments.maxSize';
        attachmentsMaxNumberKey = 'cust.module.interactions.newMessageCs.attachments.maxNumber';
        attachmentsAllowedTypesKey = 'cust.module.interactions.newMessageCs.attachments.allowedTypes';
      }
      var interlocutorId = this.modelFor('interlocutors.interlocutor').get('id');
      var getSetting = function getSetting(type, id, key) {
        return _this3.get('apiSettings').getSettingByKey(type, id, key);
      };
      return Ember.RSVP.hash({
        attachmentsMaxSize: getSetting('interlocutor', interlocutorId, attachmentsMaxSizeKey),
        attachmentsMaxNumber: getSetting('interlocutor', interlocutorId, attachmentsMaxNumberKey),
        attachmentsAllowedTypes: getSetting('interlocutor', interlocutorId, attachmentsAllowedTypesKey),
        maxSizeOfMessage: getSetting('interlocutor', interlocutorId, maxSizeOfMessageKey)
      }).then(function (settings) {
        var attachmentMaxSize = settings.attachmentsMaxSize.get('settingValue');
        var attachmentsMaxNumber = settings.attachmentsMaxNumber.get('settingValue');
        var attachmentsAllowedTypes = settings.attachmentsAllowedTypes.get('settingValue');
        var maxSizeOfMessage = settings.maxSizeOfMessage.get('settingValue');
        return {
          attachmentMaxSize: attachmentMaxSize,
          attachmentsMaxNumber: attachmentsMaxNumber,
          attachmentsAllowedTypes: attachmentsAllowedTypes,
          maxSizeOfMessage: maxSizeOfMessage,
          customerAnalysisEnabled: false
        };
      });
    },
    getSettingsPromiseLbp: function getSettingsPromiseLbp() {
      var _this4 = this;
      var iaIsEnabledKey = 'cust.module.interactions.newMessage.ia.isEnabled';
      var interlocutorId = this.modelFor('interlocutors.interlocutor').get('id');
      var getSetting = function getSetting(type, id, key) {
        return _this4.get('apiSettings').getSettingByKey(type, id, key);
      };
      return Ember.RSVP.hash({
        iaIsEnabled: getSetting('interlocutor', interlocutorId, iaIsEnabledKey)
      }).then(function (settings) {
        return settings.iaIsEnabled.get('settingValue');
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.controller.set('showErrors.theme', false);
        if (this.get('controller.isDirty') && !this.get('controller.showExitConfirmationModal')) {
          this.set('controller.showExitConfirmationModal', true);
          this.set('controller._transition', transition);
          transition.abort();
          return;
        }
        this.set('controller._transition', undefined);
        this._super(transition);
      }
    }
  });
});