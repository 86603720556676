define("lbp-cust-web-application/components/help-page", ["exports", "lbp-cust-web-application/sanitizers/help"], function (_exports, _help) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HelpPage = Ember.Component.extend({
    tagName: '',
    defaultOptions: _help.default,
    intl: Ember.inject.service(),
    init: function init() {
      this._super();
      if (this.get('moduleName') === 'projects') {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name.project', {
          pageName: this.get('intl').t("interactions.help.title")
        });
      } else {
        document.getElementById("dynamicTitle").innerText = this.get('intl').t('page.name', {
          pageName: this.get('intl').t("interactions.help.title")
        });
      }
    }
  });
  HelpPage.reopenClass({
    positionalParams: ['text', 'moduleName']
  });
  var _default = _exports.default = HelpPage;
});