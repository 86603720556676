define("lbp-cust-web-application/translations/fr-fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "administration.customers.attachments.types.application-docx": "DOCX",
    "administration.customers.attachments.types.application-ms-excel": "XLS",
    "administration.customers.attachments.types.application-ms-word": "DOC & DOT",
    "administration.customers.attachments.types.application-ppt": "PPT",
    "administration.customers.attachments.types.application-rtf": "RTF",
    "auth.login.accounts": "Comptes bancaires type|nature|numéro séparé par des virgules",
    "auth.login.callingCode": "Code Appelant",
    "auth.login.creditAccounts": "Comptes de crédit type|nature|numéro séparé par des virgules",
    "auth.login.financialCenter": "Centre financier",
    "auth.login.phones": "Téléphones",
    "auth.login.returnUrl": "Url de redirection",
    "auth.login.typo": "Code typologie client",
    "auth.logout.text": "Vous avez été déconnecté correctement.",
    "auth.logout.title": "Succès",
    "auth.sso-error.text": "Votre session a expiré pour ce module ou a rencontré une erreur, veuillez ouvrir de nouveau cette page depuis votre espace Banque en ligne.",
    "auth.sso-error.title": "Une erreur est survenue",
    "authentication": {
      "error": {
        "AUTHENTICATION_FAILED": "L'authentification a échoué",
        "BAD_CREDENTIALS": "Erreur: nom d'utilisateur ou mot de passe incorrect",
        "UNKNOWN": "Une erreur inconnue s'est produite. Veuillez réessayer ultérieurement"
      },
      "login": {
        "company": "Organisation",
        "forgot": "J'ai perdu mes données de connexion",
        "message-info": {
          "error": {
            "invalid-form": "Au moins un champ du formulaire n'est pas rempli.",
            "invalid-input": {
              "company": "L'organisation est obligatoire.",
              "password": "Le mot de passe est obligatoire.",
              "username": "Le nom d'utilisateur est obligatoire."
            }
          }
        },
        "password": "Mot de passe",
        "title": "Identification utilisateur",
        "username": "Nom d'utilisateur",
        "valid": "Me connecter"
      },
      "prospect": {
        "create": {
          "company": "Organisation",
          "email": "Adresse électronique",
          "firstName": "Prénom",
          "lastName": "Nom de famille",
          "link": "Rejoignez-nous",
          "user": {
            "password": "Mot de passe",
            "title": "Créer un mot de passe"
          }
        },
        "message": {
          "save": {
            "error": "Une erreur s'est produite lors de la sauvegarde du prospect. Veuillez réessayer plus tard.",
            "has-no-company": "Cette organisation n'existe pas",
            "info": "Le prospect a été enregistré.",
            "user": "L'utilisateur a été créé.",
            "wrong-email": "Cet e-mail est déjà utilisé. Veuillez en utiliser un autre."
          },
          "validation": {
            "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
          }
        },
        "new": {
          "buttons": {
            "cancel": "Annuler",
            "confirm": "Confirmer",
            "create": "Valider"
          }
        },
        "title": "Nouvel utilisateur"
      },
      "pwdreinit": {
        "cancel": "Annuler",
        "email": "Email",
        "header": "Veuillez saisir votre adresse email pour recevoir le lien de réinitialisation",
        "message-info": {
          "error": {
            "invalid-form": "Le formulaire est mal remplis.",
            "invalid-input": {
              "email": "L'email saisie est incorrecte."
            }
          },
          "info": {
            "success": "Si un compte est associé à l'adresse '{email}', vous recevrez un courriel contenant un lien pour réinitialiser votre mot de passe."
          }
        },
        "title": "Réinitialisation du mot de passe",
        "validate": "Valider"
      },
      "pwdrenew": {
        "email": "Email",
        "firstnewpassword": "Veuillez saisir votre nouveau mot de passe",
        "message-info": {
          "error": {
            "echec": "Votre mot de passe n'a pas pu être modifié.",
            "invalid-form": "Le formulaire est mal rempli. Veuillez vérifier les champs.",
            "invalid-input": {
              "firstPassword": "Le premier mot de passe n'est pas rempli.",
              "secondPassword": "Le second mot de passe n'est pas rempli."
            }
          },
          "info": {
            "success": "Votre mot de passe a été correctement changé."
          }
        },
        "password": "Mot de passe",
        "secondnewpassword": "Veuillez confirmer votre nouveau mot de passe",
        "title": "Changement de mot de passe",
        "validate": "Valider"
      }
    },
    "authentication.login.das": "Votre DAS",
    "authentication.login.legal-guardian": "Identifiant du représentant légal",
    "authentication.login.legal-guardian-date-take-office": "La date de la prise de fonction du représentant légal",
    "authentication.login.username": "Le client à utiliser",
    "common": {
      "application": {
        "title": "Trusted Interactions"
      },
      "file-upload": {
        "error": {
          "addFile": "Une erreur est apparue, la pièce jointe n'a pas été ajoutée. Veuillez réessayer ultérieurement.",
          "contentType": "Le type de la pièce jointe n'est pas autorisé.",
          "length": "La taille de la pièce jointe est supérieure à la taille maximale autorisée."
        }
      },
      "filter": {
        "datePrefix": "Date",
        "dateTimeLocale": "fr",
        "hide": "Masquer",
        "lessCriteria": "Voir moins de critères",
        "message-info": {
          "error": {
            "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
          }
        },
        "moreCriteria": "Voir plus de critères",
        "preset": "Prédéfinis :",
        "reset": "Réinitialiser les critères",
        "search": "Lancer la recherche",
        "show": "Afficher"
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Conseiller } CLIENT {Interlocuteur } other {}}{gender, select, MR {Mr }  MME {Mme } other {}}{lastname} {firstname}",
        "unknown-person": "Personne inconnue"
      },
      "message-info": {
        "error": {
          "403": "Vous n'avez pas les droits pour voir cette page.",
          "unknown": "Une erreur est apparue au chargement de la page. Veuillez réessayer ultérieurement."
        }
      },
      "notification": {
        "CUST_RESET_PASSWORD": {
          "label": "Changement de mot de passe",
          "variables": {
            "URL_LINK": "URL_LIEN"
          }
        },
        "LOWER_CONFIDENTIALITY_ACCEPTED": {
          "label": "Acceptation abaissement confidentialité Conversation",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "CIVILITE_INTERLOCUTEUR",
            "INTERLOCUTOR_FIRSTNAME": "PRENOM_INTERLOCUTEUR",
            "INTERLOCUTOR_LASTNAME": "NOM_INTERLOCUTEUR",
            "INTERLOCUTOR_NATURE": "NATURE_INTERLOCUTEUR"
          }
        },
        "LOWER_CONFIDENTIALITY_REFUSED": {
          "label": "Refus abaissement confidentialité Conversation",
          "variables": {
            "INTERLOCUTOR_CIVILITY": "CIVILITE_INTERLOCUTEUR",
            "INTERLOCUTOR_FIRSTNAME": "PRENOM_INTERLOCUTEUR",
            "INTERLOCUTOR_LASTNAME": "NOM_INTERLOCUTEUR",
            "INTERLOCUTOR_NATURE": "NATURE_INTERLOCUTEUR"
          }
        },
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": {
          "label": "Nouveau message Interlocuteur reçu",
          "variables": {
            "CONVERSATION_SUBJECT": "OBJET_CONVERSATION",
            "MESSAGE_EXTRACT": "EXTRAIT_MESSAGE",
            "URL_LINK": "URL_LIEN"
          }
        }
      },
      "page-error-message": "Une erreur inconnue est survenue. Merci de réessayer plus tard ou de contacter le service de support",
      "pagination-component": {
        "currentPage": "Page actuelle",
        "firstPage": "Première page",
        "lastPage": "Dernière page",
        "nextPage": "Page suivante",
        "page": "Page",
        "previousPage": "Page précédente"
      },
      "panel-collapse": {
        "close": "Replier",
        "open": "Déplier",
        "title-default": "Erreur titre du panel manquant"
      },
      "result-list": {
        "action": {
          "show-grid": "Affichage en vignettes",
          "show-list": "Affichage en lignes"
        },
        "results": {
          "error": "Une erreur est survenue pendant le chargement. Veuillez réessayer ultérieurement.",
          "none": "Aucun résultat disponible"
        },
        "sort": {
          "by": "Trier par"
        }
      },
      "selectors": {
        "actor-selector": {
          "error": "Une erreur est apparue pendant la recherche.",
          "loading": "Chargement en cours...",
          "no-results": "Aucun destinataire ne correspond à cette recherche",
          "required": "Un destinataire doit être sélectionné"
        },
        "theme-selector": {
          "error": "Une erreur est apparue pendant la recherche.",
          "loading": "Chargement en cours...",
          "no-results": "Aucun thème ne correspond à cette recherche",
          "required": "Un thème doit être sélectionné"
        }
      },
      "sidemenu": {
        "label": "Menu",
        "minimize": "Réduire le menu",
        "pulldown": "Dérouler le menu",
        "search": "Rechercher"
      },
      "spellchecker": {
        "languages": {
          "en": "Anglais",
          "fr": "Français"
        },
        "message-info": {
          "error": {
            "unknown": "Une erreur est apparue."
          }
        }
      },
      "variables": {
        "notificationCommon": {
          "EVENT_DATE": "DATE_EVT",
          "RECIPIENT_CIVILITY": "CIVILITE_DESTINATAIRE",
          "RECIPIENT_FIRSTNAME": "PRENOM_DESTINATAIRE",
          "RECIPIENT_LASTNAME": "NOM_DESTINATAIRE"
        }
      }
    },
    "common-projects": {
      "closed": "Projet Fermé",
      "components": {
        "annex-area-button": {
          "openConversation": {
            "buttonLabel": "Voir la conversation",
            "title": "Conversation"
          }
        },
        "audit-component": {
          "results": {
            "error": "Une erreur de chargement est survenue",
            "loading": "Chargement en cours..."
          },
          "table": {
            "no-result": "Aucun résultat"
          }
        },
        "buttons": {
          "close": "Fermer"
        },
        "file-uploader-label": "Sauvegarder",
        "file-uploading": "Sauvegarde en cours ...",
        "header": "Historique",
        "project-nature-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement des résultats...",
          "no-results": "Aucune entité correspond à ce terme",
          "required": "Vous devez sélectionner une entité"
        },
        "table": {
          "action": "Action",
          "date": "Date",
          "newValue": "Nouvelle valeur",
          "no-result": "Aucun résultat",
          "oldValue": "Ancienne valeur",
          "user": "Utilisateur"
        }
      },
      "document": {
        "audit": {
          "action": {
            "fileInvalid": "Marqué comme invalide",
            "fileValid": "Marqué comme valide",
            "init": "Initialisation",
            "status": "Statut changé",
            "uploaded": "Dépôt"
          },
          "button": {
            "close": "Fermer"
          },
          "column": {
            "action": "Action",
            "actor": "Conseiller",
            "date": "Date",
            "detail": "Détail",
            "type": "Type"
          },
          "userType": {
            "ADVISER": "Conseiller",
            "CLIENT": "Client"
          }
        },
        "button": {
          "add": "Ajouter un nouveau fichier",
          "audit": "Voir l'historique du document",
          "cancel": "Annuler",
          "copy": "Ajouter un fichier à partir d'un fichier existant",
          "create": {
            "copy": "Copier un fichier",
            "new": "Document vide",
            "upload": "Dépôt d’un fichier"
          },
          "download": "Télécharger tous les fichiers du document",
          "edit": {
            "show": "Editer"
          },
          "hideButtons": "Masquer tous les boutons pour le document et ses fichiers.",
          "info": {
            "hide": "Cacher les informations supplémentaires du document",
            "show": "Voir plus d'informations sur le document"
          },
          "markAsNotNeeded": "Déjà présent",
          "markAsNotValid": "Marquer le document comme Non Conforme",
          "mergeFiles": "Merge files",
          "save": "Valider",
          "showButtons": "Afficher tous les boutons pour le document et ses fichiers.",
          "undoAsNotNeeded": "Annuler déjà présent",
          "upload": "Ajouter un fichier"
        },
        "create": {
          "new": {
            "title": "Nouveau Document"
          },
          "title": "Ajouter un nouveau document"
        },
        "fields": {
          "description": "Description",
          "nature": "Nature",
          "providerType": "Fournisseur",
          "signatureRequired": "Signature requise",
          "title": "Nom"
        },
        "message-info": {
          "error": {
            "duplicate-title": "Le titre du document existe déjà.",
            "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer.",
            "markAsNotNeeded": "Une erreur s'est produite lors de la mise à jour du document en tant que document déjà présent, veuillez réessayer plus tard.",
            "markAsNotValid": "Une erreur est apparue, le document n'a pas été marqué comme Non Conforme. Veuillez réessayer ultérieurement.",
            "markAsValid": "Une erreur est apparue, le document n'a pas été marqué comme Conforme. Veuillez réessayer ultérieurement.",
            "save": "Une erreur est apparue pendant la sauvegarde du document. Veuillez réessayer ultérieurement.",
            "undoNotNeeded": "Une erreur s'est produite lors du retour à l'état Déjà présent, veuillez réessayer plus tard."
          },
          "info": {
            "markAsNotNeeded": "Le document a été marqué comme étant déjà présent.",
            "markAsNotValid": "Le document a été marqué comme Non Conforme",
            "markAsValid": "Le document a été marqué comme Conforme",
            "onlyAdviserAllowed": "* Seul le document avec le fournisseur du conseiller peut être défini comme signature requise",
            "save": "Le document a été sauvegardé.",
            "undoNotNeeded": "Le document Statut Déjà Présent a été annulé."
          }
        },
        "nature": {
          "ADVERTISEMENT": "Publicitaire",
          "BANK_STATEMENT": "Relevé bancaire",
          "BIRTH_CERTIFICATE": "Acte de naissance",
          "COMPROMISE_SALE": "Compromis de vente",
          "IBAN": "IBAN",
          "IDENTIFICATION": "Pièce d'identité",
          "INSURANCE": "Assurance",
          "LOAN_OFFER": "Offre de prêt",
          "PAYSLIP": "Fiche de paie",
          "POLICE_RECORD": "Casier judiciaire",
          "TAX_NOTICE": "Avis d'imposition",
          "UNKNOWN": "Inconnue",
          "label": "Nature : "
        },
        "providerType": {
          "ACTOR": "Conseiller",
          "INTERLOCUTOR": "Client",
          "UNKNOWN": "Inconnu",
          "label": "Fournisseur : "
        },
        "refusedReason": {
          "BLURRY": "Le document est flou.",
          "ERROR": "Le document est hors sujet.",
          "ILLEGIBLE": "Le document est illisible.",
          "label": "Motif du refus :"
        },
        "status": {
          "KO": "Non conforme",
          "NOT_NEEDED": "Déjà présent",
          "OK": "Conforme",
          "TO_ACKNOWLEDGE": "A valider",
          "TO_UPLOAD": "Nouveau",
          "UPLOADED": "Téléchargé"
        },
        "statusMessage": {
          "KO": "Ce document a été marqué comme Non Conforme. Veuillez soumettre un autre fichier s'il vous plait.",
          "NOT_NEEDED": "Ce document est déjà présent.",
          "TO_ACKNOWLEDGE": "Ce document doit être validé.",
          "TO_UPLOAD": "Ce document doit être déposé."
        },
        "type": {
          "FREE": "Libre",
          "STRUCTURED": "Structuré",
          "UNKNOWN": "Inconnu",
          "label": "Type de document : "
        }
      },
      "file": {
        "button": {
          "delete": "Supprimer le fichier",
          "download": "Télécharger le fichier",
          "markAsNotValid": "Marquer le fichier comme Non Conforme",
          "markAsValid": "Marquer le fichier comme Conforme",
          "preview": "Prévisualiser le fichier",
          "sign": "Signez ce fichier"
        },
        "help": {
          "signature-required": "Le dossier doit être signé",
          "signed": "Le dossier est déjà signé"
        },
        "message-info": {
          "error": {
            "delete": "Une erreur est apparue, le fichier n'a pas été supprimé. Veuillez réessayer ultérieurement.",
            "get-e-signiture-url": "Une erreur s'est produite lors de l'obtention de l'URL pour signer le fichier. Veuillez réessayer ultérieurement.",
            "mark-as-not-valid": "Une erreur est apparue, le fichier n'a pas été marqué comme Non Conforme. Veuillez réessayer ultérieurement.",
            "mark-as-valid": "Une erreur est apparue, le fichier n'a pas été marqué comme Conforme. Veuillez réessayer ultérieurement."
          },
          "info": {
            "delete": "Le fichier a été supprimé.",
            "mark-as-not-valid": "Le fichier a été marqué comme Non Conforme.",
            "mark-as-valid": "Le fichier a été marqué comme Conforme.",
            "pop-up": {
              "delete": "Voulez-vous vraiment supprimer ce fichier ?"
            }
          }
        },
        "status": {
          "KO": "Invalide",
          "OK": "Valide",
          "TO_SIGN": "Pour signer",
          "UPLOADED": "En validation"
        }
      },
      "file-upload": {
        "error": {
          "contentType": "Ce type de fichier n'est pas autorisé."
        },
        "info": {
          "uploadSucess": "Le fichier a été téléchargé."
        }
      },
      "folder": "Dossier",
      "list": {
        "tabs": {
          "personal": "Personnels",
          "shared": "Partagés"
        },
        "title": "Projets de {interlocutorName}"
      },
      "modal-copy": {
        "buttons": {
          "cancel": "Annuler",
          "copy": "Copier"
        },
        "document": "Sélectionner le document où copier",
        "file": "Choisir le fichier à copier",
        "label": {
          "search": "Rechercher"
        },
        "message-info": {
          "error": {
            "post-400": "Une erreur est survenue lors de la copie du fichier, veuillez vérifier le statut du document ou que vous ne possédez pas un fichier portant ce nom.",
            "unknown": "Une erreur est survenue lors de la copie du fichier. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "{nb, plural, =1 {Le fichier copié a été enregistré.} other {Les fichiers copiés ont été enregistrés.}}",
            "saveAttachment": "Le fichier copié a été enregistré.",
            "uploading": "Le fichier est en cours de téléchargement..."
          }
        }
      },
      "modal-merge": {
        "buttons": {
          "cancel": "Annuler",
          "confirm": "Confirmer",
          "move-down": "Descendre",
          "move-up": "Monter",
          "preview": "Prévisualiser"
        },
        "filename": "Nom du fichier de destination : ",
        "header": "Fusion de fichiers",
        "list": "Liste de fichiers à fusionner : ",
        "message-info": {
          "error": {
            "generic": "Une erreur est produite lors de la fusion du fichier. Veuillez réessayer ultérieurement."
          },
          "info": {
            "info": "Certains fichiers peuvent ne pas être fusionnés.",
            "loading": "Le fichier est en cours de fusion, veuillez patienter...",
            "saved": "Le fichier a été fusionné et sauvegardé."
          },
          "invalid-input": {
            "filename-duplicate": "Le nom de fichier existe déjà dans le document.",
            "filename-empty": "Le nom de fichier ne peut pas être vide.",
            "filename-long": "Le nom du fichier est trop long.",
            "list-empty": "Il faut sélectionner au moins deux fichiers.",
            "list-too-large": "Le poids de tous les fichiers est trop élevé."
          }
        }
      },
      "modal-share": {
        "buttons": {
          "cancel": "Annuler",
          "save": "Sauvegarder"
        },
        "message-info": {
          "error": {
            "loading": "Une erreur est apparue pendant le chargement des interlocuteurs de confiance. Veuillez réessayer ultérieurement.",
            "save": "Une erreur est apparue, le partage n'a pas été enregistré. Veuillez réessayer ultérieurement."
          },
          "info": {
            "empty-result": "Vous ne possédez pas d'interlocuteur de confiance.",
            "save": "Les informations de partage ont été sauvegardées."
          }
        },
        "placeholder": "Droits",
        "title": "Modification des droits du projet"
      },
      "modal-upload": {
        "message-info": {
          "error": {
            "failed-due-bad-request": "Une erreur est apparue pendant le téléchargement du fichier. s'il vous plaît vérifier la taille ou le type du fichier.",
            "failed-to-upload": "Une erreur est apparue pendant le téléchargement du fichier. Veuillez réessayer ultérieurement.",
            "post-400": "Une erreur est survenue lors du télechargement du fichier, veuillez vérifier le statut du document ou que vous ne possédez pas un fichier portant ce nom.",
            "unknown": "Une erreur est survenue lors du télechargement du fichier. Veuillez réessayer ultérieurement.",
            "wrong-format": "Le format d'un fichier pour lequel une signature est requise ne peut être qu'un PDF"
          },
          "info": {
            "file-uploading": "Sauvegarde en cours ...",
            "save": "Le fichier a été téléchargé."
          }
        }
      },
      "page-error-message": "Une erreur inconnue est survenue. Merci de réessayer plus tard ou de contacter le service de support",
      "preview-component": {
        "buttons": {
          "close": "Fermer"
        },
        "error": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
        "loading": "Chargement en cours...",
        "title": {
          "filedBy": "Déposé par :",
          "filedOn": "Déposé le :"
        }
      },
      "project": {
        "assignation": "Ce projet est géré par : ",
        "audit": {
          "action": {
            "CREATE": "Création du projet",
            "SHARED_INTERLOCUTOR_ADD": "Ajout de l'interlocuteur {interlocutorName} en {newProfileName}",
            "SHARED_INTERLOCUTOR_REMOVE": "Retrait de l'interlocuteur {interlocutorName}",
            "SHARED_INTERLOCUTOR_UPDATE": "Modification de l'interlocuteur {interlocutorName} de {previousProfileName} à {newProfileName}",
            "STATUS_CLOSED": "Clôture du projet",
            "STATUS_REOPENED": "Réouverture du projet",
            "UPDATE": "Mise à jour du projet",
            "auditToAdviser": "Envoi de la notification au conseiller {receiverName}",
            "auditToCustomer": "Envoi d'un avis au client {receiverName}"
          },
          "button": {
            "close": "Fermer"
          },
          "column": {
            "action": "Action",
            "date": "Date",
            "detail": "Détail",
            "sender": "Auteur"
          }
        },
        "button": {
          "audit": "Voir l'historique de ce projet",
          "back": "Revenir à la liste des projets",
          "close": "Clore le projet",
          "download": "Télécharger le projet",
          "edit": "Editer le projet",
          "hideInformations": "Réduire les informations",
          "notify-to-adviser": "Informer mon Conseiller que mon projet a été mis à jour",
          "notify-to-customer": "Informer mon Client que son projet a été mis à jour",
          "open": "Ouvrir le projet",
          "openConversation": "Ouvrir la conversation correspondante",
          "reopen": "Réouvrir le projet",
          "share": "Gérer la publication du projet",
          "showInformations": "Voir plus d'informations"
        },
        "closedDate": "Fermé le",
        "createdBy": "Par",
        "creationDate": "Créé le",
        "finishDate": "Fin prévisionnelle au",
        "lastUpdateDate": "Modifié le {date} par {name}",
        "message-error": {
          "download": "Une erreur est apparue, le projet n'a pas été téléchargé. Veuillez réessayer ultérieurement."
        },
        "message-info": {
          "error": {
            "LIMITED_CLICK_FOR_ADVISER": "Vous avez déjà notifié votre client il y a moins de {limit, plural, =1 {# heure} other {# heures}}.",
            "LIMITED_CLICK_FOR_CUSTOMER": "Vous avez déjà notifié votre conseiller il y a moins de {limit, plural, =1 {# heure} other {# heures}}.",
            "close": "Une erreur est apparue, le fichier n'a été pas clos. Veuillez réessayer ultérieurement.",
            "reopen": "Une erreur est apparue, le fichier n'a pas été ré-ouvert. Veuillez réessayer ultérieurement.",
            "unknown": "Une erreur s'est produite lors de l'envoi de la notification, veuillez réessayer plus tard."
          },
          "info": {
            "close": "Le projet a été clos.",
            "notify-update": "La notification a été envoyée.",
            "pop-up": {
              "close": "Voulez-vous vraiment clore ce projet ?",
              "reopen": "Voulez-vous vraiment ré-ouvrir ce projet ?"
            },
            "reopen": "Le projet a été ré-ouvert."
          }
        },
        "new": "Nouveau",
        "reopenDate": "Réouvert le",
        "shared": {
          "byMe": "Ce projet est actuellement publié à {to} avec le profil : ",
          "not": "Ce projet n'est actuellement pas publié à d'autres clients.",
          "right": {
            "FULL_ACCESS": "Accès total",
            "NO_ACCESS": "Aucun accès",
            "READ_ONLY": "Lecture seule"
          },
          "toMe": "Ce projet a été publié par {from} avec le profil : "
        },
        "statistics": {
          "messages": {
            "KO": "{nb, plural, =1 {# document rejeté} other {# documents rejetés}}",
            "OK": "{nb, plural, =1 {# document approuvé} other {# documents approuvés}}",
            "TO_ACKNOWLEDGE": "{nb, plural, =1 {# document} other {# documents}} à valider",
            "UPLOADED": "{nb, plural, =1 {# document téléchargé} other {# documents téléchargés}}"
          }
        },
        "type": {
          "FREE": "Libre",
          "STRUCTURED": "Structuré"
        }
      },
      "project-card": {
        "invalid-documents": "{numDocs, plural, =1 {# document a été marqué comme invalide} other {# documents ont été marqués comme invalides}}",
        "project-closed-alt": "Projet fermé",
        "project-shared-alt": "Projet partagé"
      },
      "project-element-list": {
        "sort": {
          "creationDate": {
            "asc": "Les plus anciens",
            "desc": "Les plus récents"
          },
          "lastUpdateDate": {
            "asc": "Mise à jour la plus ancienne",
            "desc": "Mise à jour la plus récente"
          },
          "sortBy": "Trier par :"
        },
        "status-filters": {
          "all": "Tous",
          "all-help": "Tous les projets",
          "closed": "Fermés",
          "closed-help": "Projets clôturés",
          "opened": "Ouverts",
          "opened-help": "Projets ouverts"
        }
      },
      "project-group-title": {
        "button": {
          "close": "Replier",
          "open": "Déplier"
        }
      },
      "project-part": {
        "close": "Fermer cette section",
        "open": "Ouvrir cette section",
        "title": "Documents"
      },
      "project-part-validator": {
        "error": {
          "duplicate-title": "Le nom du template de dossier existe déjà pour ce projet."
        }
      },
      "project-template-duplicate-validator": {
        "error": {
          "duplicate-title": "Le libellé du modèle de projet existe déjà."
        }
      },
      "project-template-part-document-validator": {
        "error": {
          "duplicate-title": "Le nom du template de document existe déjà pour ce dossier."
        }
      },
      "selectors": {
        "document-nature-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        },
        "project-nature-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        },
        "project-part-document-file-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        },
        "project-part-document-selector": {
          "error": "Une erreur est survenue durant la recherche",
          "loading": "Chargement en cours...",
          "no-results": "Aucun résultat"
        }
      },
      "side-menu": {
        "new-project": "Nouveau Projet",
        "projects": "Projets"
      },
      "title": "Projet"
    },
    "common-projects.file.message-info.info.mark-as-not-valid": "Le fichier a été supprimé",
    "common-projects.modal-copy.message-info.info.save.plural.false": "Le fichier copié a été enregistré.",
    "common-projects.modal-copy.message-info.info.save.plural.true": "Les fichiers copiés ont été enregistrés.",
    "common-projects.preview-component.loading": "Chargement",
    "common-projects.preview-component.previewTitle": "Prévisualisation du fichier PDF",
    "common-projects.project.message-info.info.notify-update-close": "Fermer",
    "common-projects.project.message-info.info.notify-update-text": "Les documents ont bien été reçus. <br> Ils seront pris en compte prochainement par votre conseiller La Banque Postale",
    "common-projects.project.message-info.info.notify-update-title": "Confirmation de réception de vos documents",
    "common-projects.side-menu.closed-projects": "Projets clos",
    "common-projects.side-menu.current-projects": "Projets en cours",
    "common-projects.side-menu.help.get.label": "Obtenir de l'aide",
    "common-projects.side-menu.help.label": "Aide",
    "common-projects.side-menu.projects.section.label": "Projets",
    "common.blacklisted-modal.buttons.close": "Fermer",
    "common.blacklisted-modal.footer": "*Service 0,15€/minute + prix d'un appel",
    "common.blacklisted-modal.main-text": "Afin de pouvoir réutiliser votre service de messagerie La Banque Postale, nous vous invitons à contacter votre service Client au 36 39*.",
    "common.blacklisted-modal.title": "Service momentanément indisponible",
    "common.file-upload.error.sameName": "Impossible d'insérer deux pièces jointes avec le même nom",
    "common.form-fields-required-label": "Les champs marqués d'une * sont obligatoires.",
    "common.notification.HELP_INTERACTIONS.label": "Page d'aide pour interactions",
    "common.pagination.button": "Voir plus",
    "common.pagination.button.label": "Voir plus de messages",
    "common.pagination.button.previous": "Voir précédents",
    "common.pagination.button.previous.label": "Voir les messages précédents",
    "common.pagination.label": "pagination",
    "common.pagination.limit": "Veuillez affiner la recherche pour avoir plus de résultats",
    "common.panel.panel-component.buttons.burger.label": "Ouvrir le menu",
    "common.simple-modal.buttons.back.label": "Retour",
    "common.simple-modal.buttons.back.text": "Retour",
    "common.simple-modal.buttons.back.title": "Retour",
    "common.simple-modal.buttons.close.label": "Fermer",
    "common.simple-modal.buttons.close.text": "Fermer",
    "common.simple-modal.buttons.close.title": "Fermer",
    "common.simple-modal.buttons.delete.label": "Supprimer",
    "common.simple-modal.buttons.delete.text": "Supprimer",
    "common.simple-modal.buttons.delete.title": "Supprimer",
    "common.simple-modal.buttons.validate.label": "Valider",
    "common.simple-modal.buttons.validate.text": "Valider",
    "common.simple-modal.buttons.validate.title": "Valider",
    "errors": {
      "accepted": "{description} doit être accepté",
      "after": "{description} doit être après {after}",
      "before": "{description} doit être avant {before}",
      "blank": "{description} ne peut pas être vide",
      "collection": "{description} doit être une collection",
      "confirmation": "{description} ne correspond pas à {on}",
      "date": "{description} doit être une date valide",
      "description": "Ce champ",
      "email": "{description} doit être une adresse email",
      "empty": "{description} ne peut pas être vide",
      "equalTo": "{description} doit être égale à {is}",
      "even": "{description} must be even",
      "exclusion": "{description} est réservé",
      "file-empty": "Aucun fichier n'est sélectionné",
      "greaterThan": "{description} doit être plus grand que {gt}",
      "greaterThanOrEqualTo": "{description} doit être plus grand ou égale à {gte}",
      "inclusion": "{description} n'est pas inclus dans la liste",
      "invalid": "{description} est invalide",
      "lessThan": "{description} doit être plus petit que {lt}",
      "lessThanOrEqualTo": "{description} doit être plus petit ou égale à {lte}",
      "notANumber": "{description} doit être un nombre",
      "notAnInteger": "{description} doit être un nombre",
      "odd": "{description} must be odd",
      "otherThan": "{description} doit être différent de {value}",
      "phone": "{description} doit être un numéro de téléphone",
      "positive": "{description} doit être positif",
      "present": "{description} doit être vide",
      "singular": "{description} ne peut pas être une collection",
      "tooLong": "{description} est trop long (le maximum est {max} caracters)",
      "tooShort": "{description} est trop court (le minimum est {min} caracters)",
      "unsupported-file": "Le type de fichier n'est pas pris en charge pour le document à signer",
      "url": "{description} doit être une url",
      "wrongDateFormat": "{description} doit être au format suivant {format}",
      "wrongLength": "{description} n'a pas la bonne taille (doit être de {is} caracters)"
    },
    "errors.tooLong": "{description} est trop long (le maximum est {max} caractères)",
    "errors.tooShort": "{description} est trop court (le minimum est {min} caractères)",
    "icon.disabled": "Désactivé",
    "interactions": {
      "conversation": {
        "action-bar": {
          "archive": "Archiver la conversation",
          "categorize": "Classer la conversation",
          "delete": "Supprimer la conversation",
          "download": "Exporter la conversation",
          "expiring-date": "Cette conversation expire le :",
          "mark-as-read": "Marquer la conversation comme lue",
          "mark-as-unread": "Marquer la conversation comme non lue",
          "open-project": "Ouvrir le projet",
          "print": "Imprimer la conversation",
          "removeFromTrash": "Supprimer définitivement la conversation",
          "restore": "Récupérer la conversation",
          "sort-asc": "Trier les messages par date du plus ancien au plus récent",
          "sort-desc": "Trier les messages par date du plus récent au plus ancien",
          "unarchive": "Récupérer la conversation"
        },
        "button": {
          "pause": "Pause",
          "play": "Jouer",
          "recordVoiceMessage": {
            "download": "Télécharger",
            "label": "Enregistrer un message vocal",
            "speed": "Vitesse de lecture {speed}x",
            "text": "Vocal",
            "times": "×",
            "volume": "Le volume {volumeLevel}%"
          }
        },
        "cc": {
          "current": "Cette conversation est rattachée au client : {name}"
        },
        "composer": {
          "attachments": {
            "buttons": {
              "delete": {
                "title": "Supprimer la pièce jointe"
              },
              "download": {
                "title": "Télécharger la pièce jointe"
              },
              "preview": {
                "title": "Visualiser la pièce jointe"
              }
            },
            "upload-in-progress": "(en cours)"
          },
          "buttons": {
            "add-attachment": {
              "title": "Ajouter une nouvelle pièce jointe"
            },
            "cancel": {
              "title": "Annuler les modifications",
              "value": "Annuler"
            },
            "save": {
              "title": "Sauvegarder le message en tant que brouillon",
              "value": "Sauvegarder le brouillon"
            },
            "send": {
              "title": "Envoyer le nouveau message",
              "value": "Envoyer"
            }
          },
          "notifications": {
            "attachment-uploaded": "La pièce jointe a été sauvegardée",
            "empty-file": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
            "file-name-already-used": "Une pièce jointe avec le même nom existe déjà.",
            "message-size-limit": "Les pièces jointes dépassent la taille limite.",
            "upload-in-progress": "Une pièce jointe est en cours d'envoi. Impossible d'envoyer le message"
          },
          "placeholder": "Rédiger un message"
        },
        "create": {
          "cc": "Cc",
          "confidentiality": {
            "HIGH": "Haute",
            "LOW": "Normale",
            "label": "Confidentialité :"
          },
          "flash-answer": {
            "answer": {
              "no": "Non",
              "yes": "Oui"
            },
            "header": "Résultat de réponse rapide",
            "indication": "Si ce n'est pas le cas, continuez à envoyer votre message en cliquant sur \"Non\"",
            "loading": "Analyse des messages ...",
            "question": "Cette réponse vous a semblé pertinente?",
            "sent": "Votre message a été envoyé au conseiller.",
            "title": "Cette réponse automatique semble répondre à votre message:"
          },
          "message-info": {
            "error": {
              "invalid-form": "Le formulaire est invalide, veuillez vérifier la saisie.",
              "pop-up": {
                "leaving-page": "Vos modifications ne sont pas enregistrées, voulez-vous quitter la page ?"
              },
              "recipient": "Le destinataire de la conversation est obligatoire.",
              "save": {
                "conversation": "Une erreur est apparue, la conversation n'a pas été créée. Veuillez réessayer ultérieurement.",
                "conversation-message": "Une erreur est apparue, le message n'a pas été créé. Veuillez réessayer ultérieurement."
              },
              "subject": "L'objet de la conversation est obligatoire."
            },
            "info": {
              "save": "La conversation a été créée."
            }
          },
          "subject": "Objet :",
          "theme": "Thème :",
          "title": "Nouvelle conversation",
          "to": "Destinataire :"
        },
        "draft": {
          "button": {
            "delete": {
              "label": "Supprimer",
              "title": "Supprimer la conversation en brouillon."
            }
          },
          "message-info": {
            "error": {
              "delete": "Une erreur est apparue, le brouillon de conversation n'a pas été supprimé. Veuillez réessayer ultérieurement.",
              "invalid-form": "Le formulaire est invalide, veuillez vérifier la saisie.",
              "save": {
                "conversation": "Une erreur est apparue, le brouillon de conversation n'a pas été mis à jour. Veuillez réessayer ultérieurement.",
                "conversation-message": "Une erreur est apparue, le brouillon de message n'a pas été mis à jour. Veuillez réessayer ultérieurement."
              }
            },
            "info": {
              "delete": "Le brouillon a été supprimé.",
              "pop-up": {
                "delete": "Voulez-vous supprimer cette conversation ?"
              },
              "save": "Le brouillon a été sauvegardé."
            }
          },
          "title": "Editer un brouillon"
        },
        "errors": {
          "delete": "Impossible de supprimer le brouillon. Veuillez réessayer plus tard",
          "save": "Impossible de sauvegarder le message. Veuillez réessayer plus tard",
          "send": "Impossible d'envoyer le message. Veuillez réessayer plus tard"
        },
        "export": {
          "attachments": "Avec pièces jointes",
          "button": {
            "cancel": "Annuler",
            "download": "Télécharger"
          },
          "options": "Options d'exportation"
        },
        "header": {
          "has-attachments": "Cette conversation possède des pièces jointes",
          "is-confidential": "Cette conversation est confidentielle",
          "message-number": "{nb, plural, =0 {aucun message} =1 {1 message} other {# messages}} ",
          "open-project": "Cette conversation est liée à un projet personnel ",
          "status": {
            "CLOSED": "Archivée",
            "DELETED": "Supprimée",
            "INIT": "Brouillon",
            "IN_PROGRESS": "En cours",
            "TO_ARCHIVE": "Supprimée définitivement",
            "title": "Statut"
          },
          "unread": "Non lu"
        },
        "message-info": {
          "error": {
            "accept": "Une erreur est apparue pendant l'acception de la demande de confidentialité. Veuillez réessayer ultérieurement.",
            "archive": "Une erreur est apparue pendant l'archivage. Veuillez réessayer ultérieurement.",
            "categorize": "Une erreur est apparue pendant la classification. Veuillez réessayer ultérieurement.",
            "confidentiality-response": "Une erreur est apparue pendant la réponse de la demande de confidentialité. Veuillez réessayer ultérieurement.",
            "delete": "Une erreur est apparue pendant la suppression. Veuillez réessayer ultérieurement.",
            "download": "Une erreur est apparue pendant l'export. Veuillez réessayer ultérieurement.",
            "markAsRead": "Une erreur est apparue pendant le marquage comme lu. Veuillez réessayer ultérieurement.",
            "markAsUnread": "Une erreur est apparue pendant le marquage comme non lu. Veuillez réessayer ultérieurement.",
            "pop-up": {
              "archive": "Voulez-vous archiver cette conversation ?",
              "delete": "Voulez-vous vraiment supprimer cette conversation ? Cette action est irréversible.",
              "leaving-page": "Vos modifications ne sont pas enregistrées, voulez-vous quitter la page ?"
            },
            "print": "Une erreur est apparue pendant l'impression. Veuillez réessayer ultérieurement.",
            "refuse": "Une erreur est apparue pendant le refus de la demande de confidentialité. Veuillez réessayer ultérieurement.",
            "removeFromTrash": "Une erreur est apparue pendant la suppression définitive. Veuillez réessayer ultérieurement.",
            "restore": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement.",
            "unarchive": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement."
          },
          "info": {
            "accept": "La demande d'abaissement de confidentialité a été acceptée.",
            "archive": "La conversation a été archivée.",
            "categorize": "La conversation a été classée.",
            "delete": "La conversation a été supprimée.",
            "markAsRead": "La conversation a été marquée comme lue.",
            "markAsUnread": "La conversation a été marquée comme non lue.",
            "pop-up-removeFromTrash": "Êtes-vous sûr de vouloir supprimer définitivement cette conversation ?",
            "refuse": "La demande d'abaissement de confidentialité a été rejetée.",
            "removeFromTrash": "La conversation a été supprimée définitivement.",
            "restore": "La conversation a été récupérée.",
            "unarchive": "La conversation a été restaurée."
          }
        },
        "showMore": {
          "button": "Voir plus de messages.",
          "message-info": {
            "error": {
              "showMoreMessages": "Une erreur est apparue, les messages n'ont pas été téléchargés. Veuillez réessayer ultérieurement."
            },
            "info": {
              "showMoreMessages": "Des messages plus anciens ont été téléchargés."
            }
          }
        },
        "sr-only": {
          "folderColor": "Associé au dossier de couleur",
          "lastMessageReceivedDate": "Date du dernier message reçu.",
          "messageText": "Message commençant par"
        },
        "success": {
          "delete": "Le brouillon a été supprimé",
          "save": "Le brouillon a été sauvegardé",
          "send": "Le message a été envoyé"
        },
        "templates": {
          "confidentiality": {
            "accept": "<p>Bonjour,</p><p>Le client a accepté d'abaisser la confidentialité de la conversation.</p><p>Cordialement,</p>",
            "refuse": "<p>Bonjour,</p><p>Le client a refusé d'abaisser la confidentialité de la conversation.</p><p>Cordialement,</p>"
          }
        }
      },
      "conversation-action-bar-component": {
        "actions": {
          "archive": {
            "display": "Archiver",
            "title": "Archiver toutes les conversations sélectionnées"
          },
          "categorize": {
            "display": "Classer dans ...",
            "labelSelected": "Classer toutes les conversations sélectionnées dans {label}",
            "title": "Classer toutes les conversations sélectionnées dans ...",
            "toggle": "Ouvrir le menu de 'Classer dans ...'"
          },
          "checkAll": "Sélectionner toutes les conversations affichées",
          "delete": {
            "display": "Supprimer",
            "title": "Supprimer toutes les conversations sélectionnées"
          },
          "markAs": {
            "display": "Marquer comme ...",
            "markAsRead": {
              "display": "Marquer comme lu",
              "title": "Marquer comme lus toutes les conversations sélectionnées"
            },
            "markAsUnread": {
              "display": "Marquer comme non lu",
              "title": "Marquer comme non lus toutes les conversations sélectionnées"
            },
            "toggle": "Ouvrir le menu de 'Marquer comme ...'"
          },
          "removeFromTrash": {
            "display": "Supprimer de la corbeille",
            "title": "Supprimer de la corbeille toutes les conversations sélectionnées"
          },
          "restore": {
            "display": "Récupérer",
            "title": "Récupérer toutes les conversations sélectionnées"
          },
          "unarchive": {
            "display": "Restaurer",
            "title": "Restaurer toutes les conversations sélectionnées"
          },
          "uncheckAll": "Deselectionner toutes les conversations sélectionnées"
        },
        "search": {
          "button": "Filtrer les conversations avec le texte saisi",
          "input": "Saisir le texte à chercher",
          "label": "Recherche dans les conversations :"
        },
        "sort": {
          "sortBy": "Trier par :"
        }
      },
      "conversation-line-component": {
        "buttons": {
          "check": "Sélectionner la conversation.",
          "uncheck": "Désélectionner la conversation."
        },
        "confidential": "La conversation est confidentielle.",
        "conversationSubject": "Sujet de la conversation.",
        "hasAttachments": "La conversation a au moins un message avec une pièce jointe.",
        "isRead": {
          "read": "Le dernier message de la conversation est lu.",
          "unread": "Le dernier message de la conversation est non lu."
        },
        "lastMessageDisplayName": "Nom du conseiller rattaché au dernier message.",
        "lastMessageReceivedDate": "Date du dernier message reçu.",
        "lastMessageReceivedcontent": "Contenu du dernier message reçu.",
        "lastMessageStatus": {
          "DRAFT": "Le dernier message de la conversation est un brouillon.",
          "RECEIVED": "Le dernier message de la conversation provient d'un conseiller.",
          "SENT": "Le dernier message de la conversation vient de vous."
        }
      },
      "conversation-message": {
        "body": {
          "buttons": {
            "show-less": {
              "text": "--- voir le message partiel ---",
              "title": "Afficher le message partiel"
            },
            "show-more": {
              "text": "--- voir le message complet ---",
              "title": "Afficher le message complet"
            }
          }
        },
        "header": {
          "expand-button": {
            "label-expand": "Agrandir l'entête",
            "label-shrink": "Réduire l'entête"
          },
          "expanded": {
            "cc": "Cc :",
            "cc-type": {
              "actors": "(Conseiller)",
              "externals": "(Externe)",
              "interlocutors": "(Client)"
            },
            "channel": "Canal :",
            "channels": {
              "MAIL": "Mail"
            },
            "from": "De :",
            "receiveDate": "Reçu le :",
            "sendDate": "Envoyé à:",
            "subject": "Objet :",
            "tags": "Labels :",
            "to": "À :",
            "virus-scan": "Scan anti-virus :"
          },
          "shrunken": {
            "cc": "Cc :",
            "from": "De :",
            "to": "À  :"
          },
          "title": "Information sur le message"
        }
      },
      "conversation-message-attachment": {
        "attachment": "Pièces jointes",
        "download": "Télécharger le fichier",
        "dropdown-button-title": "Actions",
        "preview": "Prévisualiser le fichier"
      },
      "conversation-message-confidentiality": {
        "accept": "Vous avez accepté d'abaisser le niveau de confidentialité le {date}",
        "asking": "Abaisser le niveau de confidentialité de cette conversation :",
        "buttons": {
          "accept": "Accepter",
          "refuse": "Refuser"
        },
        "refuse": "Vous avez refusé d'abaisser le niveau de confidentialité le {date}"
      },
      "conversations": {
        "search": {
          "list": {
            "title": "Conversations"
          },
          "message-info": {
            "error": {
              "archive": "Une erreur est apparue pendant l'archivage. Veuillez réessayer ultérieurement.",
              "categorize": "Une erreur est apparue pendant la classification. Veuillez réessayer ultérieurement.",
              "delete": "Une erreur est apparue pendant la suppression. Veuillez réessayer ultérieurement.",
              "markAsRead": "Une erreur est apparue pendant le marquage comme lu. Veuillez réessayer ultérieurement.",
              "markAsUnread": "Une erreur est apparue pendant le marquage comme non lu. Veuillez réessayer ultérieurement.",
              "removeFromTrash": "Une erreur est apparue pendant la suppression définitive. Veuillez réessayer ultérieurement.",
              "restore": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement.",
              "unarchive": "Une erreur est apparue pendant la restauration. Veuillez réessayer ultérieurement."
            },
            "info": {
              "archive": "L'archivage a été effectué.",
              "categorize": "La classification a été effectuée.",
              "delete": "La suppression a été effectuée.",
              "markAsRead": "Le marquage comme lu a été effectué.",
              "markAsUnread": "Le marquage comme non lu a été effectué.",
              "pop-up-removeFromTrash": "Êtes-vous sûr de vouloir effectuer la suppression ?",
              "removeFromTrash": "La suppression définitive a été effectuée.",
              "restore": "La restauration a été effectuée.",
              "unarchive": "La restauration a été effectuée."
            }
          }
        },
        "sort": {
          "lastMessageReceivedDate": {
            "asc": "Le plus ancien",
            "desc": "Le plus récent"
          }
        }
      },
      "forbidden-interlocutor": "L'accès à cet utilisateur n'est pas autorisé",
      "label": {
        "categorize-component": {
          "buttons": {
            "close": "Fermer",
            "manage": "Gérer mes dossiers personnels",
            "save": "Sauvegarder"
          },
          "header": {
            "text": "Mes dossiers personnels",
            "title": "Mes dossiers personnels"
          },
          "loading": "Chargement en cours...",
          "no-data": "Aucun dossier personnel à afficher"
        },
        "popup-edit-component": {
          "buttons": {
            "close": "Annuler",
            "save": "Sauvegarder"
          },
          "fields": {
            "color": {
              "label-color-1": "Couleur 1",
              "label-color-2": "Couleur 2",
              "label-color-3": "Couleur 3",
              "label-color-4": "Couleur 4",
              "label-color-5": "Couleur 5",
              "text": "Couleur :",
              "title": "Couleur"
            },
            "description": {
              "text": "Description :",
              "title": "Description"
            },
            "error": {
              "color-absent": "La couleur du nouveau dossier doit être selectionnée.",
              "name-absent": "Le nom du nouveau dossier ne peut pas être vide.",
              "name-too-long": "Le nom du nouveau dossier ne doit pas dépasser 50 caractères."
            },
            "name": {
              "text": "Nom :",
              "title": "Nom"
            }
          },
          "header": {
            "edit": {
              "text": "Dossier personnel : [{name}]",
              "title": "Dossier personnel"
            },
            "new": {
              "text": "Nouveau dossier personnel",
              "title": "Nouveau dossier personnel"
            }
          },
          "message-info": {
            "error": {
              "duplication": "Ce nom est déja utilisé pour un autre dossier personnel.",
              "invalid-form": "Le formulaire est invalide, veuillez vérifier la saisie."
            }
          }
        },
        "settings": {
          "buttons": {
            "add": {
              "label": "Ajouter",
              "title": "Ajouter"
            },
            "delete": {
              "label": "Supprimer",
              "title": "Supprimer"
            },
            "edit": {
              "label": "Editer",
              "title": "Editer"
            }
          },
          "fields": {
            "defaultText": {
              "text": "Aa",
              "title": "Exemple de label"
            },
            "description": {
              "title": "Description"
            },
            "name": {
              "title": "Nom du label"
            }
          },
          "message-info": {
            "error": {
              "create": "Une erreur est apparue, le dossier personnel n'a pas été ajouté. Veuillez réessayer ultérieurement.",
              "delete": "Une erreur est apparue, le dossier personnel n'a pas été supprimé. Veuillez réessayer ultérieurement.",
              "edit": "Une erreur est apparue, le dossier personnel n'a pas été modifié. Veuillez réessayer ultérieurement.",
              "get-404": "Une erreur est apparue, le dossier personnel n'a pas été trouvé. Veuillez réessayer ultérieurement.",
              "unknow": "Une erreur est apparue au chargement de la page. Veuillez réessayer ultérieurement."
            },
            "info": {
              "create": "Le dossier personnel a été créé.",
              "delete": "Le dossier personnel a été supprimé.",
              "deleteConfirm": "Voulez-vous supprimer le dossier personnel [{name}] ?",
              "edit": "Le dossier personnel a été mis à jour."
            }
          },
          "search": {
            "results": {
              "title": "Dossiers personnels"
            }
          }
        }
      },
      "message-attachment": {
        "message-info": {
          "error": {
            "download": "Le téléchargement a échoué. Veuillez réessayer ultérieurement."
          }
        }
      },
      "message-composer-attachments": {
        "multiple-upload": {
          "error": "{nbUploadsFailed, plural, one {# pièce jointe n'a} other {# pièces jointes n'ont}} pas pu être {nbUploadsFailed, plural, one {téléchargée} other {téléchargées}}.",
          "success": "{nbUploadsPassed, plural, one {La pièce jointe a été téléchargée} other {# pièces jointes téléchargées}} avec succès."
        }
      },
      "preview-component": {
        "buttons": {
          "close": "Retour"
        },
        "error": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
        "loading": "Chargement en cours...",
        "title": {
          "uploadDate": "Déposé le :",
          "uploader": "Déposé par :"
        }
      },
      "side-menu": {
        "conversations": {
          "label": "Conversations",
          "lines": {
            "archived": "Archivées",
            "draft": "Brouillons",
            "mine": "Mes Conversations",
            "sent": "Envoyées",
            "trashed": "Corbeille"
          },
          "sr-only": "conversations"
        },
        "labels": {
          "configurationButton": "Configuration",
          "label": "Dossiers personnels"
        },
        "new-conversation": "Nouvelle Conversation"
      }
    },
    "interactions.conversation-action-bar-component.actions.cancel": "Annuler",
    "interactions.conversation-action-bar-component.actions.selectAll": "Sélectionner toutes les conversations affichées",
    "interactions.conversation-action-bar-component.actions.unselectAll": "Désélectionner toutes les conversations affichées",
    "interactions.conversation-header.buttons.actions.label": "Menu d'actions",
    "interactions.conversation-header.buttons.answer.label": "Répondre",
    "interactions.conversation-header.buttons.back.aria-label": "{pageSource, select, conversationMine {Retour vers messages reçus} conversationSent {Retour vers messages envoyés} conversationDraft {Retour vers brouillons} conversationTrashed {Retour vers corbeille} search {Retour vers recherche} other {Retour vers messages reçus} }",
    "interactions.conversation-header.buttons.back.label": "Retour",
    "interactions.conversation-header.buttons.history.label": "Afficher l'historique",
    "interactions.conversation-header.title": "{pageSource, select, conversationMine {Messages reçus} conversationSent {Messages envoyés} conversationDraft {Brouillons} conversationTrashed {Corbeille} search {Recherche} other {Messages reçus} }",
    "interactions.conversation-line-component.buttons.label.campaign.isNotRead": "{attachment, select, true {Sélectionner le message de campagne non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Sélectionner le message de campagne non lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Sélectionner le message de campagne non lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.label.campaign.isRead": "{attachment, select, true {Sélectionner le message de campagne lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Sélectionner le message de campagne lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Sélectionner le message de campagne lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.label.draft.isNotRead": "{attachment, select, true {Sélectionner la conversation non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.label.draft.isRead": "{attachment, select, true {Sélectionner la conversation lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.label.no-draft.isNotRead": "{attachment, select, true {Sélectionner la conversation non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.label.no-draft.isRead": "{attachment, select, true {Sélectionner la conversation lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.label.only-draft.isNotRead": "{attachment, select, true {Sélectionner la conversation non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Sélectionner la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Sélectionner la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.label.only-draft.isRead": "{attachment, select, true {Sélectionner la conversation lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Sélectionner la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Sélectionner la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.no-label.campaign.isNotRead": "{attachment, select, true {Sélectionner le message de campagne non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Sélectionner le message de campagne non lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Sélectionner le message de campagne non lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.no-label.campaign.isRead": "{attachment, select, true {Sélectionner le message de campagne lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Sélectionner le message de campagne lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Sélectionner le message de campagne lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.no-label.draft.isNotRead": "{attachment, select, true {Sélectionner la conversation non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.no-label.draft.isRead": "{attachment, select, true {Sélectionner la conversation lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.no-label.no-draft.isNotRead": "{attachment, select, true {Sélectionner la conversation non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation non lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation non lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.no-label.no-draft.isRead": "{attachment, select, true {Sélectionner la conversation lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Sélectionner la conversation lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Sélectionner la conversation lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.buttons.no-label.only-draft.isNotRead": "{attachment, select, true {Sélectionner la conversation non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Sélectionner la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Sélectionner la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} }",
    "interactions.conversation-line-component.buttons.no-label.only-draft.isRead": "{attachment, select, true {Sélectionner la conversation lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Sélectionner la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Sélectionner la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.draft.label": "Brouillon",
    "interactions.conversation-line-component.expiring-date": "Date d'expiration de la conversation.",
    "interactions.conversation-line-component.header.actions.cancel.label": "Annuler",
    "interactions.conversation-line-component.header.actions.cancel.title": "Annuler toutes les actions effectuées",
    "interactions.conversation-line-component.header.actions.delete-draft.label": "Supprimer",
    "interactions.conversation-line-component.header.actions.delete-draft.title": "Supprimer le brouillon",
    "interactions.conversation-line-component.header.actions.delete-draft.titleSelect": "Supprimer {nb, plural, =1 {le brouillon} other {les brouillons}}",
    "interactions.conversation-line-component.header.actions.delete-full.label": "Supprimer définitivement",
    "interactions.conversation-line-component.header.actions.delete-full.title": "Supprimer définitivement la conversation",
    "interactions.conversation-line-component.header.actions.delete-full.titleSelect": "Supprimer définitivement {nb, plural, =1 {la conversation} other {les conversations}}",
    "interactions.conversation-line-component.header.actions.delete.label": "Supprimer",
    "interactions.conversation-line-component.header.actions.delete.title": "Supprimer, mettre la conversation à la corbeille",
    "interactions.conversation-line-component.header.actions.delete.titleSelect": "Supprimer (Mettre {nb, plural, =1 {la conversation} other {les conversations}} à la corbeille)",
    "interactions.conversation-line-component.header.actions.link-to.label": "Associer à",
    "interactions.conversation-line-component.header.actions.link-to.title": "Associer à un dossier personnel",
    "interactions.conversation-line-component.header.actions.restore.label": "Restaurer",
    "interactions.conversation-line-component.header.actions.restore.title": "Restaurer la conversation",
    "interactions.conversation-line-component.header.actions.restore.titleSelect": "Restaurer {nb, plural, =1 {la conversation} other {les conversations}}",
    "interactions.conversation-line-component.header.number-checked": "{nb, plural, =0 {Tout sélectionner} =1 {# conversation sélectionnée} other {# conversations sélectionnées}}",
    "interactions.conversation-line-component.isDraft.label": "La conversation comporte un brouillon",
    "interactions.conversation-line-component.isRead.unread": "Un message de la conversation est non lu.",
    "interactions.conversation-line-component.messages.label": "messages",
    "interactions.conversation-line-component.title.label.campaign.isNotRead": "{attachment, select, true {Ouvrir le message de campagne non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Ouvrir le message de campagne non lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Ouvrir le message de campagne non lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.label.campaign.isRead": "{attachment, select, true {Ouvrir le message de campagne lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Ouvrir le message de campagne lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Ouvrir le message de campagne lue du {label} datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.label.draft.isNotRead": "{attachment, select, true {Ouvrir la conversation non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false { Ouvrir la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other { Ouvrir la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.label.draft.isRead": "{attachment, select, true {Ouvrir la conversation lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false { Ouvrir la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other { Ouvrir la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.label.no-draft.isNotRead": "{attachment, select, true {Ouvrir la conversation non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Ouvrir la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Ouvrir la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.label.no-draft.isRead": "{attachment, select, true {Ouvrir la conversation lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Ouvrir la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Ouvrir la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.only-draft.isNotRead": "{attachment, select, true {Ouvrir la conversation non lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Ouvrir la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Ouvrir la conversation non lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} }",
    "interactions.conversation-line-component.title.label.only-draft.isRead": "{attachment, select, true {Ouvrir la conversation lue du {label} datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Ouvrir la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Ouvrir la conversation lue du {label} datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.no-label.campaign.isNotRead": "{attachment, select, true {Ouvrir le message de campagne non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Ouvrir le message de campagne non lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Ouvrir le message de campagne non lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.no-label.campaign.isRead": "{attachment, select, true {Ouvrir le message de campagne lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} false {Ouvrir le message de campagne lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}} other {Ouvrir le message de campagne lue datant du {day} dont l'objet est {subject} qui expire le {expiringDate} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.no-label.draft.isNotRead": "{attachment, select, true {Ouvrir la conversation non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false { Ouvrir la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other { Ouvrir la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.no-label.draft.isRead": "{attachment, select, true {Ouvrir la conversation lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false { Ouvrir la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other { Ouvrir la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon et {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.no-label.no-draft.isNotRead": "{attachment, select, true {Ouvrir la conversation non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Ouvrir la conversation non lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Ouvrir la conversation non lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.no-label.no-draft.isRead": "{attachment, select, true {Ouvrir la conversation lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} false {Ouvrir la conversation lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}} other {Ouvrir la conversation lue datant du {day} dont l'objet est {subject}, avec {nbrMessages,  plural, =1 {# message reçu} other {# messages reçus}} commençant par {truncatedMessage}}}",
    "interactions.conversation-line-component.title.no-label.only-draft.isNotRead": "{attachment, select, true {Ouvrir la conversation non lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Ouvrir la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Ouvrir la conversation non lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} }",
    "interactions.conversation-line-component.title.no-label.only-draft.isRead": "{attachment, select, true {Ouvrir la conversation lue datant du {day} avec au moins une pièce jointe dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} false {Ouvrir la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}} other {Ouvrir la conversation lue datant du {day} dont l'objet est {subject}, avec 1 brouillon commençant par {truncatedMessage}}}",
    "interactions.conversation-message-attachment.label.download": "télécharger la pièce jointe",
    "interactions.conversation-message-attachment.label.preview": "visualiser la pièce jointe",
    "interactions.conversation-message-attachment.text.download": "télécharger",
    "interactions.conversation-message-attachment.text.preview": "visualiser",
    "interactions.conversation-message-header.attachments.format": " : PDF, JPG, DOC(X), XLS(X), PPT(X), TXT, PNG, GIF, RTF, ODS, ODT",
    "interactions.conversation-message-header.attachments.format-title": "Formats acceptés ",
    "interactions.conversation-message-header.attachments.label": "{nb, plural, =1 {# pièce jointe} other {# pièces jointes}}",
    "interactions.conversation-message-header.attachments.max-size": " : 10 Mo par document et 30 Mo pour l’ensemble des documents",
    "interactions.conversation-message-header.attachments.max-size-title": "Taille maximum ",
    "interactions.conversation-message-header.from.adviser": "La Banque Postale",
    "interactions.conversation-message-header.from.label": "Expéditeur",
    "interactions.conversation-message-header.from.me": "Moi",
    "interactions.conversation-message-header.isUnread": "Non lu",
    "interactions.conversation-message-header.label": "Réponse",
    "interactions.conversation-message.header.expand-button.expand.aria-label.campaign.isNotRead": "{empty, select, false {Déplier et marquer comme lu le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}} true {Déplier et marquer comme lu le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} avec un corps vide} other {Déplier et marquer comme lu le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}}}",
    "interactions.conversation-message.header.expand-button.expand.aria-label.campaign.isRead": "{empty, select, false {Déplier le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}} true {Déplier le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} avec un corps vide} other {Déplier le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}}}",
    "interactions.conversation-message.header.expand-button.expand.aria-label.regular.isNotRead": "{empty, select, false {Déplier et marquer comme lu le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}} true {Déplier et marquer comme lu le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} avec un corps vide} other {Déplier et marquer comme lu le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}}}",
    "interactions.conversation-message.header.expand-button.expand.aria-label.regular.isRead": "{empty, select, false {Déplier le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}} true {Déplier le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} avec un corps vide} other {Déplier le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}}}",
    "interactions.conversation-message.header.expand-button.expand.title": "Afficher le message",
    "interactions.conversation-message.header.expand-button.shrink.aria-label.campaign": "{empty, select, false {Replier le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}} true {Replier le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} avec un corps vide} other {Replier le message envoyé par {sender} le {sendDate} expirant le {expirationDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}}}",
    "interactions.conversation-message.header.expand-button.shrink.aria-label.regular": "{empty, select, false {Replier le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}} true {Replier le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} avec un corps vide} other {Replier le message envoyé par {sender} le {sendDate}, {attachmentCount, plural, =0 {} =1 {contenant une pièce jointe} other {contenant # pièces jointes}} et commençant par {truncatedContent}}}",
    "interactions.conversation-message.header.expand-button.shrink.title": "Replier le message",
    "interactions.conversation-search-form.buttons.validate.label": "Rechercher",
    "interactions.conversation-search-form.buttons.validate.title": "Rechercher",
    "interactions.conversation-search-form.clear.title": "Réinitialiser la Recherche",
    "interactions.conversation-search-form.fields.perimeter.body": "Chercher dans :",
    "interactions.conversation-search-form.fields.perimeter.body-title": "Votre recherche :",
    "interactions.conversation-search-form.fields.perimeter.dropdown-label": "Sélectionner critère de recherche ",
    "interactions.conversation-search-form.fields.perimeter.text": "Dans :",
    "interactions.conversation-search-form.fields.perimeter.title": "Périmètre",
    "interactions.conversation-search-form.fields.text.title": "Texte de recherche",
    "interactions.conversation-search-form.no-result": "Aucun message ne correspond à votre recherche. Réessayez en élargissant vos critères de recherche.",
    "interactions.conversation-search-form.perimeters.all": "Tous les messages",
    "interactions.conversation-search-form.perimeters.conversationDraft": "Brouillons",
    "interactions.conversation-search-form.perimeters.conversationMine": "Réception",
    "interactions.conversation-search-form.perimeters.conversationSent": "Messages envoyés",
    "interactions.conversation-search-form.perimeters.conversationTrashed": "Corbeille",
    "interactions.conversation-search-form.result": "{nb, plural, =1 {# résultat} other {# résultats}}",
    "interactions.conversation-search-form.title": "Rechercher",
    "interactions.conversation.action-bar.expiring-date-label": "Expire le",
    "interactions.conversation.composer.attachments.buttons.delete.aria-label": "Supprimer la pièce jointe {file}",
    "interactions.conversation.composer.attachments.buttons.delete.title": "Supprimer la pièce jointe",
    "interactions.conversation.composer.attachments.preview-in-progress": "Chargement de la prévisualisation",
    "interactions.conversation.composer.buttons.add-attachment.title1.aria-label": "Insérer des pièces jointes",
    "interactions.conversation.composer.buttons.add-attachment.title1.label": "Insérer des pièces jointes",
    "interactions.conversation.composer.buttons.add-attachment.title2.aria-label": "Ajouter une autre pièce jointe",
    "interactions.conversation.composer.buttons.add-attachment.title2.label": "Ajouter",
    "interactions.conversation.composer.editor.aria-label.message": "Saisissez votre message",
    "interactions.conversation.composer.editor.aria-label.response": "Saisissez votre réponse",
    "interactions.conversation.composer.editor.label": "Message",
    "interactions.conversation.composer.editor.placeholder.message": "Saisissez votre message",
    "interactions.conversation.composer.editor.placeholder.response": "Saisissez votre réponse",
    "interactions.conversation.composer.modal.body": "Voulez-vous enregistrer vos modifications ?",
    "interactions.conversation.composer.modal.buttons.back.title": "Revenir au message",
    "interactions.conversation.composer.modal.buttons.back.value": "Revenir au message",
    "interactions.conversation.composer.modal.buttons.cancel.title": "Annuler la création du message",
    "interactions.conversation.composer.modal.buttons.save.title": "Enregistrer le message en tant que brouillon",
    "interactions.conversation.composer.modal.buttons.save.value": "Enregistrer",
    "interactions.conversation.composer.modal.title": "Enregistrer un brouillon",
    "interactions.conversation.create.ai.close.label": "Non, la réponse ne convient pas, envoyer mon email",
    "interactions.conversation.create.ai.close.text": "Non, envoyer mon email",
    "interactions.conversation.create.ai.close.title": "Non, envoyer mon email",
    "interactions.conversation.create.ai.found.prompt": "La réponse vous convient-elle ?",
    "interactions.conversation.create.ai.title": "Cette réponse peut vous aider",
    "interactions.conversation.create.ai.validate.label": "Oui, la réponse convient",
    "interactions.conversation.create.ai.validate.text": "Oui",
    "interactions.conversation.create.ai.validate.title": "Oui",
    "interactions.conversation.create.input.subject": "Saisissez un objet",
    "interactions.conversation.create.new.message.leave-modal.leave-button.title": "{returnButtonName} et revenir à la boîte de réception",
    "interactions.conversation.create.new.message.optional.hint": "Tous les champs sont obligatoires sauf ceux indiqués comme étant facultatifs.",
    "interactions.conversation.create.new.message.theme.input": "Sélectionnez le motif",
    "interactions.conversation.create.new.message.theme.title": "Veuillez préciser le motif de votre demande",
    "interactions.conversation.create.new.message.title": "Écrire un message",
    "interactions.conversation.create.subject": "Saisissez un objet",
    "interactions.conversation.create.subject-label": "Objet du message",
    "interactions.conversation.create.subject-label.info": "Email ",
    "interactions.conversation.create.theme": "Sélectionnez le motif",
    "interactions.conversation.create.theme-field": "Sélectionnez le motif",
    "interactions.conversation.create.theme-label": "Veuillez préciser le motif de votre demande",
    "interactions.conversation.create.title": "Écrire un message",
    "interactions.conversation.create.title.panel": "Écrire un message",
    "interactions.conversation.create.to": "Destinataire",
    "interactions.conversation.draft-info.info.delete": "Le message en brouillon a été supprimé.",
    "interactions.conversation.draft.message-info.info.pop-up.deleteTitle": "Supprimer le brouillon",
    "interactions.conversation.message-info.info.categorize": "La modification a été enregistrée",
    "interactions.conversation.message-info.info.delete": "La suppression a été effectuée.",
    "interactions.conversation.message-info.legal-guardian": "Message envoyé par {userInfo}, représentant(e) légal(e) de {interlocutorInfo}",
    "interactions.conversation.scroll.button.text": "Haut",
    "interactions.conversation.success.send": "Votre demande a été prise en compte. Nous vous répondons dans les plus brefs délais",
    "interactions.conversations.claim.iframe-title": "Formulaire de dépôt d'une réclamation",
    "interactions.conversations.claim.title": "Écrire un message",
    "interactions.conversations.expired.bold-label": "Conversation expirée",
    "interactions.conversations.expired.label": " : Nous vous invitons à créer un nouveau message",
    "interactions.conversations.inbenta.iframe-title": "Foire aux questions et envoi de message",
    "interactions.conversations.inbenta.return-to-list": "Abandonner la rédaction du nouveau message et revenir à la boîte de réception",
    "interactions.conversations.inbenta.title": "Vous avez une question ?",
    "interactions.conversations.panel-result.title.actions.new-conversation": "Nouveau message",
    "interactions.conversations.panel-result.title.actions.new-conversation-label": "Ecrire un nouveau message",
    "interactions.conversations.panel-result.title.actions.new-conversation-mobile": "Nouveau",
    "interactions.conversations.panel-result.title.actions.new-conversation-sr": "Ecrire un",
    "interactions.conversations.panel-result.title.label.conversationDraft": "Brouillons",
    "interactions.conversations.panel-result.title.label.conversationMine": "Réception",
    "interactions.conversations.panel-result.title.label.conversationSent": "Messages envoyés",
    "interactions.conversations.panel-result.title.label.conversationTrashed": "Corbeille",
    "interactions.conversations.panel-result.title.label.jump-menu": "Retour au menu d'action",
    "interactions.conversations.panel-result.title.label.jump-message": "Parcourir les messages",
    "interactions.conversations.panel-result.title.label.search": "Recherche",
    "interactions.conversations.search.message-info.error.deleteDraft": "Une erreur est apparue pendant la suppression. Veuillez réessayer ultérieurement.",
    "interactions.conversations.search.message-info.info.categorize": "La modification a été enregistrée",
    "interactions.conversations.search.message-info.info.deleteDraft": "Le brouillon a été supprimé.",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash": "Êtes-vous sûr de vouloir supprimer la conversation ?",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrash-selected": "Êtes-vous sûr de vouloir supprimer {nb, plural, =1 {la conversation sélectionnée} other {les # conversations sélectionnées}} ?",
    "interactions.conversations.search.message-info.info.pop-up-removeFromTrashtitle": "Suppression",
    "interactions.help.title": "Aide",
    "interactions.label.color-name.label-color-1": "Rouge",
    "interactions.label.color-name.label-color-2": "Orangé",
    "interactions.label.color-name.label-color-3": "Chartreuse",
    "interactions.label.color-name.label-color-4": "Vert sarcelle",
    "interactions.label.color-name.label-color-5": "Bleu azur clair",
    "interactions.label.color-name.label-color-6": "Bleu toile",
    "interactions.label.color-name.label-color-7": "Bleu royal",
    "interactions.label.color-name.label-color-8": "Bleu violet",
    "interactions.label.color-name.selected": "Sélectionné",
    "interactions.label.popup-component.buttons.close": "Annuler",
    "interactions.label.popup-component.buttons.save": "Valider",
    "interactions.label.popup-component.folder": "Dossier {folder}",
    "interactions.label.popup-component.header.text": "Associer à un dossier personnel",
    "interactions.label.popup-component.header.title": "Associer à un dossier personnel",
    "interactions.label.popup-component.header.title-description": "Sélectionnez le dossier à associer à votre message",
    "interactions.label.popup-component.no-data": "Aucun dossier personnel à afficher",
    "interactions.label.popup-edit-component.buttons.create.close": "Fermer la popup de création de dossier",
    "interactions.label.popup-edit-component.buttons.create.save": "Créer ce dossier",
    "interactions.label.popup-edit-component.buttons.edit.close": "Fermer la popup d'édition du dossier",
    "interactions.label.popup-edit-component.buttons.edit.save": "Sauvegarder les modifications du dossier",
    "interactions.label.popup-edit-component.buttons.save": "Valider",
    "interactions.label.popup-edit-component.fields.color.label": "Sélectionner la couleur de l'indicateur du dossier",
    "interactions.label.popup-edit-component.fields.color.text-title": "Couleur du dossier",
    "interactions.label.popup-edit-component.fields.name.edit.text": "Nom du dossier",
    "interactions.label.popup-edit-component.fields.name.new.text": "Nom du nouveau dossier",
    "interactions.label.popup-edit-component.header.edit.text": "Modifier mon dossier",
    "interactions.label.popup-edit-component.header.edit.title": "Modifier mon dossier",
    "interactions.label.popup-edit-component.header.new.text": "Nouveau dossier",
    "interactions.label.popup-edit-component.header.new.title": "Nouveau dossier",
    "interactions.label.popup-edit-component.label.error": "Veuillez saisir le nom du nouveau dossier",
    "interactions.label.settings.buttons.add.label": "Ajouter un dossier",
    "interactions.label.settings.buttons.add.title": "Ajouter un dossier",
    "interactions.label.settings.buttons.delete.title": "Supprimer le dossier {folder}",
    "interactions.label.settings.buttons.edit.title": "Editer le dossier {folder}",
    "interactions.label.settings.cannot-create-popup.text": "Votre espace conversation ne permet pas de créer plus de {nb} dossiers.",
    "interactions.label.settings.cannot-create-popup.title": "Création d'un dossier",
    "interactions.label.settings.delete-popup.buttons.cancel": "Annuler",
    "interactions.label.settings.delete-popup.title": "Supprimer le dossier",
    "interactions.label.settings.message-info.info.deleteConfirm": "Etes-vous sur de vouloir supprimer ce dossier ?",
    "interactions.label.settings.name.label": "Dossier",
    "interactions.label.settings.search.results.subtitle": "Gérer mes dossiers",
    "interactions.label.settings.search.results.title": "Gérer mes dossiers ({nb} / {total})",
    "interactions.label.settings.search.results.title-empty": "Gérer mes dossiers",
    "interactions.label.title": "Dossier {folder} de couleur {color}",
    "interactions.parameters.message-info.error.save": "Une erreur est apparue pendant la mise à jour du paramétrage.",
    "interactions.parameters.message-info.info.save": "Le paramétrage a été mis à jour.",
    "interactions.parameters.modal.footer.buttons.close": "Annuler",
    "interactions.parameters.modal.footer.buttons.save": "Valider",
    "interactions.parameters.modal.footer.buttons.send": "Envoyer",
    "interactions.parameters.modal.label.active.part1": "Je souhaite être averti sur mon adresse email",
    "interactions.parameters.modal.label.active.part2": "de la réception d'un nouveau message",
    "interactions.parameters.modal.label.inactive.part1": "Je ne souhaite pas être averti par mail de la réception d'un nouveau message dans mon Espace Client de La Banque Postale.",
    "interactions.parameters.modal.label.inactive.part2": "Cependant, pour toute information réglementaire, une notification me sera envoyée sur mon adresse email.",
    "interactions.parameters.modal.label.nota-bene": "Pour indiquer ou modifier cette adresse email, rendez-vous dans votre Espace personnel banque en ligne",
    "interactions.parameters.modal.title": "Gestion des notifications",
    "interactions.parameters.title": "Paramètres",
    "interactions.preview-component.previewTitle": "prévisualisation de la pièce jointe PDF",
    "interactions.side-menu.close.aria-label": "Fermer le menu messagerie",
    "interactions.side-menu.close.description": "Fermer la messagerie et revenir à l’espace Contacts",
    "interactions.side-menu.close.label": "Menu messagerie",
    "interactions.side-menu.conversations.lines.mine": "Réception",
    "interactions.side-menu.conversations.lines.search": "Recherche",
    "interactions.side-menu.conversations.lines.sent": "Messages envoyés",
    "interactions.side-menu.conversations.section.label": "Boites principales",
    "interactions.side-menu.help.get.label": "Obtenir de l'aide",
    "interactions.side-menu.help.label": "Aide",
    "interactions.side-menu.labels.add.label": "Créer un dossier",
    "interactions.side-menu.labels.label": "Mes dossiers",
    "interactions.side-menu.labels.manage.label": "Gérer mes dossiers",
    "interactions.side-menu.leave.aria-label": "Quitter la messagerie et retourner vers les modes de contact",
    "interactions.side-menu.main-folder": "Mes dossiers",
    "interactions.side-menu.module.label": "Messagerie",
    "interactions.side-menu.parameters.label": "Paramètres",
    "interactions.side-menu.switch-module.label": "Accéder à l'échange de documents",
    "interactions.side-menu.switch-module.popup.buttons.cancel.label": "Annuler",
    "interactions.side-menu.switch-module.popup.buttons.cancel.title": "Annuler",
    "interactions.side-menu.switch-module.popup.buttons.confirm.label": "Confirmer",
    "interactions.side-menu.switch-module.popup.buttons.confirm.title": "Confirmer",
    "interactions.side-menu.switch-module.popup.label": "Accéder à l'espace d'échange de documents",
    "interactions.side-menu.switch-module.popup.message-info.info.confirm-route": "Êtes-vous sûr de vouloir quitter la messagerie ?",
    "interactions.side-menu.switch-module.title": "Plus",
    "interlocutors": {
      "breadcrumb": {
        "title": "Interlocuteurs"
      },
      "interlocutor": {
        "projects": {
          "breadcrumb": {
            "title": "Projets"
          },
          "search": {
            "closed": "Projets clos",
            "open": "Projets en cours"
          }
        }
      }
    },
    "multimenu.buttons.back.label": "Retour au détail du projet",
    "multimenu.buttons.back.submenu": "Retour au choix de provenance du fichier",
    "page.name": "LBP - Messagerie Sécurisée - {pageName}",
    "page.name.project": "LBP - Espace de partage - {pageName}",
    "page.name.project-info": "Project ",
    "pageContentLoader.label": "Requête en cours de traitement, veuillez patienter",
    "powerSelect.selected": "{title} sélectionné",
    "profile": {
      "coordinates": {
        "edit": {
          "cancel": "Annuler",
          "email": {
            "clear": "Effacer le champ email externe.",
            "label": "Email externe :",
            "title": "Email externe"
          },
          "mobile-phone": {
            "clear": "Effacer le champ téléphone mobile.",
            "invalid-message": "Le numéro de téléphone saisi est invalide.",
            "label": "Téléphone mobile :",
            "title": "Téléphone mobile"
          },
          "save": "Sauvegarder"
        },
        "header": "Coordonnées",
        "message-info": {
          "error": {
            "invalid-form": "Le formulaire est invalide, corrigez le puis réessayez.",
            "save": "Une erreur est apparue pendant la mise à jour de vos coordonnées. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "Vos coordonnées ont été mises à jour."
          }
        },
        "view": {
          "edit": "Modifier",
          "email": {
            "label": "Email externe :"
          },
          "mobile-phone": {
            "label": "Téléphone mobile :"
          }
        }
      },
      "infos": {
        "field": {
          "firstname": {
            "label": "Prénom :",
            "title": "Prénom"
          },
          "name": {
            "label": "Nom :",
            "title": "Nom"
          }
        },
        "header": "Informations personnelles"
      },
      "notifications": {
        "NEW_CAMPAIGN_MESSAGE_RECEIVED": "Nouveau message de campagne reçu",
        "NEW_DOCUMENT_UPLOADED_BY_ADVISER": "Nouveau document créé par un conseiller",
        "NEW_FILE_AVAILABLE_TO_SIGN": "Nouveau fichier à signer",
        "NEW_MESSAGE_INTERLOCUTOR_RECEIVED": "Nouveau message reçu",
        "PROJECT_PUBLICATION_STOPPED": "Partage de projet interrompu",
        "PROJECT_PUBLISHED_TO_INTERLOCUTOR": "Nouveau projet partagé avec moi",
        "SEND_DOCUMENT_REJECT_TO_CUSTOMER": "Document rejeté par un conseiller",
        "SEND_UPDATE_TO_CUSTOMER": "Mise à jour de projet"
      },
      "preferences": {
        "authorizeAudioMessage": {
          "label": "Messages audio autorisés :",
          "title": "Messages audio autorisés :"
        },
        "authorizeAudioMessages": {
          "false": "No",
          "true": "Yes"
        },
        "availableLangages": {
          "en-gb": "English",
          "fr-fr": "Français",
          "it-it": "Italiano"
        },
        "closeErrorMessage": {
          "label": "Fermeture des messages d'erreur :",
          "title": "Fermeture des messages d'erreur :"
        },
        "closeErrorMessages": {
          "false": "Automatique",
          "true": "Manuel"
        },
        "edit": {
          "cancel": "Annuler",
          "save": "Sauvegarder"
        },
        "header": "Préférences",
        "language": {
          "label": "Langue de l'interface :",
          "title": "Langue"
        },
        "message-info": {
          "error": {
            "save": "Une erreur est survenue lors de la mise à jour des préférences. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "Les préférences ont été mises à jour."
          }
        },
        "spellchecker-language": {
          "label": "Langue de vérification par défaut :",
          "title": "Langue de vérification par défaut"
        },
        "view": {
          "edit": "Modifier"
        }
      },
      "side-bar": {
        "sign-out": "Déconnexion",
        "title": "Paramètres du compte"
      },
      "side-menu": {
        "personal-informations": "Informations personnelles",
        "preferences": "Préférences",
        "subscriptions": "Notifications"
      },
      "subscriptions": {
        "edit": {
          "cancel": "Annuler",
          "save": "Sauvegarder"
        },
        "header": "Mes abonnements",
        "message-info": {
          "error": {
            "loading": "Une erreur est apparue pendant le chargement des abonnements aux notifications. Veuillez réessayer ultérieurement.",
            "save": "Une erreur est apparue pendant la sauvegarde des abonnements aux notifications. Veuillez réessayer ultérieurement."
          },
          "info": {
            "save": "Vos abonnements aux notifications ont été mis à jour."
          }
        },
        "view": {
          "edit": "Modifier",
          "state": {
            "disabled": "Inactive",
            "enabled": "Active"
          }
        }
      }
    },
    "projects": {
      "breadcrumb": {
        "choose-project": "Choix Projet",
        "create": {
          "free": {
            "title": "Projet Libre"
          },
          "title": "Nouveau Projet"
        },
        "edit": {
          "free": {
            "title": "Modification Projet"
          }
        },
        "project-details": "Détails",
        "projects-status": {
          "CLOSED": "Projets clos",
          "IN_PROGRESS": "Projets en cours"
        }
      },
      "create": {
        "project": {
          "buttons": {
            "cancel": "Annuler",
            "preview": "Pré visualiser",
            "save": "Sauvegarder"
          },
          "fields": {
            "assigned-to": "Assigné à",
            "date-format": "DD/MM/YYYY",
            "description": "Description",
            "enddate": "Date de fin prévue",
            "errors": {
              "description-absent": "La description du projet ne peut pas être vide.",
              "description-too-long": "La description du projet ne peut pas dépasser 255 caractères.",
              "nature-absent": "La nature du projet ne peut pas être vide.",
              "ownerActor-absent": "Le projet doit être assigné à un conseiller.",
              "title-absent": "Le nom du projet ne peut pas être vide.",
              "title-too-long": "Le nom du projet ne peut pas dépasser 255 caractères."
            },
            "nature": "Nature",
            "title": "Nom"
          },
          "folder": {
            "no-data": "Le projet n'a aucun dossier.",
            "status": {
              "CLOSED": "Fermé",
              "INIT": "Initialisé",
              "OPENED": "Ouvert"
            },
            "title": "Dossiers"
          },
          "message-info": {
            "error": {
              "create": "Une erreur est apparue alors de la création du projet. Veuillez réessayer ultérieurement.",
              "duplicate-title": "Le nom existe déjà pour cet interlocuteur.",
              "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
            },
            "info": {
              "create": "Le projet a été créé."
            }
          },
          "pop-up": {
            "not-saved-transition": "Les modifications n'ont pas été enregistrées. Etes-vous sûr de vouloir quitter cette page ?"
          }
        }
      },
      "document": {
        "no-data": "Cette section n'a pas de document."
      },
      "edit": {
        "project": {
          "buttons": {
            "cancel": "Annuler",
            "preview": "Pré visualiser",
            "save": "Sauvegarder"
          },
          "fields": {
            "assigned-to": "Assigné à",
            "date-format": "DD/MM/YYYY",
            "description": "Description",
            "enddate": "Date de fin prévue",
            "errors": {
              "description-absent": "La description du projet ne peut pas être vide.",
              "nature-absent": "La nature du projet ne peut pas être vide.",
              "title-absent": "Le nom du projet ne peut pas être vide."
            },
            "nature": "Nature",
            "title": "Nom"
          },
          "folder": {
            "no-data": "Le projet n'a aucun dossier.",
            "status": {
              "CLOSED": "Fermé",
              "INIT": "Initialisé",
              "OPENED": "Ouvert"
            },
            "title": "Dossiers"
          },
          "message-info": {
            "error": {
              "duplicate-title": "Le nom de projet existe déjà pour cet interlocuteur.",
              "edit": "Une erreur est apparue lors de la modification du projet. Veuillez réessayer ultérieurement.",
              "invalid-form": "Le formulaire est invalide, merci de le corriger et de réessayer."
            },
            "info": {
              "edit": "Le projet a été modifié."
            }
          },
          "pop-up": {
            "not-saved-transition": "Les modifications n'ont pas été enregistrées. Etes-vous sûr de vouloir quitter cette page ?"
          }
        }
      },
      "list": {
        "message-info": {
          "error": {
            "get-404": "Une erreur est apparue, le projet n'a pas été trouvé. Veuillez réessayer ultérieurement.",
            "unknown": "Une erreur est apparue au chargement de la page. Veuillez réessayer ultérieurement."
          }
        },
        "title": "Projets"
      },
      "new-project": {
        "buttons": {
          "choose-btn": "Choisir"
        },
        "free": {
          "description": "Dans un projet libre, le client ou vous-même pouvez librement ajouter des documents.",
          "title": "Nouveau Projet Libre"
        }
      },
      "project": {
        "interlocutor-new": {
          "help": "Créer un nouveau projet pour {interlocutor}",
          "title": "Nouveau projet pour {interlocutor}"
        },
        "new": {
          "help": "Créer un nouveau projet",
          "title": "Nouveau projet"
        }
      },
      "project-group": {
        "no-data": "Ce groupe de projet est vide."
      },
      "project-group-title": {
        "projects": "Projets appartenant à {interlocutorFullName}",
        "projects-shared": "Projets partagés avec {interlocutorFullName}"
      },
      "project-part": {
        "no-data": "Ce projet n'a pas de section."
      },
      "search": {
        "filter": {
          "label": {
            "assignedTo": "Assignées à ::",
            "interlocutor": "Interlocuteur :"
          }
        },
        "legend": "Search",
        "message-info": {
          "error": {
            "project-load": "Impossible de charger la liste des projets"
          }
        }
      },
      "side-menu": {
        "closed-projects": "Close",
        "in-progress-projects": "En cours",
        "interlocutor-menu-label": "Projets de {name}",
        "my-projects": "Les miens",
        "projects": "Projets"
      },
      "signature": {
        "spinner": {
          "primary-error": "Une erreur est survenue",
          "primary-success": "Le document a été signé",
          "primary-wait": "Vérification de la signature du document...",
          "secondary-error": "Le document n'a pas été signé",
          "secondary-success": "Vous pouvez fermer cet onglet",
          "secondary-wait": "Veuillez patienter"
        }
      },
      "title": {
        "empty": "Projets",
        "mine": "Projets"
      }
    },
    "projects.help.title": "Aide",
    "projects.project-group-title-heading.project.text": "Projet {project}",
    "projects.project-group-title-heading.status.closed": "clos",
    "projects.project-group-title-heading.status.in-progress": "en cours",
    "projects.project-group-title-heading.text": "Mes Projets {status}",
    "projects.project-group-title.projects": "Mes projets",
    "projects.project-group.no-data.closed": "Aucun projet clos",
    "projects.project-group.no-data.current": "Aucun projet en cours",
    "projects.project.actions.action-download.aria-label": "Télécharger tous les documents au format zip",
    "projects.project.actions.action-download.title": "Télécharger les documents",
    "projects.project.actions.action-notif.aria-label": "Prévenir mon conseiller que tous mes documents sont déposés",
    "projects.project.actions.action-notif.title": "Prévenir mon conseiller que tous mes documents sont déposés",
    "projects.project.actions.title": "Actions",
    "projects.project.description.closedOn": "Clos le",
    "projects.project.description.createdOn": "Créé le",
    "projects.project.description.createdOnList": "Créé le {date}",
    "projects.project.description.desc": "Descriptif du projet",
    "projects.project.description.expiredOn": "Expire le",
    "projects.project.description.finishedOn": "Date de fin le",
    "projects.project.description.indicators.new-project.text": "Cet espace Projet à été créé par mon conseiller.",
    "projects.project.description.indicators.new-project.title": "Nouveau",
    "projects.project.description.indicators.related-messages.text": "Consulter dans la messagerie    >",
    "projects.project.description.indicators.related-messages.title": "{nb, plural, =1 {# Message associé} other {# Messages associés}}",
    "projects.project.description.indicators.unread-new-documents.title": "{nb, plural, =1 {# document déposé} other {# documents déposés}}",
    "projects.project.description.indicators.unread-rejected-documents.title": "{nb, plural, =1 {# document rejeté} other {# documents rejetés}}",
    "projects.project.description.indicators.unread-validated-documents.title": "{nb, plural, =1 {# document validé} other {# documents validés}}",
    "projects.project.description.indicators.warning.text": "Le {date}, j'ai prévenu mon conseiller que tous mes fichiers étaient déposés.",
    "projects.project.description.indicators.warning.title": "J'ai prévenu mon conseiller",
    "projects.project.description.link": "Accéder au projet",
    "projects.project.description.new": "Nouveau",
    "projects.project.description.shared": "Partagé par moi",
    "projects.project.description.title": "À propos de ce projet",
    "projects.project.documents.action.delete.aria-label": "Supprimer le document {document}",
    "projects.project.documents.action.delete.title": "Supprimer le document",
    "projects.project.documents.delete.group-title.name": "Voulez-vous supprimer ce document :",
    "projects.project.documents.delete.title": "Supprimer le document",
    "projects.project.documents.deletion.buttons.cancel.label": "Annuler",
    "projects.project.documents.deletion.buttons.cancel.text": "Annuler",
    "projects.project.documents.deletion.buttons.cancel.title": "Annuler",
    "projects.project.documents.deletion.buttons.ok.label": "Ok",
    "projects.project.documents.deletion.buttons.ok.text": "Ok",
    "projects.project.documents.deletion.buttons.ok.title": "Ok",
    "projects.project.documents.deletion.group-title.body": "Suppression",
    "projects.project.documents.deletion.group-title.text": "Voulez-vous supprimer ce fichier ?",
    "projects.project.documents.deletion.group-title.title": "Supprimer le fichier",
    "projects.project.documents.file.action.delete.aria-label": "Supprimer le fichier {file}",
    "projects.project.documents.file.action.delete.title": "Supprimer le fichier",
    "projects.project.documents.file.action.download.aria-label": "Télécharger ou visualiser le fichier {file}",
    "projects.project.documents.file.action.download.title": "Télécharger le fichier",
    "projects.project.documents.file.action.preview.aria-label": "Visualiser le fichier {file}",
    "projects.project.documents.file.action.upload.text": "Téléchargement du fichier en cours ...",
    "projects.project.documents.file.action.upload.title": "Téléchargement du fichier",
    "projects.project.documents.file.add.aria-label": "Ajouter un fichier au document {document}, statut {status}",
    "projects.project.documents.file.add.from.addInProgress": "Ajout en cours",
    "projects.project.documents.file.add.from.duplicate": "Un fichier avec le même nom est déja présent dans le document",
    "projects.project.documents.file.add.from.file.aria-label": "Fichier {file}",
    "projects.project.documents.file.add.from.fileSearchInProgress": "Recherche de fichier en cours",
    "projects.project.documents.file.add.from.message-info.errors.duplicate": "Vous ne pouvez importer plusieurs fichiers avec le même nom.",
    "projects.project.documents.file.add.from.no-files.aria-label": "Aucun fichier dans le projet {project}",
    "projects.project.documents.file.add.from.no-files.label": "Aucun fichier dans ce projet",
    "projects.project.documents.file.add.from.no-project": "Vous n'avez pas de projet dans cette catégorie.",
    "projects.project.documents.file.add.from.project.aria-label": "Liste des fichiers présents dans le projet {project}",
    "projects.project.documents.file.add.from.projectSearchInProgress": "Recherche de projet en cours",
    "projects.project.documents.file.add.from.selectFiles": "Ajouter les fichiers cochés",
    "projects.project.documents.file.add.fromClosed.aria-label": "Fichier depuis un projet clos, ce choix adapte la liste des fichiers disponibles",
    "projects.project.documents.file.add.fromClosed.label": "Fichier depuis un projet clos",
    "projects.project.documents.file.add.fromCurrent.aria-label": "Fichier depuis un projet en cours, ce choix adapte la liste des fichiers disponibles",
    "projects.project.documents.file.add.fromCurrent.label": "Fichier depuis un projet en cours",
    "projects.project.documents.file.add.fromDevice.aria-label": "Fichier depuis cet appareil ce choix adapte la liste des fichiers disponibles",
    "projects.project.documents.file.add.fromDevice.label": "Fichier depuis cet appareil",
    "projects.project.documents.file.add.inProgress": "Ajout en cours",
    "projects.project.documents.file.add.selectFile": "Sélectionner un fichier",
    "projects.project.documents.file.add.title": "Ajouter un fichier",
    "projects.project.documents.file.attachments.from.adviser": "La Banque Postale",
    "projects.project.documents.file.attachments.popup.label.download": "télécharger le fichier",
    "projects.project.documents.file.attachments.popup.label.preview": "visualiser le fichier",
    "projects.project.documents.file.attachments.popup.text.download": "télécharger",
    "projects.project.documents.file.attachments.popup.text.preview": "visualiser",
    "projects.project.documents.file.deleteAction": "Supprimer le fichier",
    "projects.project.documents.file.downloadAction": "Télécharger le fichier",
    "projects.project.documents.message-info.error.delete": "Une erreur s'est produite, le document n'a pas été supprimé. Veuillez réessayer ultérieurement.",
    "projects.project.documents.message-info.info.delete": "Le document a été supprimé",
    "projects.project.documents.message-info.info.pop-up.delete": "Voulez-vous vraiment supprimer ce document ?",
    "projects.project.documents.reject.aria-label": "Motif de refus du document {document}",
    "projects.project.documents.reject.modal.title": "Motif de refus du document",
    "projects.project.documents.reject.no-data": "Motif de refus non connu",
    "projects.project.documents.status.KO": "Non valide",
    "projects.project.documents.status.OK": "Valide",
    "projects.project.documents.status.TO_ACKNOWLEDGE": "Déposé",
    "projects.project.documents.status.TO_UPLOAD": "À déposer",
    "projects.project.documents.status.TO_UPLOAD_LBP": "À déposer par La Banque Postale",
    "projects.project.documents.status.UPLOADED": "Déposé par La Banque Postale",
    "projects.project.documents.title": "Documents du projet",
    "projects.project.documents.unread.text": "Non lu",
    "projects.project.documents.unread.title": "Document non lu",
    "projects.project.documents.uploadOther.baseModal.buttonCancel": "Annuler",
    "projects.project.documents.uploadOther.button.OK": "OK",
    "projects.project.documents.uploadOther.button.aria-label": "Valider la création du nouveau type de document",
    "projects.project.documents.uploadOther.confirmModal.text": "Le type de document \"{type}\" a été créé",
    "projects.project.documents.uploadOther.errorModal.text": "Erreur lors de l'ajout du nouveau document",
    "projects.project.documents.uploadOther.errorModal.textDuplicate": "Impossible d'ajouter un document car un document avec le même nom existe déjà",
    "projects.project.documents.uploadOther.errorModal.title": "Ajouter un nouveau document",
    "projects.project.documents.uploadOther.fileModal.title": "Ajouter un fichier au nouveau document",
    "projects.project.documents.uploadOther.powerSelect.aria-label": "Liste des documents types possibles à ajouter",
    "projects.project.documents.uploadOther.powerSelect.model.aria-label": "Modèle {model} de nature {nature}",
    "projects.project.documents.uploadOther.powerSelect.model.title": "- {model}",
    "projects.project.documents.uploadOther.powerSelect.nature.aria-label": "Nature {nature}",
    "projects.project.documents.uploadOther.title": "Déposer des fichiers pour un autre type de document",
    "projects.project.labels.advisorWarned": "J'ai prévenu mon conseiller le {date} que tous mes fichiers étaient déposés",
    "projects.project.labels.clos": "Voir le projet {name} fermé le {closed} et expire le {expire}",
    "projects.project.labels.newDocuments": "{nb, plural, =1 {1 nouveau document a été déposé} other {# nouveaux documents ont été déposés}}, voir le projet",
    "projects.project.labels.open": "Voir le projet {name} ouvert le {created} {nbMessages, plural, =0 { } =1 {, # message} other {, # messages}} {isWarned, plural, =1 {, conseiller prévenu} other {}}",
    "projects.project.labels.readMessages": "{nbMessages, plural, =0 {} =1 {Lire le message associé} other {Lire les # messages associés}} au projet {project}",
    "projects.project.labels.rejectedDocuments": "Il y a {nb, plural, =1 {1 nouveau document a été rejeté} other {# nouveaux documents ont été rejetés}}, voir le projet",
    "projects.project.labels.unvalidatedDocuments": "Il y a {nb, plural, =1 {1 nouveau document qui n'a pas été validé} other {# nouveaux documents qui n'ont pas été validés}}, voir le projet",
    "projects.quit.text": "Accéder à la conversation vous fera quitter l'espace d'échange de documents. Voulez-vous continuer ?",
    "projects.side-menu.close.aria-label": "Fermer le menu échange de documents",
    "projects.side-menu.close.label": "Menu échange de documents",
    "projects.side-menu.description.closed": "Projets clos",
    "projects.side-menu.description.inprogress": "Projets en cours",
    "projects.side-menu.leave.aria-label": "Quitter l'espace d'échange de documents et retourner vers les modes de contact",
    "projects.side-menu.module.label": "Échange de documents",
    "projects.side-menu.switch-module.label": "Accéder à la messagerie",
    "projects.side-menu.switch-module.popup.buttons.cancel.label": "Annuler",
    "projects.side-menu.switch-module.popup.buttons.cancel.title": "Annuler",
    "projects.side-menu.switch-module.popup.buttons.confirm.label": "Confirmer",
    "projects.side-menu.switch-module.popup.buttons.confirm.title": "Confirmer",
    "projects.side-menu.switch-module.popup.label": "Accéder à la messagerie",
    "projects.side-menu.switch-module.popup.message-info.info.confirm-route": "Êtes-vous sûr de vouloir quitter l'échange de documents ?",
    "projects.side-menu.switch-module.title": "Plus",
    "select.none": "Choisir le type de documents",
    "select.none-label": "Choisir le type de documents :",
    "shared": {
      "accessibility": {
        "pagination": "Pagination"
      },
      "components": {
        "a11y": {
          "modals": {
            "alert-modal": {
              "closeButtonText": "Fermer",
              "closeButtonTitle": "Fermer la fenêtre"
            }
          }
        },
        "absence-table": {
          "absence-backend-error": "Erreur lors de la récupération des absences d'un conseiller",
          "granularity-dateformat-day": "DD/MM/YYYY",
          "granularity-dateformat-month": "MMMM YYYY",
          "granularity-dateformat-week": "Semaine {week} - {year}",
          "granularity-day": "jour",
          "granularity-legend": "Affichage",
          "granularity-month": "mois",
          "granularity-week": "semaine",
          "grid-caption": "Liste des absences",
          "next-day-button-label": "Jour suivant",
          "next-month-button-label": "Mois suivant",
          "next-week-button-label": "Semaine suivante",
          "prev-day-button-label": "Jour précedent",
          "prev-month-button-label": "Mois précedent",
          "prev-week-button-label": "Semaine précedente",
          "range-label": "De {start} à {end}",
          "range-label-backup": "Remplacé par {backup}",
          "today-button-label": "Aujourd'hui"
        },
        "charts": {
          "adviser-dashboard-chart": {
            "ACKNOWLEDGE": "Conversations acquitées",
            "CLOSED": "Conversations traitées",
            "CLOSED-AFTER-CRITICAL-DUE-DATE": "Nombre de conversations closes après la date d'échéance critique",
            "CLOSED-BEFORE-CRITICAL-DUE-DATE": "Nombre de conversations closes avant la date d'échéance critique",
            "CLOSED-BEFORE-DUE-DATE": "Nombre de conversations closes avant la date d'échéance",
            "OPEN": "Conversations créées",
            "REOPEN": "Conversations réouvertes",
            "nb-conversations": "Nombre de conversations"
          }
        },
        "date": {
          "cancel": "Annuler",
          "dow": {
            "abbr": {
              "Fr": "Vendredi",
              "Mo": "Lundi",
              "Sa": "Samedi",
              "Su": "Dimanche",
              "Th": "Jeudi",
              "Tu": "Mardi",
              "We": "Mercredi"
            },
            "text": {
              "Fr": "Ve",
              "Mo": "Lu",
              "Sa": "Sa",
              "Su": "Di",
              "Th": "Je",
              "Tu": "Ma",
              "We": "Me"
            }
          },
          "format": "DD/MM/YYYY",
          "formatWithTime": "DD/MM/YYYY HH:mm",
          "month": {
            "april": "Avril",
            "august": "Août",
            "december": "Décembre",
            "february": "Février",
            "january": "Janvier",
            "july": "Juillet",
            "june": "Juin",
            "march": "Mars",
            "may": "Mai",
            "november": "Novembre",
            "october": "Octobre",
            "september": "Septembre"
          },
          "month-year": "Mois Année",
          "next-month": "Mois suivant",
          "next-year": "Année suivante",
          "ok": "OK",
          "picker-button-alt": "Choisissez une date",
          "placeholderFormat": "JJ/MM/AAAA",
          "placeholderFormatWithTime": "JJ/MM/AAAA hh:mm",
          "previous-month": "Mois précédent",
          "previous-year": "Année précédente"
        },
        "dropdown": {
          "default-button-title": "Actions"
        },
        "form-component": {
          "form-fields-required-label": "Les champs marqués d'une * sont obligatoires."
        },
        "form-group-component": {
          "missing-text": "Ce champ est requis."
        },
        "modals": {
          "audio-preview": {
            "loading": "Chargement ...",
            "modal-title": "Lecture audio"
          },
          "authenticator-confirm-modal": {
            "cancel": "Déconnexion",
            "confirm": "Oui",
            "message": "Vous allez être déconnecté dans quelques instants.",
            "question": "Souhaitez-vous prolonger votre session ?",
            "title": "Information"
          },
          "document-preview": {
            "error-message": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
            "modal-title": "Prévisualiser le fichier",
            "select-page": "Sélectionner une page",
            "upload-by": "Téléchargé par {actor} le {date}",
            "zoom-in": "Zoomer",
            "zoom-out": "Dézoomer"
          },
          "image-preview": {
            "error-message": "Une erreur est apparue pendant le chargement de l'image. Veuillez réessayer ultérieurement.",
            "loading-message": "Chargement en cours...",
            "modal-title": "Prévisualiser le fichier",
            "upload-by": "Déposé par",
            "upload-date": "Déposé le",
            "zoom-in": "Zoomer",
            "zoom-out": "Dézoomer"
          },
          "modal-component": {
            "modal-cancel": "Annuler",
            "modal-confirm": "Confirmer"
          },
          "title": {
            "closeButton": "Fermer ce message"
          }
        },
        "multi-selectors": {
          "cc-multi-selector": {
            "error": "Une erreur est apparue pendant la recherche.",
            "error-external-on-secured": "Impossible d'ajouter un courriel externe sur le canal de courriel sécurisé",
            "error-secured-on-external": "Impossible d'ajouter un courriel sécurisé sur le canal de courriel externe",
            "errors": {
              "no-account-found-external": "Aucun compte ayant le mail externe '{email}' n'a été trouvé",
              "no-account-found-secured": "Aucun compte ayant le mail securisé '{email}' n'a été trouvé"
            },
            "loading": "Chargement en cours...",
            "no-results": "Aucun résultat",
            "type": {
              "actor": "Conseiller : ",
              "entity": "Entité : ",
              "external": "Ext.",
              "interlocutor": "",
              "trustedInterlocutor": "Interlocuteur de confiance"
            }
          },
          "tags-multi-selector": {
            "error": "Une erreur est apparue pendant la recherche.",
            "loading": "Chargement en cours...",
            "no-results": "Aucun résultat"
          }
        },
        "panels": {
          "showLess": "Afficher moins",
          "showMore": "Afficher plus"
        },
        "presets-selector": {
          "title": "Prédéfinis"
        },
        "rating": {
          "message-info": {
            "error": {
              "rate": "Une erreur s'est produite lors de la sauvegarde de la note, veuillez réessayer plus tard."
            },
            "info": {
              "rate": "La note a été sauvegardée avec succès."
            }
          },
          "title": "Voulez-vous changer votre note ?",
          "title-new": "Êtes-vous satisfait par cet échange ?"
        },
        "result-list": {
          "results": {
            "error": "Une erreur est survenue pendant le chargement. Veuillez réessayer ultérieurement.",
            "loading": "Chargement ...",
            "none": "Aucun résultat disponible",
            "wait": "Veuillez patienter"
          }
        },
        "text-editor": {
          "actions-list": {
            "f-ai": {
              "choose-template": "Choisissez un modèle de réponse :",
              "ko-declaration": "Déclarer les modèles comme non satisfaisants :",
              "show-templates": "Afficher les modèles de réponses de l'analyse sémantique"
            },
            "f-aligncenter": "Centrer",
            "f-alignleft": "Aligner à gauche",
            "f-alignright": "Aligner à droite",
            "f-attachments": {
              "help": "Copier les pièces jointes"
            },
            "f-bold": "Gras",
            "f-color": {
              "help": "Couleur",
              "label": "Changer la couleur du texte"
            },
            "f-font": {
              "help": "Font",
              "label": "Choisissez la famille de fontes"
            },
            "f-gallery": "Ajouter une image",
            "f-italic": "Italique",
            "f-link": {
              "help": "Ajouter un lien",
              "ok": "OK",
              "url": "URL"
            },
            "f-list": "Passer de/à la liste",
            "f-signature": {
              "apply": "Appliquer la signature",
              "choose": "Choisissez une signature",
              "label": "Signature..."
            },
            "f-size": {
              "help": "Taille de la fonte",
              "label": "Choisissez la taille de la fonte"
            },
            "f-spellCheck": {
              "check-spelling": "Vérifier l'orthographe",
              "choose-language": "Choisissez une langue",
              "ignore": "Ignorer",
              "ignore-all": "Ignorer tout"
            },
            "f-underline": "Souligné",
            "f-upload": {
              "help": "Ajouter piéce jointe"
            }
          },
          "initial-attachments": {
            "copy-attachment": "Copier une pièce jointe",
            "message-info": {
              "error": {
                "copy-attachment": "Une erreur s'est produite lors de la copie de la pièce jointe. Merci de rééssayer ultérieurement."
              },
              "info": {
                "file-removed": "La pièce jointe a été supprimée.",
                "file-uploaded": "La pièce jointe a été copiée."
              }
            }
          },
          "inline-images": {
            "message-info": {
              "error": {
                "empty-file": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
                "preview": "Le chargement de l'image a échoué, veuillez réessayer ultérieurement."
              }
            }
          },
          "message-composer-attachments": {
            "delete": "Supprimer cette pièce jointe",
            "download": "Télécharger cette pièce jointe",
            "dropdown-button-title": "Actions",
            "message-info": {
              "error": {
                "download": "Le téléchargement a échoué. Veuillez réessayer ultérieurement.",
                "empty-file": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
                "failed-to-upload": "Impossible d'envoyer la pièce jointe. Merci de rééssayer ultérieurement",
                "file-name-already-used": "Une pièce jointe avec le même nom existe déjà.",
                "maxAttachmentReached": "Vous avez atteint le nombre maximum de pièces jointes autorisé.",
                "message-size-limit": "Les pièces jointes dépassent la taille limite.",
                "not-allowed-content-type": "Le type de pièce jointe n'est pas autorisé.",
                "preview": "Le chargement de l'image a échoué, veuillez réessayer ultérieurement."
              },
              "info": {
                "file-uploaded": "La pièce jointe a été sauvegardée",
                "file-uploading": "Sauvegarde en cours ..."
              }
            },
            "preview": "Visualiser cette pièce jointe"
          }
        }
      },
      "file-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
          "NOT_ALLOWED_CONTENT_TYPE": "Le type de la pièce jointe n'est pas autorisé.",
          "addFile": "Une erreur est apparue, la pièce jointe n'a pas été ajoutée. Veuillez réessayer ultérieurement.",
          "contentType": "Le type de la pièce jointe n'est pas autorisé.",
          "length": "La taille de la pièce jointe est supérieure à la taille maximale autorisée."
        }
      },
      "helpers": {
        "date-short-view": {
          "today": "Aujourd'hui, {hour}"
        }
      },
      "img-avatar": {
        "avatar-alt": "{type, select, ADVISER {Conseiller } CLIENT {Interlocuteur } other {}}{gender, select, MR {Mr }  MME {Mme } other {}}{lastname} {firstname}",
        "unknown-person": "Personne inconnue"
      },
      "inline-uploader": {
        "error": {
          "EMPTY_ATTACHMENT": "Vous ne pouvez pas télécharger des pièces jointes vides. Merci de réessayer avec un fichier valide.",
          "length": "La taille de l'image en ligne est supérieure à la taille maximale autorisée."
        }
      },
      "numeric-unit": {
        "billion": "G",
        "million": "M",
        "thousand": "K"
      },
      "pagination": "pagination",
      "skip-link": "Navigation (Quitter le menu)",
      "spellchecker": {
        "languages": {
          "de": "Allemand",
          "en": "Anglais",
          "fr": "Français",
          "nl": "Néerlandais",
          "ru": "Russe"
        },
        "message-info": {
          "error": {
            "unknown": "Une erreur est apparue."
          }
        }
      },
      "theme": {
        "criticalDueDateDelay": {
          "error": {
            "message": "Ce champ doit être supérieur à En retard dans"
          }
        }
      },
      "treeview": {
        "button": {
          "list.label": "Afficher la liste des résultats",
          "search.label": "Rechercher",
          "select.default.label": "Select",
          "tree.label": "Afficher l'arborescence complète"
        },
        "message": {
          "noresult": "Aucun résultat de recherche"
        }
      }
    },
    "shared.skip-link": "Accéder au Contenu principal (Quitter le menu)",
    "side-panel": {
      "accessibility": {
        "buttons": {
          "close": {
            "title": "Fermer"
          }
        },
        "contrast": {
          "enhanced": "Renforcés",
          "standard": "Standard"
        },
        "disparities": "Contrastes",
        "message-info": {
          "error": {
            "unknown": "Une erreur s'est produite lors de la modification du contraste d'accessibilité, veuillez réessayer plus tard."
          }
        },
        "title": "Accessibilité"
      },
      "burger": {
        "label": "Afficher / Masquer le menu"
      },
      "hide-menu": "Cacher le menu latéral",
      "logo": {
        "by": "by",
        "ti": "Trusted Interactions",
        "worldline": "Worldline"
      },
      "logo-alt": "WL Trusted Interactions",
      "module": {
        "title": "Module"
      },
      "settings": {
        "myprofile": "Paramètres",
        "name": "Préférences",
        "preferences": "Préférences"
      },
      "show-menu": "Afficher le menu latéral",
      "toggle-title": "Ouvre/Ferme le menu"
    },
    "site-map": {
      "modules": "Modules",
      "title": "Plan du site"
    },
    "themes.ACCOUNT_NUMBER": "Numéro de compte",
    "themes.CREDIT_NUMBER": "Numéro de crédit immobilier",
    "themes.PHONE_NUMBER": "Numéro de téléphone",
    "themes.error.ACCOUNT_NUMBER": "Veuillez sélectionner un numéro de compte",
    "themes.error.CREDIT_NUMBER": "Veuillez sélectionner un numéro de crédit immobilier",
    "themes.error.message": "Veuillez apporter des précisions concernant votre message",
    "themes.error.message.replay": "Saisissez votre message afin d’envoyer votre réponse",
    "themes.error.subject": "Veuillez saisir l’objet du message",
    "themes.error.theme": "Veuillez préciser le motif de votre demande",
    "themes.input.ACCOUNT_NUMBER": "Sélectionnez le compte",
    "themes.input.CREDIT_NUMBER": "Sélectionnez le numéro de crédit immobilier",
    "themes.optional.field": "(facultatif)",
    "topbar": {
      "burger": {
        "label": "Afficher / Masquer le menu"
      },
      "interlocutors": {
        "myself": "Mon compte"
      },
      "message-info": {
        "log-out-confirmation": "Souhaitez-vous vous déconnecter? Vous perdrez les données non sauvegardées."
      },
      "module": {
        "administration": "Administration",
        "interactions": "Messages",
        "profile": "Profil",
        "projects": "Projets"
      },
      "nav": {
        "label": "Barre multi module"
      },
      "organisation": {
        "name": "equensWorldline"
      },
      "settings": {
        "profile": "Paramètres",
        "sign-out": "Déconnexion"
      }
    },
    "viewer.error.format": "Seuls les fichiers PDF sont prévisualisables",
    "viewer.img.label": "Chargé : {name} - Page {nbPage}",
    "viewer.page.first": "Première page",
    "viewer.page.label": "Il y a {nbPages} pages dans le document, vous êtes page {current}",
    "viewer.page.last": "Dernière page",
    "viewer.page.next": "Page suivante",
    "viewer.page.previous": "Page précédente",
    "viewer.select.label": "Numéro de page",
    "viewer.zoom.minus": "Dezoomer",
    "viewer.zoom.plus": "Zoomer",
    "viewer.zoom.title": "Zoom:",
    "viewer.zoom.unzoomed": "Dézoomé",
    "viewer.zoom.zoomed": "Zoomé"
  };
});