define("lbp-cust-web-application/initializers/components/ti-web-shared/modals/document-viewer", ["exports", "ti-web-shared/components/ti-web-shared/modals/document-viewer", "lodash"], function (_exports, _documentViewer, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/components/ti-web-shared/modals/document-viewer',
    // eslint-disable-line
    initialize: initialize
  };
  _documentViewer.default.reopen({
    _getApiPath: Ember.computed('isCampaign', 'isProject', 'isRequest', 'identity', function () {
      if (this.get('isCampaign') === true) {
        return '/api/customer/campaign-message-templates-attachments/';
      } else if (this.get('isProject') === true) {
        return '/api/project-part-document-files-att/';
      } else if (this.get('isRequest') === true) {
        return '/api/demand-message-attachments/';
      } else if (!this.get('identity').isActor()) {
        return '/api/customer/conversation-message-attachments/';
      }
      return '/api/customer/conversation-message-attachments/';
    }),
    _getImage: function _getImage(workerRef, page) {
      var _this = this;
      if (this.get("blobs.".concat(page))) {
        return Ember.RSVP.resolve(this.get("blobs.".concat(page)));
      }
      var imageUrl = '/api/pdf/' + workerRef + '/page/' + page + '?cacheBuster=' + Date.now();
      var headers = _lodash.default.clone(this.get('authHeaders'));
      headers['Accept'] = 'image/jpeg';
      return this.get('ajaxBinary').sendAjaxForBinaryForPreview('GET', headers, imageUrl).then(function (blob) {
        _this.set("blobs.".concat(page), blob);
        return blob;
      });
    }
  });
});