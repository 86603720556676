define("lbp-cust-web-application/components/ticust-web-module-interactions/conversation-header-list", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'conversation-line-component conversation-header-component' + ' flex-basic flex-row-simple flex-center conversation-line-component-base',
    elementId: 'conversation-line-component-checkbox',
    intlPrefix: 'interactions.conversation-line-component',
    showModalDeletion: false,
    // Parameters
    isChecked: undefined,
    focusElemAfterRemove: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalDeletionId', 'popup-project-delete-file-' + _emberUuid.default.v4());
      this.set('modalDeletionTitleId', 'popup-project-delete-file-' + _emberUuid.default.v4());
      this.set('focusElemAfterRemoveId', 'deleteFullButton');
    },
    // Actions
    selectAll: function selectAll() {
      throw new Error("Missing 'selectAll' handler");
    },
    unselectAll: function unselectAll() {
      throw new Error("Missing 'unselectAll' handler");
    },
    categorize: function categorize() {
      throw new Error("Missing 'categorize' handler");
    },
    delete: function _delete() {
      throw new Error("Missing 'delete' handler");
    },
    deleteDraft: function deleteDraft() {
      throw new Error("Missing 'deleteDraft' handler");
    },
    removeFromTrashAction: function removeFromTrashAction() {
      throw new Error("Missing 'removeFromTrashAction' handler.");
    },
    restore: function restore() {
      throw new Error("Missing 'restore' handler");
    },
    actions: {
      selectAll: function selectAll() {
        this.get('selectAll')();
      },
      unselectAll: function unselectAll() {
        this.get('unselectAll')();
      },
      categorize: function categorize() {
        this.get('categorize')();
      },
      delete: function _delete() {
        this.get('delete')();
      },
      deleteDraft: function deleteDraft() {
        this.get('deleteDraft')();
      },
      removeFromTrash: function removeFromTrash() {
        this.set('focusElemAfterRemoveId', 'check-all-box');
        this.get('removeFromTrash')();
      },
      restore: function restore() {
        this.get('restore')();
      },
      toggleModalDeletion: function toggleModalDeletion() {
        this.toggleProperty('showModalDeletion');
      }
    }
  });
});