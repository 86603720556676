define("lbp-cust-web-application/templates/app/ticust-web-module-interactions/interlocutors/interlocutor/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6vmeERyn",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"help-page\",[[23,[\"text\"]],\"interactions\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lbp-cust-web-application/templates/app/ticust-web-module-interactions/interlocutors/interlocutor/help.hbs"
    }
  });
});