define("lbp-cust-web-application/initializers/components/ti-web-shared/modals/modal-component", ["exports", "ti-web-shared/components/ti-web-shared/modals/modal-component", "ember-uuid"], function (_exports, _modalComponent, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ti-web-shared/components/ti-web-shared/modals/modal-component',
    // eslint-disable-line
    initialize: initialize
  };
  _modalComponent.default.reopen({
    IsNotFocusableElement: false,
    hasValidate: true,
    focusOpenButton: false,
    backToIntl: undefined,
    triggerButtonId: undefined,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalId', 'popup-component-categorize-' + _emberUuid.default.v4());
      this.set('modalTitleId', 'popup-component-categorize-' + _emberUuid.default.v4());
    },
    openScrollObserver: Ember.on('init', Ember.observer('isOpen', function () {
      if (this.get('isOpen')) {
        window.parent.postMessage('eesResetIframeScroll', '*');
      }
    })),
    _setNavTrap: function _setNavTrap(modalId, modalTitleId) {
      var allFocusables = document.getElementById(modalId).querySelectorAll('input, select, a, button:enabled');
      var lastFocus;
      var isDefaultFirstFocus = false;
      if (allFocusables.length > 1) {
        lastFocus = allFocusables[allFocusables.length - 1];
      } else {
        isDefaultFirstFocus = true;
        lastFocus = document.getElementById(modalTitleId);
      }
      var firstFocus = document.getElementsByClassName('modal-close')[0];
      firstFocus.focus();
      lastFocus.addEventListener('keydown', function (e) {
        if (!e.shiftKey && e.keyCode === 9) {
          e.preventDefault();
          firstFocus.focus();
        }
      }, true);
      firstFocus.addEventListener('keydown', function (e) {
        if (e.shiftKey && e.keyCode === 9) {
          e.preventDefault();
          lastFocus.focus();
        }
        if (isDefaultFirstFocus && e.keyCode === 32) {
          e.preventDefault();
        }
      }, true);
    },
    _cancel: function _cancel() {
      this.get('cancel')();
      this.set('isOpen', false);
      if (this.get('focusedElement') != null) {
        if (this.get('IsNotFocusableElement')) {
          this.get('focusedElement').setAttribute('tabindex', '-1');
          this.get('focusedElement').focus();
        } else {
          this.get('focusedElement').focus();
        }
      } else {
        document.getElementById(this.get('modalTriggerButtonId')).focus();
      }
    },
    actions: {
      custom: function custom() {
        this.get('custom')();
        this.set('isOpen', false);
      },
      confirm: function confirm() {
        var _this = this;
        this.get('validate')().then(function () {
          _this.get('confirm')();
          if (!_this.isDestroyed) {
            // Avoid "calling set on destroyed object" error
            _this.set('isOpen', false);
          }
          if (_this.get('focusedElement') != null) {
            window.toastr.options.onHidden = function () {
              _this.get('focusedElement').focus();
            };
          }
        }, this.get('onValidationError'));
      },
      open: function open(event) {
        this._super(event);
        if (this.get('focusOpenButton')) {
          this.set('originalFocusId', this.get('modalTriggerButtonId'));
        }
      }
    }
  });
});