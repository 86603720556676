define("lbp-cust-web-application/services/pagination-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    // At minimum one page
    page: 1,
    // Need to be aware for which page handle pagination
    _sourceName: undefined,
    // An id of opened conversation, help find the page
    _conversationId: undefined,
    // Need to indicate new page index for next click "See More" _seeMoreIds()
    _index: undefined,
    _previousIndex: 0,
    // "search" page
    _searchPageValues: {
      currentPage: undefined,
      searchPerimeter: undefined,
      searchText: undefined
    },
    incrementPageNumber: function incrementPageNumber(number) {
      this.incrementProperty('page', number ? number : 1);
    },
    getPageNumber: function getPageNumber() {
      return this.get('page');
    },
    _resetPagingValues: function _resetPagingValues() {
      this.set('page', 1);
      this.set('_index', undefined);
      this.set('_previousIndex', 0);
      this.set('_searchPageValues', {
        currentPage: undefined,
        searchPerimeter: undefined,
        searchText: undefined
      });
    },
    setSourceName: function setSourceName(sourceName) {
      // if sourceName is different, page was changed, need reset pagination
      if (this.get('_sourceName') && this.get('_sourceName') !== sourceName) {
        this._resetPagingValues();
      }
      this.set('_sourceName', sourceName);
    },
    setConversationId: function setConversationId(id) {
      this.set('_conversationId', id);
    },
    getIdsOfOpenedPages: function getIdsOfOpenedPages(allConversationsIds) {
      var _this = this;
      var ids = [];
      var pageNumber = 0;
      if (this.get('_conversationId')) {
        allConversationsIds.forEach(function (e, index) {
          if (e.includes(_this.get('_conversationId'))) {
            pageNumber = index;
          }
        });
      }
      if (pageNumber > 0) {
        ids = allConversationsIds[pageNumber - 1];
        ids = ids.concat(allConversationsIds[pageNumber]);
        this.set('_index', pageNumber + 1);
        this.set('_previousIndex', pageNumber - 1);
      } else {
        ids = allConversationsIds[pageNumber];
        ids = ids.concat(allConversationsIds[pageNumber + 1]);
        this.set('_index', pageNumber + 2);
        this.set('_previousIndex', 0);
      }
      // After pages found, don't need the conversation id.
      this._clearConversationId();
      return ids;
    },
    _clearConversationId: function _clearConversationId() {
      this.set('_conversationId', undefined);
    },
    isIndexSetUp: function isIndexSetUp() {
      return this.get('_index');
    },
    getIndexEndResetIt: function getIndexEndResetIt() {
      // Need to know index only once
      var i = this.get('_index');
      this.set('_index', undefined);
      return i;
    },
    getPreviousIndex: function getPreviousIndex() {
      var prevIndex = this.get('_previousIndex');
      if (prevIndex && prevIndex - 1 >= 0) {
        return prevIndex - 1;
      }
    },
    isFirstPageDisplayed: function isFirstPageDisplayed() {
      return this.get('_previousIndex') === 0;
    },
    setSearchValues: function setSearchValues(currentPage, searchPerimeter, searchText) {
      if ('' !== searchText) {
        this._resetPagingValues();
        this.set('_searchPageValues', {
          currentPage: currentPage,
          searchPerimeter: searchPerimeter,
          searchText: searchText
        });
      }
    },
    getSearchValues: function getSearchValues() {
      if (Object.values(this.get('_searchPageValues')).every(function (val) {
        return val === undefined;
      })) {
        return undefined;
      } else {
        return this.get('_searchPageValues');
      }
    }
  });
});