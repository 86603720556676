define("lbp-cust-web-application/initializers/authenticators/ti", ["exports", "ticust-web-common/authenticators/ti", "lodash"], function (_exports, _ti, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-common/authenticators/ti',
    // eslint-disable-line
    initialize: initialize
  };
  _ti.default.reopen({
    // Services
    session: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    identity: Ember.inject.service('identity-service'),
    getAuthenticateData: function getAuthenticateData(credentials) {
      var authentication = this._super(credentials);
      authentication.company = credentials.company;
      authentication.das = credentials.das;
      return authentication;
    },
    makeRequest: function makeRequest(url, data, headers) {
      var _this = this;
      // Refresh token
      if (data.token) {
        this.get('tiLogger').debug('Call refresh token');
        return this._super(url, data, headers).then(function (response) {
          var clonedSession = _lodash.default.clone(_this.get('session.data.authenticated'));
          return {
            json: _lodash.default.merge(clonedSession, response.json)
          };
        });
      }
      return this._super(url, data, headers).then(function (response) {
        _this.get('identity').set('authSuccess', true);
        return response;
      });
    },
    restore: function restore(data) {
      var expiresAt = data.expiresAt;
      var now = this.getCurrentTime();
      if (expiresAt > now) {
        this.get('identity').set('authSuccess', true);
        var wait = (expiresAt - now - this.refreshLeeway) * 1000;
        if (wait > 0) {
          this.scheduleAccessTokenRefresh(expiresAt, {});
          return Promise.resolve(data);
        }
      } else {
        return Promise.resolve(this.refreshAccessToken({}));
      }
    },
    authenticate: function authenticate(credentials) {
      return this._super(credentials).then(function (data) {
        data.das = credentials.das;
        data.phones = credentials.phones;
        data.accounts = credentials.accounts;
        data.creditAccounts = credentials.creditAccounts;
        data.typo = credentials.typo;
        data.financialCenter = credentials.financialCenter;
        data.returnUrl = credentials.returnUrl;
        return data;
      });
    },
    invalidate: function invalidate() {
      return this._super();
    }
  });
});