define("lbp-cust-web-application/initializers/models/label", ["exports", "ticust-web-common/models/label"], function (_exports, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-common/models/label',
    // eslint-disable-line
    initialize: initialize
  };
  _label.default.reopen({
    intl: Ember.inject.service(),
    title: Ember.computed('intl.locale', 'name', 'colorId', function () {
      return this.get('intl').t('interactions.label.title', {
        folder: this.get('name'),
        color: this.get('intl').t('interactions.label.color-name.' + this.get('colorId'))
      });
    })
  });
});