define("lbp-cust-web-application/initializers/validator-lookup-override", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = _exports.default = {
    name: 'validator-lookup-override',
    initialize: initialize
  };
  Ember._ContainerProxyMixin.reopen({
    factoryFor: function factoryFor(fullName, options) {
      var result = this._super(fullName, options);
      if (!result) {
        var engineParent = this.get('engineParentContainer');
        if (engineParent && fullName.startsWith('validator:')) {
          return engineParent.factoryFor(fullName, options);
        }
      }
      return result;
    }
  });
});