define("lbp-cust-web-application/routes/application", ["exports", "ticust-web-common/routes/host-base-route", "ember-uuid", "moment"], function (_exports, _hostBaseRoute, _emberUuid, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _hostBaseRoute.default.extend({
    queue: Ember.inject.service(),
    lbpService: Ember.inject.service(),
    session: Ember.inject.service(),
    config: Ember.inject.service(),
    tiLogger: Ember.inject.service('logger-service'),
    beforeModel: function beforeModel(transition) {
      var _this = this;
      Ember.run.backburner.DEBUG = true;
      this.get('tiLogger').debug('App beforeModel', transition);
      var lbpService = this.get('lbpService');
      if (lbpService.status === 'init') {
        this.get('tiLogger').debug('Save attemptedTransition', transition);
        this.set('session.attemptedTransition', transition);
        return lbpService.load().then(function () {
          _this.get('tiLogger').debug('After LBP Service Init', transition);
          var attemptedTransition = _this.get('session.attemptedTransition');
          if (attemptedTransition) {
            attemptedTransition.retry();
            _this.set('session.attemptedTransition', null);
          }
        });
      }
      // Add sessionId before authentication
      if (!this.get('session.data.sessionId')) {
        var time = (0, _moment.default)(new Date().getTime()).format('HHmmss');
        this.set('session.data.sessionId', _emberUuid.default.v4() + '-' + time);
      }
    },
    activate: function activate() {
      // Test if we are integrated into an iframe
      if (window.top != window.self) {
        this._iframeAction();
      }
    },
    _iframeAction: function _iframeAction() {
      this.get('tiLogger').debug('We are into an iframe');

      // Add class on body for iframe integration on desktop only
      if (!this.get('config.iframeCss')) {
        this.get('tiLogger').debug('Ember options iframeCss is not enable');
      } else if (this.get('session.data.context.callingOrigin') === 'DESKTOP') {
        Ember.$('body').toggleClass('ti-iframe');
      } else {
        this.get('tiLogger').debug('Do not activate iframe css regarding the calling code', this.get('session.data.context.callingOrigin'));
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      this.transitionTo('ticust-web-module-authentication.logout');
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.get('queue').clearAll();
        this._super(transition);
      },
      sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
        this.get('tiLogger').debug('TransitionTo previous route before Auth');
        this.transitionTo(this.controllerFor('application').get('previousRouteName'));
      }
    }
  });
});