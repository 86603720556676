define("lbp-cust-web-application/initializers/engines/interactions-engine", ["exports", "ticust-web-module-interactions/engine"], function (_exports, _engine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  var EXTERNAL_PROJECTS_ROUTE = 'ticust-web-module-projects';
  var TAG_SERVICE = 'tags';
  var AI_SERVICE = 'ai-self-service';
  var LBP_MESSAGE_SERVICE = 'lbp-message';
  var LBP_SERVICE = 'lbp-service';
  var PAGINATION_HELPER = 'pagination-helper';
  function initialize() {}
  var _default = _exports.default = {
    name: 'ticust-web-module-interactions/engine',
    // eslint-disable-line
    initialize: initialize
  };
  /**
    Initializer to reopen the Interaction engined and inject the tag service.
  */
  _engine.default.reopen({
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this._super(args);

      // Init arrays if necessary
      if (!this.dependencies.services) {
        this.dependencies.services = [];
      }
      if (!this.dependencies.externalRoutes) {
        this.dependencies.externalRoutes = [];
      }

      // Inject if necessary LBP services and externalRoutes
      if (this.dependencies.services.indexOf(TAG_SERVICE) === -1) {
        this.dependencies.services.push(TAG_SERVICE);
      }
      if (this.dependencies.services.indexOf(AI_SERVICE) === -1) {
        this.dependencies.services.push(AI_SERVICE);
      }
      if (this.dependencies.services.indexOf(LBP_MESSAGE_SERVICE) === -1) {
        this.dependencies.services.push(LBP_MESSAGE_SERVICE);
      }
      if (this.dependencies.externalRoutes.indexOf(EXTERNAL_PROJECTS_ROUTE) === -1) {
        this.dependencies.externalRoutes.push(EXTERNAL_PROJECTS_ROUTE);
      }
      if (this.dependencies.services.indexOf(LBP_SERVICE) === -1) {
        this.dependencies.services.push(LBP_SERVICE);
      }
      if (this.dependencies.services.indexOf(PAGINATION_HELPER) === -1) {
        this.dependencies.services.push(PAGINATION_HELPER);
      }
    }
  });
});