define("lbp-cust-web-application/initializers/services/tag-commander-cli/tags", ["exports", "tag-commander-cli/services/tags"], function (_exports, _tags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {}
  var _default = _exports.default = {
    name: 'tag-commander-cli/services/tags',
    initialize: initialize
  };
  _tags.default.reopen({
    session: Ember.inject.service(),
    callingOrigin: Ember.computed('session.data.context.callingOrigin', function () {
      var callingOrigin = this.get('session.data.context.callingOrigin');
      if (callingOrigin === 'DESKTOP') {
        return 'web';
      }
      if (callingOrigin === 'MOBI') {
        return 'mobile';
      }
      if (callingOrigin === 'APP') {
        return 'app';
      }
      return 'unknown';
    }),
    xtsite: Ember.computed('callingOrigin', function () {
      return tc_vars[this.get('callingOrigin')]['xiti_xtsite']; // eslint-disable-line
    }),
    xtn2: Ember.computed('callingOrigin', function () {
      return tc_vars[this.get('callingOrigin')]['xiti_xtn2']; // eslint-disable-line
    }),
    sendPageTag: function sendPageTag(tagData) {
      tagData = this._setXtn2(tagData);
      this._super(tagData);
    },
    sendClickTag: function sendClickTag(clickData) {
      clickData = this._setXtn2(clickData);
      this._super(clickData);
    },
    _setXtn2: function _setXtn2(data) {
      if (data) {
        var xtn2 = this.get('xtn2');
        if (xtn2) {
          data['level2'] = this.get('xtn2');
        } else {
          delete data['level2'];
        }
      }
      return data;
    },
    _getTagConfig: function _getTagConfig() {
      return {
        site: this.get('xtsite'),
        secure: true,
        log: tC.internalvars.xiti_log,
        // eslint-disable-line
        logSSL: tC.internalvars.xiti_logs // eslint-disable-line
      };
    }
  });
});